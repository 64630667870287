/** @format */

import React from "react";

const EditIcon = () => {
  return (
    <svg
      id="edit"
      xmlns="http://www.w3.org/2000/svg"
      width="23.95"
      height="23.921"
      viewBox="0 0 23.95 23.921"
    >
      <path
        id="Path_197617"
        data-name="Path 197617"
        d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,1,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"
        transform="translate(0 -0.079)"
        fill="#6c5cff"
      />
      <path
        id="Path_197618"
        data-name="Path 197618"
        d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123a1,1,0,0,0-.274.492Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407L12.74,12.667l-1.8.394Z"
        transform="translate(0 -0.079)"
        fill="#6c5cff"
      />
    </svg>
  );
};

export default EditIcon;
