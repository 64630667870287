/** @format */

import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Table.module.css";
import { AppContext } from "../../../helpers/app-context";
function FilterModal(props) {
  const [checkboxes, setCheckboxes] = useState([]);
  const [searchedCheckboxes, setSearchedCheckboxes] = useState();
  const inputRef = useRef(null);
  const ctx = useContext(AppContext);
  useEffect(() => {
    const checkedCheckboxes = props?.tableParams?.params?.filter?.find(
      (item) => item?.filterBy === props.column?.filterColumn?.key
    );
    const comingCheckboxes = props?.values ? [...props.values] : [];
    let selectedCheckboxes = [];
    if (checkedCheckboxes) {
      checkedCheckboxes?.filterValues?.forEach((value) => {
        const removeIndex = comingCheckboxes?.findIndex(
          (cval) => cval === value
        );
        if (removeIndex > -1) {
          comingCheckboxes.splice(removeIndex, 1);
        }
        selectedCheckboxes.push({ value, checked: true });
      });
    }

    const unselectedCheckboxes = comingCheckboxes.map((value) => {
      return { value, checked: false };
    });

    setCheckboxes([...selectedCheckboxes, ...unselectedCheckboxes]);
  }, []);

  const handleOnChange = (ch) => {
    let newCheckboxes = checkboxes ? [...checkboxes] : [];
    if (ch.value === "Select All") {
      // Toggle all checkboxes based on the "Select All" checkbox
      const selectAllChecked = !ch.checked;
      newCheckboxes = newCheckboxes.map((checkbox) => ({
        ...checkbox,
        checked: selectAllChecked,
      }));
    } else {
      newCheckboxes = newCheckboxes.map((checkbox) => {
        if (checkbox && checkbox.value === ch.value) {
          return { value: checkbox.value, checked: !checkbox.checked };
        }
        return checkbox;
      });
    }
  

    setCheckboxes(newCheckboxes);
  };
  
  // const handleOnChange = (ch) => {
  //   let newCheckboxes = checkboxes ? [...checkboxes] : [];
  //   if (ch.value === "Select All") {
  //     // Toggle all checkboxes based on the "Select All" checkbox
  //     const selectAllChecked = !ch.checked;
  //     newCheckboxes = newCheckboxes.map((checkbox) => ({
  //       ...checkbox,
  //       checked: selectAllChecked,
  //     }));
  //   } else {
  //     newCheckboxes = newCheckboxes.map((checkbox) => {
  //       if (checkbox && checkbox.value === ch.value) {
  //         return { value: checkbox.value, checked: !checkbox.checked };
  //       }
  //       return checkbox;
  //     });
  //   }
  //   let countCheckedTrue = 0;

  //   for (const obj of newCheckboxes) {
  //     if (obj.checked === true) {
  //       countCheckedTrue++;
  //     }
  //   }

  //   ctx?.setFilterCount(countCheckedTrue);

  //   // Update the searche checkbox list as well if the user is searching something
  //   if (inputRef.current?.value) onSearchCheckboxes(newCheckboxes);
  //   setCheckboxes(newCheckboxes);
  // };

  const onSearchCheckboxes = (updatedCheckboxes) => {
    const checkIn = updatedCheckboxes ? updatedCheckboxes : checkboxes;
    const newCheckboxes = checkIn
      ? checkIn.filter((checkbox) => {
          const re = new RegExp(inputRef.current?.value || "", "gi");
          return checkbox?.value.search(re) !== -1;
        }) || []
      : [];

    setSearchedCheckboxes(newCheckboxes);
  };

  const renderCheckboxes = () => {
    const selectAllCheckbox = {
      value: "Select All",
      checked: checkboxes?.every((checkbox) => checkbox.checked) || false,
    };

    if (inputRef.current?.value) {
      return [selectAllCheckbox, ...searchedCheckboxes] || [];
    }

    return [selectAllCheckbox, ...checkboxes] || [];
  };

  const onClear = () => {
    const newCheckboxes =
      checkboxes?.map((checkbox) => {
        return { value: checkbox.value, checked: false };
      }) || [];

    // let's update the searche checkbox list as well if user is searching something
    if (inputRef.current?.value) onSearchCheckboxes(newCheckboxes);
    setCheckboxes(newCheckboxes);
    ctx?.setFilterCount();
  };

  const onSubmit = () => {
    const { tableParams, column } = props;
    const filterArrObj = [...(tableParams?.params?.filter || [])]; // all objects of filter
    const filterObjIndex = tableParams?.params?.filter?.findIndex(
      (item) => item?.filterBy === column?.filterColumn?.key
    ); // current filter object index

    const selectedCheckboxes =
      checkboxes?.filter((checkbox) => checkbox.checked) || [];
    if (typeof filterObjIndex === "number" && filterObjIndex !== -1) {
      filterArrObj.splice(filterObjIndex, 1);
    }

    if (selectedCheckboxes && column.filterColumn.key) {
      const filterBy = column.filterColumn.key;
      const filterValues = selectedCheckboxes?.map((checkboxes) => {
        return checkboxes.value;
      });
      if (filterValues.length > 0) {
        filterArrObj.push({
          filterBy,
          filterValues,
        });
      }
    }
    const params = { ...tableParams?.params, filter: filterArrObj };
    props.applyAllFilters(params);
    column?.setFilterColumn();
    const countCheckedTrue = checkboxes.filter(
      (checkbox) => checkbox.checked === true
    ).length;
     ctx?.setFilterCount(countCheckedTrue);
     
  };

  const filterModalRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterModalRef.current &&
        !filterModalRef.current.contains(event.target) &&
        event.target.className !== styles.modalCheckboxes // Check if the clicked element is not a checkbox
      ) {
        props.column.setFilterColumn();
      }
    };

    if (props.column.filterColumn) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.column.filterColumn]);
  if (!props?.column?.filterColumn) return null;

  return (
    <div className={styles.filterMaincontainer}>
      <div className={styles.filterWrapperContainer}>
        <div className={styles.filterModalCOntainer} ref={filterModalRef}>
          <div className={styles.filterModal}>
            {renderCheckboxes().map((checkbox) => {
              return (
                <button
                  className={styles.modalCheckboxContainer}
                  key={checkbox.value}
                >
                  <input
                    type="checkbox"
                    name={`${String(props?.column?.filterColumn?.key)}[]`}
                    checked={checkbox.checked}
                    value={checkbox.value ? checkbox.value : ""}
                    id={checkbox.value}
                    onChange={() => handleOnChange(checkbox)}
                    className={styles.modalCheckboxes}
                  />
                  <label className={styles.modalLabel} htmlFor={checkbox.value}>
                    {checkbox?.value ? checkbox.value : "--"}
                  </label>
                </button>
              );
            })}
          </div>

          <div className={styles.modalBtnContainer}>
            <button
              className={styles.modalClearBtn}
              onClick={() => {
                onClear();
              }}
            >
              Clear
            </button>
            <button
              className={styles.modalApplyBtn}
              onClick={() => {
                onSubmit();
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterModal;
