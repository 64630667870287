/** @format */

import React from "react";

const OutletIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.5"
      height="20.5"
      viewBox="0 0 20.5 20.5"
    >
      <path
        id="company"
        d="M21.5,21H20V7.5a.49.49,0,0,0-.34-.5L16,5.47V21H15V3.5a.5.5,0,0,0-.5-.5h-9a.5.5,0,0,0-.5.5V21H3.5a.5.5,0,0,0,0,1h18a.5.5,0,0,0,0-1Zm-4-12h1a.5.5,0,0,1,.5.5.51.51,0,0,1-.5.5h-1a.51.51,0,0,1-.5-.5A.5.5,0,0,1,17.5,9Zm0,3h1a.5.5,0,0,1,0,1h-1a.5.5,0,0,1,0-1Zm0,3h1a.51.51,0,0,1,.5.5.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5Zm0,3h1a.5.5,0,0,1,0,1h-1a.5.5,0,0,1,0-1ZM11,6h1a.5.5,0,0,1,0,1H11a.5.5,0,0,1,0-1Zm0,3h1a.5.5,0,0,1,0,1H11a.5.5,0,0,1,0-1Zm0,3h1a.5.5,0,0,1,0,1H11a.5.5,0,0,1,0-1Zm0,3h1a.5.5,0,0,1,0,1H11a.5.5,0,0,1,0-1ZM7.94,6H9A.5.5,0,0,1,9,7H8A.5.5,0,0,1,8,6Zm0,3H9a.5.5,0,0,1,0,1H8A.5.5,0,0,1,8,9Zm0,3H9a.5.5,0,0,1,0,1H8a.5.5,0,0,1,0-1Zm0,3H9a.5.5,0,0,1,0,1H8a.5.5,0,0,1,0-1Zm2.56,6V19h-1v2h-1V18.47A.5.5,0,0,1,9,18h2a.5.5,0,0,1,.5.5V21Z"
        transform="translate(-2.25 -2.25)"
        fill="none"
        stroke={color}
        stroke-width="1.5"
      />
    </svg>
  );
};

export default OutletIcon;
