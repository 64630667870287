/** @format */

import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styles from "./CustomTab.module.css";

export default function CustomTab({ onChange, value, customLabel }) {
  return (
    <Box
      sx={{
        bgcolor: "#FDFDFD",
        marginBottom: "28px",
        boxShadow: "0px 3px 6px #00000029",
      }}
    >
      <Tabs
        value={value}
        onChange={onChange}
        variant="scrollable"
        scrollButtons={false}
        aria-label="scrollable prevent tabs example"
        sx={{
          "& .css-lk6x5s-MuiTabs-indicator": {
            height: "0px",
            width: "0px",
          },
          "& .css-16fpmzx": {
            width: "0px !important",
          },
        }}
      >
        <Tab
          label={customLabel[0]}
          className={styles.tabWrapper}
          sx={{
            "&.Mui-selected": {
              color: "#f6f5ff",
              backgroundColor: "#6c5cff",
              textTransform: "none",
              clipPath: "polygon(0 0, 100% 0, 90% 100%, 0 100%)",
            },
          }}
        />
        <Tab
          label={customLabel[1]}
          className={styles.tabWrapper}
          sx={{
            "&.Mui-selected": {
              color: "#f6f5ff",
              backgroundColor: "#6c5cff",
              textTransform: "none",
              clipPath: "polygon(10% 0, 100% 0, 90% 100%, 0 100%)",
            },
          }}
        />
        {customLabel[2] && (
          <Tab
            label={customLabel[2]}
            className={styles.tabWrapper}
            sx={{
              "&.Mui-selected": {
                color: "#f6f5ff",
                backgroundColor: "#6c5cff",
                textTransform: "none",
                clipPath: "polygon(10% 0, 100% 0, 90% 100%, 0 100%)",
              },
            }}
          />
        )}
      </Tabs>
    </Box>
  );
}
