/** @format */

import React, { useContext } from "react";
import { ControllerInput } from "../../../components/common/ControllerInput";
import CustomButton from "../../../components/common/CustomButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styles from "./AddSalesPerson.module.css";
import AppLayout from "../../../components/layout";
import { YUP_REQUIRED_MESSAGE } from "../../../helpers/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../helpers/app-context";
import { ADD_SALES_PERSON } from "../../../helpers/api-endpoints";
import ToastIcon from "../../../assets/icons/ToastIcon";
import { toast } from "react-toastify";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
const schema = yup.object().shape({
  name: yup
    .string()
    .required(YUP_REQUIRED_MESSAGE)
    .matches(
      /^[a-zA-Z0-9](?=(?:[^\s]*\s){0,3}[^\s]*$)[a-zA-Z0-9\s]{2,199}$/,
      "Please enter a valid Name"
    ),
  mobileNumber: yup
    .string()
    .trim()
    .required(YUP_REQUIRED_MESSAGE)
    .matches(/^[6-9]\d{9}$/, "Please enter a valid Mobile Number"),
});
const AddSalesPerson = () => {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { state } = useLocation();
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const handleCloseToast = () => {
    toast.dismiss();
  };
  const onSubmit = async (data) => {
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("mobile", data.mobileNumber);
    formData.append("company_id", ctx?.selectedCompany?.id);
    const result = await ctx.HttpPost(ADD_SALES_PERSON, formData);
    if (result.status) {
      navigate("/sales-person");
      ctx.showToastAlert({
        type: "success",
        position: "bottom-left",
        message: (
          <div className="toastContainer">
            <div className="toastIcon">
              <ToastIcon />
            </div>
            <div className="toastContent">Sales Person Added Successfully</div>
            <div className="toastClose" onClick={handleCloseToast}>
              CLOSE
            </div>
          </div>
        ),
      });
    }
  };
  return (
    <AppLayout title="ADD Sales Person" backButton={true}>
      <div className={styles.mainContainer}>
        <div className={styles.breadcrumbs}>
          <CustomBreadcrumbs
            item1="Company"
            item2={ctx?.selectedCompany?.name}
            item3="Sales Person"
            item4="Add Sales Person"
            lastTypographyColor="#0000004D"
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControllerInput
            control={control}
            name="name"
            id="name"
            label="Name"
            placeholder="Enter Name"
            wrapperClass={styles.addSalesPersonInput}
          />
          <ControllerInput
            control={control}
            type="tel"
            name="mobileNumber"
            id="mobileNumber"
            label="Mobile Number"
            placeholder="Enter Mobile Number"
            wrapperClass={styles.addSalesPersonInput}
          />

          <div className={styles.buttonWrapper}>
            <CustomButton title="SAVE SALES PERSON" />
          </div>
        </form>
      </div>
    </AppLayout>
  );
};

export default AddSalesPerson;
