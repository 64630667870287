/** @format */

import React from "react";

const ProfileIc = () => {
  return (
    <svg
      id="Group_133712"
      data-name="Group 133712"
      xmlns="http://www.w3.org/2000/svg"
      width="34.333"
      height="34.333"
      viewBox="0 0 33.333 33.333"
    >
      <defs>
        <clipPath id="clip-path">
          <circle
            id="Ellipse_4524"
            data-name="Ellipse 4524"
            cx="16.667"
            cy="16.667"
            r="16.667"
            fill="#fff"
            stroke="#6c5cff"
            stroke-width="1.25"
          />
        </clipPath>
      </defs>
      <g id="Group_131161" data-name="Group 131161" transform="translate(0 0)">
        <g id="Group_130919" data-name="Group 130919">
          <circle
            id="Ellipse_4294"
            data-name="Ellipse 4294"
            cx="16.667"
            cy="16.667"
            r="16.667"
          />
          <path
            id="Path_227254"
            data-name="Path 227254"
            d="M16.667,0A16.667,16.667,0,1,1,0,16.667,16.667,16.667,0,0,1,16.667,0Z"
            fill="#a5a5a5"
          />
        </g>
      </g>
      <g
        id="Mask_Group_15"
        data-name="Mask Group 15"
        transform="translate(0 0)"
        clip-path="url(#clip-path)"
      >
        <path
          id="Icon_ionic-ios-person"
          data-name="Icon ionic-ios-person"
          d="M25.192,24.562c-.386-1.7-2.589-2.535-3.35-2.8a24.976,24.976,0,0,0-2.792-.536,2.837,2.837,0,0,1-1.292-.595,11.289,11.289,0,0,1-.086-2.642,7.95,7.95,0,0,0,.611-1.163,15,15,0,0,0,.45-2.031s.44,0,.595-.772a7.594,7.594,0,0,0,.4-1.8c-.032-.616-.37-.6-.37-.6a9.4,9.4,0,0,0,.364-2.75c.048-2.2-1.678-4.374-4.8-4.374-3.168,0-4.856,2.171-4.808,4.374a9.8,9.8,0,0,0,.359,2.75s-.338-.016-.37.6a7.594,7.594,0,0,0,.4,1.8c.15.772.595.772.595.772a15,15,0,0,0,.45,2.031,7.95,7.95,0,0,0,.611,1.163,11.289,11.289,0,0,1-.086,2.642,2.837,2.837,0,0,1-1.292.595,24.975,24.975,0,0,0-2.792.536c-.761.268-2.964,1.1-3.35,2.8-.059.268-.774,3.132-.5,3.132,0,0,5.193,5.483,10.536,5.548s10.923-5.548,10.923-5.548C25.868,27.693,25.251,24.83,25.192,24.562Z"
          transform="translate(1.755 1.876)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default ProfileIc;
