/** @format */

import React, { useContext, useEffect, useRef, useState } from "react";
import AppLayout from "../../../components/layout";
import styles from "./Tickets.module.css";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import { Box, Tab, Tabs } from "@mui/material";
import Faqs from "../Faqs";
import Attachment from "../../../assets/icons/Attachment";
import UnCheckedIcon from "../../../assets/icons/UnCheckedIcon";
import SentIcon from "../../../assets/icons/SentIcon";
import {
  CLOSE_TICKET,
  SENT_CHAT_MESSAGE,
  SHOW_TICKET_CHAT_DETAILS,
  TICKET_LIST,
} from "../../../helpers/api-endpoints";
import { AppContext } from "../../../helpers/app-context";
import Select from "react-select";
import { CustomDeleteModal } from "../../../components/common/CustomDeleteModal";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { fileToBase64 } from "../../../helpers/functions";
import LoaderIcon from "../../../assets/icons/LoaderIcon";
import RefreshIcon from "../../../assets/icons/RefreshIcon";
const Tickets = () => {
  const [value, setValue] = useState("0");
  const [chatData, setChatData] = useState();
  const [resolved, setResolved] = useState(null);
  const [data, setData] = useState([]);
  const ctx = useContext(AppContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isActive, setIsActive] = useState("");
  const handleSortAll = (sortValue) => {
    setIsActive(sortValue);
  };

  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageUi, setImageUi] = useState(false);

  const handleImageUpload = (e) => {
    setImageUi(true);
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setSelectedImages([...selectedImages, ...files]);
    }
  };

  const handleMessageSend = async () => {
    setImageUi(false);

    // Check if there is either text or selected images to send
    const hasUserMessage = userMessage?.trim();
    const hasSelectedImages = selectedImages?.length > 0;

    if (!hasUserMessage && !hasSelectedImages) {
      // Neither message nor images to send
      return;
    }

    const messageData = {
      message: hasUserMessage ? userMessage.trim() : "",
      ticket_id: chatData?.ticket_id,
      attachment: hasSelectedImages ? [] : [], // Empty array if no images
    };

    if (hasSelectedImages) {
      // Convert selected images to base64
      const base64Array = await Promise.all(selectedImages.map(fileToBase64));
      messageData.attachment = base64Array;
    }
    console.log(messageData?.attachment);
    const result = await ctx.HttpPost(SENT_CHAT_MESSAGE, messageData);

    if (result.status) {
      // Create a new message object
      const newMessage = {
        message: messageData.message,
        attachments: hasSelectedImages
          ? selectedImages.map((image) => URL.createObjectURL(image))
          : [],
        message_from: "0",
      };
      console.log(newMessage?.attachments);
      // Update the chat UI
      setMessages([...messages, newMessage]);
      setUserMessage("");
      setSelectedImages([]);
      ctx.showToastAlert({
        type: "success",
        position: "bottom-center",
        message: <div className="toastErrorMessage">{result.msg}</div>,
      });
    }
  };

  const handleImageRemove = (index) => {
    setSelectedImages((prevImageData) => {
      const newData = [...prevImageData];
      newData.splice(index, 1);
      return newData;
    });
    console.log(selectedImages.length);
    if (selectedImages.length === 1) {
      console.log("Hii");
      setImageUi(false);
    }
  };
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getData = async () => {
    setData([]);
    const dataToSend = {
      filter: isActive,
    };
    const result = await ctx.HttpPost(TICKET_LIST, dataToSend);
    if (result.status) {
      setData(result.data);
    }
  };
  useEffect(() => {
    getData();
  }, [isActive]);
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: "0", label: "Open" },
    { value: "1", label: "Resolved" },
  ];

  const customStyles = {
    indicatorSeparator: () => ({
      display: "none",
      cursor: "pointer",
    }),
    // indicatorContainer: (base) => ({
    //   ...base,
    //   padding: "0px",
    // }),
    // Svg: (base) => ({
    //   ...base,
    //   position: "absolute",
    //   right: "0px",
    //   left: "1px",
    // }),
    option: (base) => ({
      ...base,
      color: "#1F1F1F",
      background: "white",
      fontSize: "12px",
      height: "100%",
    }),
    singleValue: (base) => ({
      ...base,
      textAlign: "center",
      background: "#ffffff",
      color: selectedOption.value === "0" ? "#E41E29" : "#0DBF48",
      position: "absolute",
      top: "-5px",
    }),
    control: (base) => ({
      ...base,
      width: "116px",
      height: "32px",
      minHeight: "32px",
      background: "#ffffff",
      borderRadius: "5px",
      boxSizing: "border-box",
      borderColor: "#1F1F1F",
      "&:focus": {
        boxShadow: "none",
      },
    }),
  };
  const handleSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    setResolved(selectedOption);
  };
  const onDeleteCompany = async () => {
    const dataToSend = {
      status: resolved.value,
      ticket_id: chatData?.ticket_id,
    };
    const result = await ctx.HttpPost(CLOSE_TICKET, dataToSend);
    if (result.status) {
      setResolved(null);
      // setDeletecompany();
      getData();
    }
  };

  const defaultValueFromBackend = {
    value: chatData?.status === "1" ? "1" : "0",
    label: chatData?.status === "1" ? "Resolved" : "Open",
  };
  // Set the default value when the component mounts
  useEffect(() => {
    setSelectedOption(defaultValueFromBackend);
  }, [chatData]);
  const handleCancel = () => {
    setSelectedOption(defaultValueFromBackend);
    setResolved(null);
  };
  const handleShowChatHistory = async (ticket_id) => {
    setMessages([]);
    const dataToSend = {
      ticket_id: ticket_id,
    };
    const result = await ctx.HttpPost(SHOW_TICKET_CHAT_DETAILS, dataToSend);
    if (result.status) {
      setMessages(result.data);
    }
  };
  return (
    <AppLayout title="Company / Brand" backButton={false}>
      <div className={styles.mainContainer}>
        <div className={styles.createCompanyBtn}>
          <CustomBreadcrumbs
            item1="Help & Support"
            lastTypographyColor="#0000004D"
          />
        </div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="secondary tabs example"
            sx={{
              "& .css-lk6x5s-MuiTabs-indicator": {
                backgroundColor: "#6C5CFF !important",
                background: "#6C5CFF !important",
              },
              "& .css-16fpmzx": {
                backgroundColor: "#6C5CFF !important",
                background: "#6C5CFF !important",
              },
            }}
          >
            <Tab
              value="0"
              label="Tickets"
              className={styles.tabWrapper}
              sx={{
                "&.Mui-selected": {
                  color: "#0E0750",
                },
              }}
            />
            <Tab
              value="1"
              label="FAQs"
              className={styles.tabWrapper}
              sx={{
                "&.Mui-selected": {
                  color: "#0E0750",
                },
              }}
            />
          </Tabs>
        </Box>

        <div style={{ marginLeft: "18px" }}>
          {value === "0" && (
            <div className={styles.wrapperTicketContainer}>
              <div>
                <div className={styles.headerMainContainer} style={{cursor:"pointer"}}>
                  <div
                    className={`${styles.sortItems} ${
                      isActive === "" ? styles.sortActive : styles.sortInActive
                    }`}
                    onClick={() => handleSortAll("")}
                  >
                    All
                  </div>
                  <div
                    className={`${styles.sortItems} ${
                      isActive === "0" ? styles.sortActive : styles.sortInActive
                    }`}
                    onClick={() => handleSortAll("0")}
                  >
                    Open
                  </div>
                  <div
                    className={`${styles.sortItems} ${
                      isActive === "1" ? styles.sortActive : styles.sortInActive
                    }`}
                    onClick={() => handleSortAll("1")}
                  >
                    Resolved
                  </div>
                </div>
                <div className={styles.TicketContainer}>
                  {data.length <= 1 && ctx.loading ? (
                    <div className={styles.loaderIconContainer}>
                      <LoaderIcon />
                    </div>
                  ) : (
                    data.map((item, index) => {
                      return (
                        <div
                          className={styles.cardMainContainer}
                          key={index}
                          onClick={() => {
                            handleShowChatHistory(item.ticket_id);
                            setChatData(item);
                          }}
                        >
                          <div className={styles.cardTitleContainer}>
                            <div className={styles.cardTitle}>
                              {item.outlet_name}
                            </div>
                            <div className={styles.cardDate}>
                              {item.created_at}
                            </div>
                          </div>
                          <div className={styles.cardSubTitle}>
                            {/* <div>{item.location}</div> */}
                            <div>{item.issue_type}</div>
                          </div>

                          <div className={styles.cardInvoiceDetails}>
                            {item.message}
                          </div>
                          <div className={styles.cardTitleContainer}>
                            <div className={styles.markResolvedContainer}>
                              <Attachment color="#1FB5EB" />
                              <div className={styles.attachmentFile}>
                                {item.attachment_count} Attachmnets
                              </div>
                            </div>

                            <div
                              className={` ${styles.mark} ${
                                item.status === "1"
                                  ? styles.markResolved
                                  : styles.markUnResolved
                              }`}
                            >
                              {item.status === "1" ? (
                                <div className={styles.resolvedStatusContainer}>
                                  <div className={styles.resolvedStatus}>
                                    <UnCheckedIcon />{" "}
                                  </div>
                                  Resolved
                                </div>
                              ) : (
                                "Open"
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>

              <div className={styles.viewCardContainer}>
                {chatData?.outlet_name ? (
                  !imageUi ? (
                    <div>
                      <div className={styles.cardTitleContainer}>
                        <div className={styles.viewCardTitle}>
                          {chatData?.outlet_name}
                          <Box
                            onClick={() => {
                              handleShowChatHistory(chatData?.ticket_id);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            <RefreshIcon />
                          </Box>
                        </div>
                        <div className={styles.cardTitle}>
                          {chatData?.created_at}
                        </div>
                      </div>
                      <div className={styles.chartSubTitle}>
                        <div className={styles.cardLocation}>
                          {chatData?.location}
                        </div>

                        <div className={styles.chatSubHeadings}>
                          <div className={styles.chatStatusContainer}>
                            {" "}
                            Status :
                          </div>
                          <Select
                            value={selectedOption}
                            onChange={handleSelect}
                            options={options}
                            styles={customStyles}
                            isSearchable={false}
                          />
                        </div>
                      </div>
                      <div
                        className={`${styles.chatSalesperson} ${styles.chatSubHeadings}`}
                      >
                        Salesperson :{" "}
                        <span className={styles.chatSalesPeronName}>
                          {chatData?.sales_person_name}
                        </span>
                      </div>
                      <hr className={styles.searchSeparation} />
                      <div className={styles.chatContainer}>
                        <div className={styles.messages}>
                          {messages.map((message, index) => (
                            <div
                              key={index}
                              className={`${styles.message} ${
                                message?.message_from === "0"
                                  ? styles.userMessage
                                  : styles.receivedMessage
                              }`}
                            >
                              <div className={styles.imageContainer}>
                                {message?.attachments &&
                                  message?.attachments.map((image, index) => (
                                    <img
                                      key={index}
                                      src={image}
                                      alt="User uploaded"
                                      className={styles.selectedImageswidth}
                                    />
                                  ))}
                              </div>
                              {message?.message.length > 0 && (
                                <div
                                  className={` ${
                                    message?.message_from !== "0" &&
                                    styles.receivedtextMessage
                                  }`}
                                >
                                  {message?.message}
                                </div>
                              )}
                            </div>
                          ))}
                          <div ref={messagesEndRef} />
                        </div>
                        <div className={styles.inputContainer}>
                          <input
                            type="text"
                            placeholder="Type Message..."
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            className={styles.chatInput}
                          />

                          <input
                            type="file"
                            id="file-input"
                            accept="image/*"
                            multiple
                            style={{ display: "none" }}
                            onChange={handleImageUpload}
                          />

                          <div className={styles.iconsContainer}>
                            <span
                              className={styles.icon}
                              onClick={() =>
                                document.getElementById("file-input").click()
                              }
                            >
                              <Attachment color="#333333" />
                            </span>

                            <span
                              className={styles.icon}
                              onClick={handleMessageSend}
                            >
                              <SentIcon />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className={styles.horizontalImageContainer}>
                        {selectedImages.map((image, index) => (
                          <div className={styles.addNewOutletCol} key={index}>
                            <div
                              onClick={() => handleImageRemove(index)}
                              className={styles.crossIconContainer}
                            >
                              <div className={styles.crossIcon}>&#x2716;</div>
                            </div>
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Selected img ${index + 1}`}
                              className={styles.horizontalImageItem}
                            />
                          </div>
                        ))}
                      </div>

                      <div className={styles.imageinputContainer}>
                        <input
                          type="file"
                          id="file-input"
                          accept="image/*"
                          multiple
                          style={{ display: "none" }}
                          onChange={handleImageUpload}
                        />
                        <span
                          className={styles.icon}
                          onClick={() =>
                            document.getElementById("file-input").click()
                          }
                        >
                          <PlusIcon />
                        </span>
                        <input
                          type="text"
                          placeholder="Type Message..."
                          value={userMessage}
                          onChange={(e) => setUserMessage(e.target.value)}
                          className={styles.chatInput}
                        />
                        <div className={styles.iconsContainer}>
                          <span
                            className={styles.icon}
                            onClick={handleMessageSend}
                          >
                            <SentIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <div className={styles.selectTicketContainer}>
                    <div>Select an ticket to chat</div>
                    <div>Nothing is selected</div>
                  </div>
                )}
              </div>
              {console.log(resolved)}
              {resolved && (
                <CustomDeleteModal
                  flag={resolved}
                  content={`Do you want to update <br /> the status to ${
                    resolved?.label === "Resolved" ? "resolved" : "open"
                  } ?`}
                  handleClose={() => handleCancel()}
                  onClick={onDeleteCompany}
                  btnTItle={resolved?.label === "Resolved" ? "Resolve" : "Open"}
                />
              )}
            </div>
          )}
          {value === "1" && <Faqs />}
        </div>
      </div>
    </AppLayout>
  );
};

export default Tickets;
