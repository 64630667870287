/** @format */

import React from "react";
import { Button } from "@mui/material";
import styles from "./CustomButton.module.css";

const CustomButton = ({ title, onClick, wrapperClass,style }) => {
  return (
    <Button
      className={`${styles["custom-button"]} ${wrapperClass}`}
      onClick={onClick}
      type="submit"
      style={style}
    >
      {title}
    </Button>
  );
};

export default CustomButton;
