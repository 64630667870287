/** @format */

import React from "react";

const CopyLinkIcon = () => {
  return (
    <svg
      id="Component_59_21"
      data-name="Component 59 – 21"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_27991"
        data-name="Rectangle 27991"
        width="24"
        height="24"
        fill="none"
      />
      <g
        id="Group_135677"
        data-name="Group 135677"
        transform="translate(0 0.5)"
      >
        <path
          id="Path_228985"
          data-name="Path 228985"
          d="M10,13l.953.953a4,4,0,0,0,5.659,0l3.032-3.032a4.643,4.643,0,0,0,0-6.566h0a4.643,4.643,0,0,0-6.566,0l-.887.888"
          fill="none"
          stroke="#0e0750"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Path_228986"
          data-name="Path 228986"
          d="M14,10l-.953-.953a4,4,0,0,0-5.659,0L4.356,12.078a4.643,4.643,0,0,0,0,6.566h0a4.643,4.643,0,0,0,6.566,0l.887-.888"
          fill="none"
          stroke="#0e0750"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
    </svg>
  );
};

export default CopyLinkIcon;
