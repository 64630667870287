/** @format */

import React, { useContext } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import styles from "./styles.module.css";
import { ControllerInput } from "../../components/common/ControllerInput";
import CustomButton from "../../components/common/CustomButton";
import { AppContext } from "../../helpers/app-context";
import { useNavigate } from "react-router-dom";
import { DEVICE_ID, TOKEN_NAME, USER_DETAILS } from "../../helpers/constant";
const schema = yup.object().shape({
  email: yup
    .string()
    .required("This field is required")
    .email("Please enter a valid email id")
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/,
      "Please enter a valid email id"
    ),
  password: yup.string().required("This field is required"),
});
const Login = () => {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);
    const result = await ctx.AppLogin(false, formData);
    if (result.status) {
      console.log("inside result");
      localStorage.setItem(TOKEN_NAME, result.token);
      localStorage.setItem(DEVICE_ID, result.device_id);
      localStorage.setItem(USER_DETAILS, JSON.stringify(result.user_details));
      navigate("/campaign-list");
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginWrapper}>
        <div className={styles.formTitle}>Magic Ads</div>
        <div className={styles.formSubTitle}>Admin Login</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControllerInput
            control={control}
            name="email"
            id="email"
            label="Email ID"
            placeholder="Enter your email id"
            labelWrapperClass={styles.labelWrapperClass}
            inputWrapperClass={styles.inputEmailWrapperClass}
          />
          <ControllerInput
            control={control}
            name="password"
            id="password"
            label="Password"
            placeholder="Enter your password"
            labelWrapperClass={styles.labelWrapperClass}
            inputWrapperClass={styles.inputWrapperClass}
          />

          <Typography
            className={styles.forgotPassword}
            onClick={() => navigate("/forgot-password")}
          >
            Forgot Password?
          </Typography>
          <CustomButton title="Login" />
        </form>
      </div>
    </div>
  );
};

export default Login;
