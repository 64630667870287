/** @format */

import React from "react";

const DownloadIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g
        id="Group_135685"
        data-name="Group 135685"
        transform="translate(-5811 -5127)"
      >
        <rect
          id="Rectangle_27993"
          data-name="Rectangle 27993"
          width="22"
          height="22"
          transform="translate(5811 5127)"
          fill="none"
        />
        <g
          id="Icon_feather-upload"
          data-name="Icon feather-upload"
          transform="translate(5815 5131)"
        >
          <path
            id="Path_148"
            data-name="Path 148"
            d="M18,22.5v3A1.5,1.5,0,0,1,16.5,27H6a1.5,1.5,0,0,1-1.5-1.5v-3"
            transform="translate(-4.5 -13.497)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Path_149"
            data-name="Path 149"
            d="M18,8.251,14.251,4.5,10.5,8.251"
            transform="translate(21.003 14.252) rotate(180)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Path_150"
            data-name="Path 150"
            d="M18,4.5v9"
            transform="translate(24.752 13.504) rotate(180)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default DownloadIcon;
