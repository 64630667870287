/** @format */

import React, { useContext, useEffect, useState } from "react";
import styles from "./CampaignListDetails.module.css";
import AppLayout from "../../../components/layout";
import { Box } from "@mui/material";
import Table from "../../../components/common/Table";
import CustomButton from "../../../components/common/CustomButton";
import CreateIcon from "../../../assets/icons/CreateIcon";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedDialogs from "../../../components/common/CustomModal";
import { AppContext } from "../../../helpers/app-context";
import {
  ADD_PRODUCTS_TO_CAMPAIGN,
  CAMPAIGN_DETAILS,
  DELETE_PRODUCTS_FROM_CAMPAIGN,
  PRODUCT_LIST,
} from "../../../helpers/api-endpoints";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import { CustomDeleteModal } from "../../../components/common/CustomDeleteModal";
import EditIcon from "../../../assets/icons/EditIcon";
import ControllerSelectV2 from "../../../components/common/SelectTag";
import { yupResolver } from "@hookform/resolvers/yup";
import { YUP_REQUIRED_MESSAGE } from "../../../helpers/constant";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ControllerInput } from "../../../components/common/ControllerInput";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";

const schema = yup.object().shape({
  productList: yup.array().required(YUP_REQUIRED_MESSAGE),
  products: yup
    .array()
    .of(
      yup.object().shape({
        minQty: yup
          .number()
          .typeError("Min quantity must be a number")
          .positive("Min quantity must be a positive number")
          .required("Min quantity is required")
          .integer("Min quantity must be an integer"),
        discprice: yup
          .number()
          .typeError("Offer price must be a number")
          .positive("Offer price must be a positive number")
          .required("Offer price is required")
          .integer("Offer price must be an integer"),

      })
    )
    .required("At least one product must be added"),
});

const CampaignListDetails = () => {
  const ctx = useContext(AppContext);
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [addProducts, setAddProducts] = useState();
  const navigate = useNavigate();
  const getData = async () => {
    setData([]);
    setProducts([]);
    let formData = new FormData();
    formData.append("campaign_id", state?.campaign_id);
    const result = await ctx.HttpPost(CAMPAIGN_DETAILS, formData);
    if (result.status) {
      setData(result.data.products);
      setProducts(result.data);
    }
  };
  // const handleEdit = (data) => {
  //   setEditItem(data);
  // };
  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   // Make an API call to update the offer price using 'updatedOfferPrice'
  //   // You can use 'editItem.product_id' and 'editItem.variant_id' for identification
  //   // Update logic goes here...

  //   // Close the edit modal after update
  //   setEditItem(null);
  //   // Reset the updatedOfferPrice state
  //   setUpdatedOfferPrice('');
  // };

  useEffect(() => {
    getData();
  }, []);
  const [deletecompany, setDeletecompany] = useState(null);
  const handleDelete = (data) => {
    setDeletecompany(data);
  };
  const onDeleteCompany = async () => {
    //api call pass deletecompany
    let formData = new FormData();
    formData.append("product_id", deletecompany?.product_id);
    formData.append("variant_id", deletecompany?.variant_id);
    formData.append("campaign_id", state?.campaign_id);
    const result = await ctx.HttpPost(DELETE_PRODUCTS_FROM_CAMPAIGN, formData);
    if (result.status) {
      setDeletecompany();
      getData();
    }
  };
  const [params, setParams] = useState({});
  const tableHeader = [
    {
      key: "product_name",
      label: "Product Name",
      sorting: true,
      headerClasses: styles.headerClasses,
      cardColumn: styles.cardColumn,
      render: (column) => {
        return (
          <Box className={styles.avatarWrapper}>
            <img
              src={column?.images[0]?.product_image}
              alt=""
              width={"50px"}
              height={"40px"}
            />
            <div>{column?.product_name}</div>
          </Box>
        );
      },
    },
    {
      key: "mrp",
      label: "MRP",
      // cardColumn: styles.amountCard,
      // headerClasses: styles.amountHeader,
      render: (column) => {
        return <Box ml={1}><span>&#8377;</span> {column.mrp}</Box>
      }
    },
    {
      key: "description",
      label: "SKU",
    },
    {
      key: "sku_code",
      label: "SKU Code",
    },
    {
      key: "min_quantity",
      label: "Qty",
    },
    {
      key: "discount_price",
      label: "Offer Price",
      // cardColumn: styles.amountCard,
      // headerClasses: styles.amountHeader,
      render: (column) => {
        return <Box ml={1}><span>&#8377;</span> {column.discount_price}</Box>
      }
    },
    {
      label: "",
      render: (column) => {
        return (
          <div
            style={{ marginRight: "35px", marginLeft: "5px" }}
            onClick={() =>
              navigate("/campaign-list/update-edit-campaign", {
                state: {
                  column: state?.column,
                  campId: state?.campaign_id
                },
              })
            }
          >
            <EditIcon />
          </div>
        );
      },
    },

    {
      label: "",
      render: (column) => {
        return (
          <div onClick={() => handleDelete(column)}>
            <DeleteIcon />
          </div>
        );
      },
    },
  ];
  const searchInput = {
    key: "product_name",
    placeholder: "Search Product",
  };
  const [outletBudgetModal, setOutletBudgetModal] = useState(false);
  const { handleSubmit, control, watch, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const onSubmit = async (data) => {
    if (watchproductList.length > 0) {
      const dataToSend = {
        campaign_id: state?.campaign_id,
        products: data?.productList?.map((sku, index) => ({
          product_id: sku?.product_id,
          variant_id: sku?.value,
          min_quantity: data?.products[index]?.minQty,
          discount_price: data?.products[index]?.discprice
        })),
      };
      const result = await ctx.HttpPost(ADD_PRODUCTS_TO_CAMPAIGN, dataToSend);
      if (result.status) {
        setAddProducts();
        getData();
        reset();
      }
    } else {
      ctx.showToastAlert({
        type: "error",
        message: "Please Select Product",
      });
    }
  };

  const watchproductList = watch("productList");
  const handleRemoveInputField = (itemValue) => {
    const updatedProductList = watchproductList.filter(
      (productList) => productList.value !== itemValue
    );
    setValue("productList", updatedProductList);

    // Update the 'products' array based on the updated 'watchproductList'
    const updatedProducts = updatedProductList.map((product) => ({
      minQty: 0, // Set an appropriate default value for 'minQty'
    }));
    setValue("products", updatedProducts);
  };

  const handleAddProduct = async () => {
    let formData = new FormData();
    formData.append("company_id", ctx?.selectedCompany?.id);
    const result = await ctx.HttpPost(PRODUCT_LIST, formData);
    // if (result.status) {
    //   setAddProducts(result.data);
    // }
    console.log(result?.data);
    setAddProducts(result?.data === undefined ? true : result.data);
  };

  return (
    <AppLayout
      title={ctx?.selectedCompany?.name + "- Campaign List"}
      backButton={true}
    >
      <>
        <div className={styles.breadcrumbsContainer}>
          <CustomBreadcrumbs
            item1="Company"
            item2={ctx?.selectedCompany?.name}
            item3={state.campaign_name}
            lastTypographyColor="#0000004D"
          />
          <div className={styles.campaignHeaderContainer}>
            <div className={styles.imageContainer}>
              <img
                src={products?.image}
                width="545px"
                height={"144px"}
                alt="not found"
              />
              {/* <div className={styles.percentageOverlay}>{products?.offer}</div> */}
            </div>
            <div className={styles.campaignHeader}>
              <div className={styles.campaignHeaderContainer}>
                <div className={styles.campaignName}>
                  {products?.campaign_name}
                  <span className={styles.productCount}>({data?.length})</span>
                </div>
                <div
                  className={styles.avatarWrapper}
                  onClick={() =>
                    navigate("/campaign-list/update-edit-campaign", {
                      state: {
                        column: state?.column,
                        campId: state?.campaign_id
                      },
                    })
                  }
                >
                  <div className={styles.EditIcon}>Edit</div>
                  <EditIcon />
                </div>
              </div>
              <div className={styles.campaignKeys}>
                URL <div className={styles.productUrl}>{products?.url}</div>
              </div>
              <div className={styles.campaignHeaderContainer}>
                <div className={styles.campaignKeys}>
                  Start Date
                  <div className={styles.campaignValues}>
                    {products?.start_date}
                  </div>
                </div>
                <div className={styles.campaignKeys}>
                  Budget
                  <div className={styles.campaignValues}>
                    {" "}
                    ₹{products?.budget}
                  </div>
                </div>
              </div>
              <div className={styles.campaignHeaderContainer}>
                <div className={styles.campaignKeys}>
                  End Date
                  <div style={{ marginLeft: "29px" }} className={styles.campaignValues}>
                    {" "}
                    {products?.end_date}
                  </div>
                </div>
                <div className={styles.campaignKeys}>
                  Active Outlet
                  <div className={styles.campaignValues}>
                    {products?.active}({products?.inactive + products?.active})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.tableContainer}>
          <div className={styles.uploadBtns}>
            <CustomButton
              title="Set Outlet Budget"
              wrapperClass={styles.wrapperClassUploadBtn}
            />
            <CustomButton
              title={
                <div className={styles.btnTitleStyling}>
                  <span className={styles.Iconstyling}>
                    <CreateIcon />
                  </span>
                  Add Product
                </div>
              }
              onClick={() => handleAddProduct()}
              wrapperClass={styles.wrapperClassBtn}
            />
          </div>

          <Table
            tableParams={{ params, setParams }}
            tableHeader={tableHeader}
            tableData={data}
            tableSearchInput={searchInput}
            tableWrapper={styles.tableWrapper}
          />
        </div>
        {deletecompany && (
          <CustomDeleteModal
            flag={deletecompany}
            content="Do you want to delete<br />this product from campaign ?"
            handleClose={() => setDeletecompany(null)}
            onClick={onDeleteCompany}
          />
        )}
        {outletBudgetModal && (
          <CustomizedDialogs
            handleClose={() => setOutletBudgetModal(false)}
            modalTitle="Set Outlet Budget"
            modalContent={<div></div>}
          />
        )}
        {addProducts && (
          <CustomizedDialogs
            handleClose={() => {
              setAddProducts(false);
              reset();
            }}
            modalTitle="Add Product"
            modalContent={
              <div className={styles.modalHeight}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {addProducts.length > 0 ? (
                    <ControllerSelectV2
                      wrapperClass={styles.addProductContainer}
                      isMulti={true}
                      control={control}
                      options={addProducts.map((item) => ({
                        product_id: item?.product_id,
                        label: item?.product_name,
                        value: item?.variant_id,
                        image: item?.product_images[0]?.product_image,
                      }))}
                      name="productList"
                      id="productList"
                      label="Select Product"
                    />
                  ) : (
                    <div className={styles.noaddProducts}>
                      No products available
                    </div>
                  )}
                  {watchproductList && watchproductList.length > 0 && (
                    <>
                      <div className={styles.modalContainer}>
                        <div>Product Name</div>
                        <div>Min Quantity</div>
                        <div>Offer Price</div>
                      </div>
                      {watchproductList.map((item, index) => (
                        <div
                          key={item}
                          className={styles.addProductModalContainer}
                          style={{ marginLeft: "20px", display: "flex", justifyContent: "space-between" }}
                        >
                          <div className={styles.productModalTitle}>
                            <div className={styles.productModalItems}>
                              {index + 1}. {item.label}
                            </div>
                            <ControllerInput
                              control={control}
                              name={`products[${index}].minQty`}
                              placeholder="Enter Min Quantity"
                              inputWrapperClass={styles.addProductInput}
                            />
                            <ControllerInput
                              control={control}
                              name={`products[${index}].discprice`}
                              placeholder="Enter Offer Price"
                              inputWrapperClass={styles.addProductInput}
                            />
                          </div>
                          <div
                            onClick={() => handleRemoveInputField(item?.value)}
                            className={styles.deleteInputField}
                          >
                            <DeleteIcon />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  <div
                    className={`${styles.btnContainer} ${watchproductList &&
                      watchproductList.length === 0 &&
                      styles.activeBtn
                      }`}
                  >
                    {addProducts.length > 0 && (
                      <CustomButton
                        title="SAVE"
                        wrapperClass={styles.wrapperClassBtn}
                      />
                    )}
                  </div>
                </form>
              </div>
            }
          />
        )}
      </>
    </AppLayout>
  );
};

export default CampaignListDetails;
