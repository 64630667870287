/** @format */

import React, { useContext, useEffect, useState } from "react";
import AppLayout from "../../../components/layout";
import { Box } from "@mui/material";
import styles from "./CompanyList.module.css";
import ActiveIcon from "../../../assets/icons/ActiveIcon";
import Table from "../../../components/common/Table";
import CustomButton from "../../../components/common/CustomButton";
import CreateIcon from "../../../assets/icons/CreateIcon";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../../assets/icons/EditIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import { AppContext } from "../../../helpers/app-context";
import { CustomDeleteModal } from "../../../components/common/CustomDeleteModal";
import { COMPANY_LIST, DELETE_COMPANY } from "../../../helpers/api-endpoints";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
export const CompanyList = () => {
  const ctx = useContext(AppContext);
  const [data, setData] = useState([]);
  const getData = async () => {
    setData([]);
    const result = await ctx.HttpGet(COMPANY_LIST);
    if (result.status) {
      setData(result.data);
      ctx?.setCompanyList(result.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const [deletecompany, setDeletecompany] = useState(null);
  const handleDelete = (data) => {
    setDeletecompany(data);
  };
  const onDeleteCompany = async () => {
    //api call pass deletecompany
    let formData = new FormData();
    formData.append("company_id", deletecompany?.id);
    const result = await ctx.HttpPost(DELETE_COMPANY, formData);
    if (result.status) {
      setDeletecompany();
      getData();
      ctx?.setUpdateCompanyList(deletecompany?.id);
    }
  };
  const [params, setParams] = useState({});
  const tableHeader = [
    {
      key: "name",
      label: "Company Name",
      sorting: true,
      headerClasses: styles.companyNameHeader,
      cardColumn: styles.companyNameCard,
      render: (column) => {
        return (
          <Box className={styles.avatarWrapper}>
            <img src={column.logo} alt="" width={"50px"} height={"40px"} />
            <div>{column.name}</div>
          </Box>
        );
      },
    },
    {
      label: "Campaigns",
      render: (column) => {
        return (
          <Box className={styles.avatarWrapper}>
            <ActiveIcon />
            <div className={styles.isActive}>
              Active : {column.campaign_status?.active}
            </div>
          </Box>
        );
      },
    },
    {
      label: "",
      render: (column) => {
        return (
          <div style={{ display: "flex" }}>
            <div
              style={{ marginRight: "35px" }}
              onClick={() =>
                navigate("/company-list/edit-company", { state: column })
              }
            >
              <EditIcon />
            </div>
            <div onClick={() => handleDelete(column)}>
              <DeleteIcon />
            </div>
          </div>
        );
      },
    },
  ];
  const searchInput = {
    key: "name",
    placeholder: "Search Company",
  };
  const navigate = useNavigate();

  return (
    <AppLayout title="Company / Brand">
      <div className={styles.mainContainer}>
        <div className={styles.createCompanyBtn}>
          <CustomBreadcrumbs item1="Company" lastTypographyColor="#0000004D" />
        </div>
        <div className={styles.uploadBtns}>
          <CustomButton
            onClick={() => navigate("/company-list/create-company")}
            title={
              <div className={styles.btnTitleStyling}>
                <span className={styles.Iconstyling}>
                  <CreateIcon />
                </span>
                Create Company
              </div>
            }
            wrapperClass={styles.wrapperClassBtn}
          />
        </div>

        <Table
          tableParams={{ params, setParams }}
          tableHeader={tableHeader}
          tableData={data}
          tableSearchInput={searchInput}
        />

        {deletecompany && (
          <CustomDeleteModal
            flag={deletecompany}
            content="Do you want to delete<br />this Company ?"
            handleClose={() => setDeletecompany(null)}
            onClick={onDeleteCompany}
          />
        )}
      </div>
    </AppLayout>
  );
};
