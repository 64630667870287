/** @format */

import React from "react";

const AddSalesPersonIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g
        id="Group_135685"
        data-name="Group 135685"
        transform="translate(-5811 -5129)"
      >
        <rect
          id="Rectangle_27993"
          data-name="Rectangle 27993"
          width="22"
          height="22"
          transform="translate(5811 5129)"
          fill="none"
        />
        <g
          id="Group_135686"
          data-name="Group 135686"
          transform="translate(0 -2)"
        >
          <circle
            id="Ellipse_43873"
            data-name="Ellipse 43873"
            cx="3.5"
            cy="3.5"
            r="3.5"
            transform="translate(5819 5136)"
            fill="rgba(0,0,0,0)"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Path_228987"
            data-name="Path 228987"
            d="M7.5,17.724A3.607,3.607,0,0,1,10.806,15.5h6.388A3.607,3.607,0,0,1,20.5,17.724"
            transform="translate(5808.5 5130.138)"
            fill="rgba(0,0,0,0)"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default AddSalesPersonIcon;
