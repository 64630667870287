/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  TextField,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import styles from "../CustomDropdown/CustomDropdown.module.css";
import SearchIcon from "../../../assets/icons/SearchIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";

// ... (previous code)

const FaqDropdown = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [newItem, setNewItem] = useState(""); // State to hold the new item to be added
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [addItem, setAddItem] = useState();

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
    props.onOptionSelect(option);
  };

  const handleAddItemClick = (event) => {
   if(event){
    event.preventDefault()
   }
    if (addItem) {
      const newOption = { label: addItem, value: addItem };
      const updatedOptions = [...props.options, newOption];
      setNewItem("");
      props.setOptions(updatedOptions);
    }
  };
  useEffect(() => {
    setAddItem(selectedOption?.label);
  }, [selectedOption]);
  const handleDeleteOption = (valueToDelete) => {
    const updatedOptions = props.options.filter(
      (option) => option.value !== valueToDelete
    );
    props.setOptions(updatedOptions);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column" }}
      className={`${props.wraperClass} ${styles.overlayContainer}`}
    >
      <Box sx={{ display: "flex" }}>
        <InputBase
          variant="outlined"
          className={`${styles.selectField} ${styles.inputSelectField} ${props.wrapperSelectInput}`}
          value={addItem}
          placeholder={props.placeholder}
          readOnly={!dropdownOpen}
          type="text"
          onChange={(e) => setAddItem(e.target.value)}
          endAdornment={
            <InputAdornment
              position="end"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <IconButton>
                <ExpandMoreOutlinedIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        {dropdownOpen && (
          <button
            onClick={(event) => handleAddItemClick(event)}
            className={styles.addItemBtn}
          >
            ADD
          </button>
        )}
      </Box>
      {dropdownOpen && (
        <div
          className={`${styles.dropdownContainer} ${styles.overlayDropdown}`}
        >
          <TextField
            variant="outlined"
            placeholder={props.searchPlaceholder}
            value={searchValue}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: <SearchIcon />,
              sx: {
                backgroundColor: "#fff",
                width: "494px",
                marginBottom: "-4px",
                "& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "10px 14px 8px 12px !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  outline: "none",
                },
                "& input::placeholder": {
                  color: "#00000099",
                  fontSize: "14px",
                },
              },
            }}
          />
          <hr className={styles.searchSeparation} />
          <Box sx={{ maxHeight: 200, overflowY: "auto" }}>
            {props?.options
              ?.filter((option) =>
                option.label.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map((option) => (
                <Box
                  key={option.value} // Use a unique property like 'value' as the key
                  className={`${styles.dropdownList} `}
                >
                  <div onClick={() => handleOptionChange(option)}>
                    {option.label}
                  </div>
                  <div onClick={() => handleDeleteOption(option.value)}>
                    <DeleteIcon />
                  </div>
                </Box>
              ))}
          </Box>
        </div>
      )}
    </Box>
  );
};

export default FaqDropdown;
