/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { filterData, searchedData, sorting } from "../../../helpers/functions";
import IconSorting from "../../../assets/icons/IconSorting";
import styles from "./Table.module.css";
import SearchIcon from "../../../assets/icons/SearchIcon";
import { AppContext } from "../../../helpers/app-context";
import loader from "../../../assets/svgs/Loading.gif";
import SearchLoader from "../../../assets/svgs/Search.gif";
import SearchCloseIcon from "../../../assets/icons/SearchCloseIcon";
import FilterModal from "./FilterModal";
import IconFilter from "../../../assets/icons/IconFilter";
import LoaderIcon from "../../../assets/icons/LoaderIcon";
const Table = (props) => {
  const [filterColumn, setFilterColumn] = useState();
  const [tableData, setTableData] = useState(props.tableData);
  const [filterCheckboxes, setFilterCheckboxes] = useState([]);

  const {
    tableHeader,
    tableSearchInput,
    tableParams,
    wrapClassName,
    tableWrapper,
    tableFiltering,
  } = props;
  const ctx = useContext(AppContext);
  const handleSorting = (data) => {
    const order =
      tableParams?.params.sortBy === data.key &&
      tableParams?.params.order === "ASC"
        ? "DESC"
        : "ASC";
    const params = { ...tableParams?.params, sortBy: data.key, order };
    applyAllFilters(params);
  };
  const getAppliedFiltersData = (params, key) => {
    let entireData = [...props.tableData];

    if (params.filter) {
      for (const filterObj of params.filter) {
        if (filterObj.filterBy !== key) {
          entireData = filterData(
            entireData,
            filterObj.filterValues,
            filterObj.filterBy
          );
        }
      }
    }

    if (params.searchBy && params.searchValue) {
      entireData = searchedData(
        entireData,
        params.searchValue,
        params.searchBy
      );
    }

    if (params.sortBy && params.order) {
      entireData = sorting(entireData, params.sortBy, params.order);
    }
    return entireData;
  };
  const handleSearch = (value) => {
    if (tableSearchInput) {
      const params = {
        ...tableParams?.params,
        searchBy: tableSearchInput.key,
        searchValue: value,
      };
      applyAllFilters(params);
    }
  };
  useEffect(() => {
    setTableData(props.tableData);
  }, [props.tableData]);
  const applyAllFilters = (params) => {
    let entireData = [...props.tableData];
    if (params.filter) {
      for (const filterObj of params.filter) {
        entireData = filterData(
          entireData,
          filterObj.filterValues,
          filterObj.filterBy
        );
      }
    }
    if (params.searchBy && params.searchValue) {
      entireData = searchedData(
        entireData,
        params.searchValue,
        params.searchBy
      );
    }

    if (params.sortBy && params.order) {
      entireData = sorting(entireData, params.sortBy, params.order);
    }

    setTableData(entireData);
    tableParams?.setParams(params);
  };

  useEffect(() => {
    if (props?.tableParams?.params) {
      applyAllFilters(props.tableParams.params);
    }
  }, []);
  const CardBody = () => {
    return (
      <>
        {tableParams?.params?.searchValue && tableData.length === 0 ? (
          <div className={styles.loaderIcon} style={{position:'fixed',left:'50%'}}>
            <div style={{marginBottom:'260px',height:"130px"}}>
              <img
                src={SearchLoader}
                alt="loading"
                height={"100px"}
                width={"100px"}
                className={styles.SearchLoader}
              />
              <div className={styles.searchLoaderLabel} style={{fontSize:'15px'}}>
                Sorry, we couldn't find Anything... Search Again.
              </div>
            </div>
          </div>
        ) : (
          tableData.map((column) => {
            return (
              <div className={`${styles.ListBody} ${wrapClassName}`}>
                {tableHeader.map((header) => {
                  return (
                    <div
                      className={`${styles.bodyColumn} ${header?.cardColumn}`}
                    >
                      {header?.render ? (
                        header.render(column)
                      ) : (
                        <>{header.key && column[header.key]}</>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })
        )}
      </>
    );
  };

  useEffect(() => {
    ctx?.setFilterCount();
  }, []);
  return ctx.loading ? (
    <div className={styles.loaderIcon}>
      {/* <img src={loader} alt="loading" height={"100px"} width={"100px"} /> */}
      <LoaderIcon />
    </div>
  ) : (
    <div>
      <div className={styles.paramsContainers}>
        {tableSearchInput && (
          <div>
            <TextField
              variant="outlined"
              placeholder={tableSearchInput.placeholder || "Search"}
              name={tableSearchInput.key}
              value={tableParams?.params?.searchValue || ""}
              onChange={(e) => handleSearch(e.target.value)}
              InputProps={{
                startAdornment: !tableParams?.params?.searchValue && (
                  <div style={{paddingTop:'5px'}}
                    onClick={(e) => {
                      if (!tableParams?.params?.searchValue) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <SearchIcon />
                  </div>
                ),
                endAdornment: tableParams?.params?.searchValue && (
                  <div
                    className={styles.searchCloseIcon}
                    onClick={() => {
                      handleSearch("");
                    }}
                  >
                    <SearchCloseIcon />
                  </div>
                ),

                sx: {
                  borderRadius: "5px",
                  backgroundColor: "#fff",
                  color: "#0000004D",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #C5C2C2",
                    outline: "none",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "13px 16px 12px 16px",
                    width: "359px",
                  },
                },
              }}
            />
          </div>
        )}
        {tableFiltering && (
          <div className={styles.filterContainer}>
            <button
              className={styles.filterBtnContainer}
              onClick={() => {
                setFilterColumn(tableFiltering);
                if (tableFiltering.key) {
                  const newData = getAppliedFiltersData(
                    tableParams.params,
                    tableFiltering.key
                  );
                  const checkboxes = [
                    ...new Set(
                      newData.map((item) => {
                        return !item[tableFiltering.key]
                          ? null
                          : item[tableFiltering.key];
                      })
                    ),
                  ];

                  setFilterCheckboxes(checkboxes.filter((i) => i !== null));
                }
              }}
            >
              <div className={styles.filterBtn}>
                <IconFilter />
                <div className={styles.filterLabel}>Filter </div>

                {ctx?.filterCount !== 0 && ctx?.filterCount && (
                  <div className={styles.checkboxCount}>
                    {ctx?.filterCount !== 0 && ctx?.filterCount}
                  </div>
                )}
              </div>
            </button>

            <FilterModal
              key={String(filterColumn?.key)}
              tableParams={tableParams}
              column={{ filterColumn, setFilterColumn }}
              applyAllFilters={applyAllFilters}
              values={filterCheckboxes}
            />
          </div>
        )}
      </div>
      <Box className={`${styles.listWrapper} `}>
        <Box className={styles.listHeader}>
          {tableHeader &&
            tableHeader.map((column) => {
              return (  
                <Typography style={{cursor:"pointer"}}
                  key={column.label}
                  className={`${styles.headerColumn} ${column?.headerClasses}`}
                >
                  {column.label}
                  {column.sorting && (
                    <Tooltip
                    title={`Click to sort  ${
                      tableParams?.params.sortBy === column.key &&
                      tableParams?.params.order === "ASC"
                        ? "Descending"
                        : "Ascending"
                    }`}
                    placement="top"
                  >
                    <span
                      className={styles.sortIcon}
                      onClick={() => handleSorting(column)}
                    >
                      <IconSorting />
                    </span>
                     </Tooltip>
                  )}
                </Typography>
              );
            })}
        </Box>
        <div className={`${styles.mainListContainer} ${tableWrapper}`}>
          <CardBody />
        </div>
      </Box>
    </div>
  );
};

export default Table;
