/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { searchedData, sorting } from "../../../helpers/functions";
import IconSorting from "../../../assets/icons/IconSorting";
import Tooltip from '@mui/material/Tooltip';
import styles from "../Table/Table.module.css";
import SearchIcon from "../../../assets/icons/SearchIcon";
import loader from "../../../assets/svgs/Loading.gif";
import { AppContext } from "../../../helpers/app-context";
import SearchCloseIcon from "../../../assets/icons/SearchCloseIcon";
import SearchLoader from "../../../assets/svgs/Search.gif";
import LoaderIcon from "../../../assets/icons/LoaderIcon";
const ViewMoreTable = (props) => {
  const [tableData, setTableData] = useState(props.tableData);
  const {
    tableHeader,
    tableSearchInput,
    tableParams,
    wrapClassName,
    viewmoreTableHeader,
    tableWrapper,
    innerTable,
    viewMoreTableIcon,
    viewLessTableIcon,
    headerColumnColor,
  } = props;
  const ctx = useContext(AppContext);
  const [expandedRows, setExpandedRows] = useState([]);
  const handleEdit = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };
  const handleSorting = (data) => {
    const order =
      tableParams?.params.sortBy === data.key &&
      tableParams?.params.order === "ASC"
        ? "DESC"
        : "ASC";
    const params = { ...tableParams?.params, sortBy: data.key, order };
    applyAllFilters(params);
  };

  const handleSearch = (value) => {
    if (tableSearchInput) {
      const params = {
        ...tableParams?.params,
        searchBy: tableSearchInput.key,
        searchValue: value,
      };
      applyAllFilters(params);
    }
  };
  useEffect(() => {
    setTableData(props.tableData);
  }, [props.tableData]);

  const applyAllFilters = (params) => {
    let entireData = [...props.tableData];
    if (params.searchBy && params.searchValue) {
      entireData = searchedData(
        entireData,
        params.searchValue,
        params.searchBy
      );
    }

    if (params.sortBy && params.order) {
      entireData = sorting(entireData, params.sortBy, params.order);
    }

    setTableData(entireData);
    tableParams?.setParams(params);
  };

  useEffect(() => {
    if (props?.tableParams?.params) {
      applyAllFilters(props.tableParams.params);
    }
  }, []);

  const InnerTable = ({ column }) => {
    return (
      <>
        {column?.products?.map((innerTable, index) => {
          return (
            <div key={index} className={styles.innerTableContainer}>
              {viewmoreTableHeader.map((header) => {
                return (
                  <div
                    className={`${styles.innerTableBodyColumn} ${header?.cardColumn}`}
                  >

                    {header?.render ? (
                      header.render(innerTable)
                    ) : (
                      <>{header.key && innerTable[header.key]}</>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
  };
  const CardBody = () => {
    return (
      <>
        {tableParams?.params?.searchValue && tableData.length === 0 ? (
          <div className={styles.loaderIcon}>
            <div>
              <img
                src={SearchLoader}
                alt="loading"
                height={"132px"}
                width={"132px"}
                className={styles.SearchLoader}
              />
              <div className={styles.searchLoaderLabel}>
                Sorry, we couldn't find Anything... Search Again.
              </div>
            </div>
          </div>
        ) : (
          tableData.map((column, index) => {
            const isExpanded = expandedRows.includes(index);
            return (
              <>
                <Box className={`${styles.ListBody} ${wrapClassName}`}>
                  {tableHeader.map((header) => {
                    return (
                      <Box
                        className={`${styles.bodyColumn} ${header?.cardColumn}`}
                      >
                        {header?.render ? (
                          header.render(column)
                        ) : (
                          <>{header.key && column[header.key]}</>
                        )}
                      </Box>
                    );
                  })}
                  <div
                    onClick={() => handleEdit(index)}
                    className={styles.viewMoreBtn}
                  >
                    {isExpanded
                      ? viewLessTableIcon
                        ? viewLessTableIcon
                        : "View less"
                      : viewMoreTableIcon
                      ? viewMoreTableIcon
                      : "View More"}
                  </div>
                </Box>
                {isExpanded && (
                  <>
                    <div className={styles.viewContainer}>
                      {viewmoreTableHeader.map((header) => {
                        return (
                          <div
                            className={`${styles.viewMoreHeader} ${header.headerClasses}`}
                          >
                            <div className={styles.viewMoreHeaderCol}>
                              <div className={headerColumnColor}>
                                {" "}
                                {header.label}
                              </div>
                              {header?.viewMoreValuesCol && (
                                <div className={styles.viewMoreValues}>
                                  {header?.render ? (
                                    header.render(column)
                                  ) : (
                                    <>{header.key && column[header.key]}</>
                                  )}
                                </div>
                              )}
                            </div>
                            {!header?.viewMoreValuesCol && !innerTable && (
                              <div className={styles.viewMoreValues}>
                                {header?.render ? (
                                  header.render(column)
                                ) : (
                                  <>{header.key && column[header.key]}</>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {innerTable && <InnerTable column={column} />}
                  </>
                )}
              </>
            );
          })
        )}
      </>
    );
  };
  return ctx.loading ? (
    <div className={styles.loaderIcon}>
      {/* <img src={loader} alt="loading" height={"100px"} width={"100px"} /> */}
      <LoaderIcon />
    </div>
  ) : (
    <div>
      <div>
        {tableSearchInput && (
          <div>
            <TextField
              variant="outlined"
              placeholder={tableSearchInput.placeholder || "Search"}
              name={tableSearchInput.key}
              value={tableParams?.params?.searchValue || ""}
              onChange={(e) => handleSearch(e.target.value)}
              InputProps={{
                startAdornment: !tableParams?.params?.searchValue && (
                  <div style={{paddingTop:"5px"}}
                    onClick={(e) => {
                      if (!tableParams?.params?.searchValue) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <SearchIcon />
                  </div>
                ),
                endAdornment: tableParams?.params?.searchValue && (
                  <div
                    className={styles.searchCloseIcon}
                    onClick={() => {
                      handleSearch("");
                    }}
                  >
                    <SearchCloseIcon />
                  </div>
                ),

                sx: {
                  borderRadius: "5px",
                  backgroundColor: "#fff",
                  color: "#0000004D",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #C5C2C2",
                    outline: "none",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "14px 16px 10px 16px",
                    width: "359px",
                  },
                },
              }}
            />
          </div>
        )}
      </div>
      <Box className={styles.listWrapper}>
        <Box className={styles.listHeader}>
          {tableHeader &&
            tableHeader.map((column) => {
              return (
                <Typography style={{cursor:"pointer"}}
                  key={column.label}
                  className={`${styles.headerColumn} ${column?.headerClasses}`}
                >
                  {column.label}
                  {column.sorting && (
                     <Tooltip
                     title={`Click to sort  ${
                       tableParams?.params.sortBy === column.key &&
                       tableParams?.params.order === "ASC"
                         ? "Descending"
                         : "Ascending"
                     }`}
                     placement="top"
                   >
                     <span
                       className={styles.sortIcon}
                       onClick={() => handleSorting(column)}
                     >
                       <IconSorting />
                     </span>
                   </Tooltip> 
                  )}
                </Typography>
              );
            })}
        </Box>
        <div className={`${styles.viewmoreMainListContainer} ${tableWrapper}`}>
          <CardBody />
        </div>
      </Box>
    </div>
  );
};

export default ViewMoreTable;
