/** @format */

import React from "react";

const CompanyIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.228"
      height="29.461"
      viewBox="0 0 26.228 29.461"
    >
      <g
        id="Icon_feather-home"
        data-name="Icon feather-home"
        transform="translate(1.402 1)"
      >
        <path
          id="Path_140"
          data-name="Path 140"
          d="M4.5,8.25s2.944,3.825,5.926,3.825,6-3.825,6-3.825,2.948,3.825,5.824,3.825S27.932,8.25,27.932,8.25V26.432a2.6,2.6,0,0,1-2.6,2.6H7.1a2.6,2.6,0,0,1-2.6-2.6V8.25L9.217,1.575h14L27.932,8.25"
          transform="translate(-4.5 -1.575)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_141"
          data-name="Path 141"
          d="M13.5,31.018V18h7.811V31.018"
          transform="translate(-5.689 -3.557)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default CompanyIcon;
