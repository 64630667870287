/** @format */

import React, { useContext, useState } from "react";
import { Menu } from "@mui/material";
import { AppContext } from "../../helpers/app-context";
import styles from "./Layout.module.css";
import { SELECTED_COMPANY } from "../../helpers/constant";

export default function CompanyDropdown() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleNavigate = (account) => {
    ctx?.setSelectedCompany(account);
    localStorage.setItem(SELECTED_COMPANY, JSON.stringify(account));
    handleCloseMenu();
  };

  const ctx = useContext(AppContext);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <React.Fragment>
      <button
        type="button"
        onClick={handleOpenMenu}
        className={styles.activeCompanyBtn}
      >
        <div className={styles.selectedCompany}>
          {ctx?.selectedCompany?.name}
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`${styles.dropdownIcon} ${
            menuOpen && styles.openDropdownIcon
          }`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="#5D5D61"
          strokeWidth={5}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <Menu
        id="basic-menu"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        className={styles.companyList}
        PaperProps={{
          style: {
            minWidth: "160px",
            right: 40,
            padding: 0,
          },
        }}
      >
        {ctx?.companyList?.map((account) => {
          return (
            <button
              key={account.name}
              onClick={() => {
                handleNavigate(account);
              }}
              type="button"
              className={styles.dropdownItems}
            >
              <div
                className={`${
                  ctx?.selectedItem?.name !== account.name
                    ? styles.inactivedropdownItems
                    : styles.activeDropdownItem
                }`}
              >
                {" "}
                {account.name}
              </div>
            </button>
          );
        })}
      </Menu>
    </React.Fragment>
  );
}
