/** @format */

import React, { useContext, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import styles from "./ProductSku.module.css";
import Table from "../../../components/common/Table";
import CustomButton from "../../../components/common/CustomButton";
import CreateIcon from "../../../assets/icons/CreateIcon";
import CustomizedDialogs from "../../../components/common/CustomModal";
import UploadLogoIcon from "../../../assets/icons/UploadLogoIcon";
import { ControllerInput } from "../../../components/common/ControllerInput";
import { YUP_REQUIRED_MESSAGE } from "../../../helpers/constant";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AddSkuIcon from "../../../assets/icons/AddSkuIcon";
import { AppContext } from "../../../helpers/app-context";
import {
  ADD_PRODUCTS,
  DELETE_PRODUCTS,
  DOWNLOAD_EXCEL,
  EDIT_PRODUCTS,
  PRODUCT_LIST,
  UPLOAD_EXCEL,
  VIEW_PRODUCTS,
} from "../../../helpers/api-endpoints";
import EditIcon from "../../../assets/icons/EditIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import { CustomDeleteModal } from "../../../components/common/CustomDeleteModal";
import UploadIcon from "../../../assets/icons/UploadIcon";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import { toast } from "react-toastify";
import ToastIcon from "../../../assets/icons/ToastIcon";
import SearchCloseIcon from "../../../assets/icons/SearchCloseIcon";
import AppLayout from "../../../components/layout";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
const skuSchema = yup.object().shape({
  product_sku: yup
    .string()
    .required(YUP_REQUIRED_MESSAGE)
    .matches(
      /^[a-zA-Z0-9](?=(?:[^\s]*\s){0,3}[^\s]*$)[a-zA-Z0-9\s]{2,199}$/,
      "Please enter a valid Product SKU"
    ),
  sku_code: yup
    .string()
    .required(YUP_REQUIRED_MESSAGE)
    .matches(
      /^[a-zA-Z0-9](?=(?:[^\s]*\s){0,3}[^\s]*$)[a-zA-Z0-9\s]{2,199}$/,
      "Please enter a valid SKU Code"
    ),
  mrp: yup
    .number()
    .typeError("MRP must be a number")
    .required(YUP_REQUIRED_MESSAGE)
    // .matches(/^\$?\d{1,6}(,\d{3})*(\.\d{0,3})?$/, "Please enter valid MRP")
    .min(1, 'MRP must be greater than 0')

  // .number()
  // .typeError("MRP must be a number")
  // .min(1, 'Quantity must be greater than 0')
  // .required(YUP_REQUIRED_MESSAGE)
});

const schema = yup.object().shape({
  productName: yup
    .string()
    .required(YUP_REQUIRED_MESSAGE)
    .matches(
      /^[a-zA-Z0-9](?=(?:[^\s]*\s){0,10}[^\s]*$)[a-zA-Z0-9\s()\-_,]{2,199}$/,
      "Please enter a valid Product Name"
    ),
  products: yup.array().of(skuSchema).required().min(1),
});

export const ProductSku = () => {
  const { handleSubmit, control, setValue, reset, getValues } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const handleCloseToast = () => {
    toast.dismiss();
  };
  const onSubmit = async (data) => {
    const hasEmptyImage = skuUIList.some((sku) => !sku.product_image);
    if (hasEmptyImage) {
      ctx.showToastAlert({
        type: "error",
        message: "Please upload an image for all SKUs.",
      });
    } else if (editProduct) {
      const dataToSend = {
        product_name: data.productName,
        product_id: editProduct.product_id,
        products: data.products.map((sku, index) => ({
          product_sku: sku.product_sku,
          sku_code: sku.sku_code,
          mrp: sku.mrp,
          variant_id: editProduct.variants[index]
            ? editProduct.variants[index].variant_id
            : 0,
          product_image: skuUIList[index].product_image.startsWith("https:")
            ? ""
            : skuUIList[index].product_image, // Include the product_image for each SKUUI
        })),
      };
      console.log(dataToSend);
      const result = await ctx.HttpPost(EDIT_PRODUCTS, dataToSend);
      if (result.status) {
        setAddProductModal(false);
        reset();
        getData();
        setEditProduct();
        setSkuUIList([
          {
            product_sku: "",
            sku_code: "",
            mrp: "",
            product_image: null,
            photoURL: null,
          },
        ]);
      }
    } else {
      const dataToSend = {
        product_name: data.productName,
        company_id: ctx?.selectedCompany?.id,
        products: data.products.map((sku, index) => ({
          product_sku: sku.product_sku,
          sku_code: sku.sku_code,
          mrp: sku.mrp,
          product_image: skuUIList[index].product_image, // Include the product_image for each SKUUI
        })),
      };
      const result = await ctx.HttpPost(ADD_PRODUCTS, dataToSend);
      if (result.status) {
        setAddProductModal(false);
        getData();
        reset();
        setSkuUIList([
          {
            product_sku: "",
            sku_code: "",
            mrp: "",
            product_image: null,
            photoURL: null,
          },
        ]);
        ctx.showToastAlert({
          type: "success",
          position: "bottom-left",
          message: (
            <div className="toastContainer">
              <div className="toastIcon">
                <ToastIcon />
              </div>
              <div className="toastContent">Product Added Successfully</div>
              <div className="toastClose" onClick={handleCloseToast}>
                CLOSE
              </div>
            </div>
          ),
        });
      }
    }
  };

  const [params, setParams] = useState({});
  const ctx = useContext(AppContext);
  const [data, setData] = useState([]);
  const [editProduct, setEditProduct] = useState();
  const getData = async () => {
    setData([]);
    let formData = new FormData();
    formData.append("company_id", ctx?.selectedCompany?.id);
    const result = await ctx.HttpPost(PRODUCT_LIST, formData);
    if (result.status) {
      setData(result.data);
    }
  };
  useEffect(() => {
    if (ctx?.selectedCompany?.id) {
      getData();
    }
  }, [ctx?.selectedCompany]);
  const [addProductModal, setAddProductModal] = useState(false);
  const handleEdit = async (data) => {
    let formData = new FormData();
    formData.append("product_id", data);
    const result = await ctx.HttpPost(VIEW_PRODUCTS, formData);
    if (result.status) {
      setAddProductModal(true);
      setEditProduct(result.data);
      setValue("productName", result.data.product_name);
      // setSkuUIList(result.data.variants);
      result.data.variants.forEach((variant, index) => {
        setValue(`products[${index}].product_sku`, variant.product_sku);
        setValue(`products[${index}].sku_code`, variant.sku_code);
        setValue(`products[${index}].mrp`, variant.mrp);
        // Assuming you have a URL for the image, set it as well
        setValue(
          `products[${index}].photoURL`,
          variant?.product_images[0]?.product_image
        );
      });
      const newSkuUIList = result.data.variants.map((variant) => ({
        product_sku: variant.product_sku,
        sku_code: variant.sku_code,
        mrp: variant.mrp,
        product_image: variant?.product_images[0]?.product_image,
        photoURL: variant?.product_images[0]?.product_image, // Assuming you have a URL for the image
      }));
      setSkuUIList(newSkuUIList);
    }
  };
  const [deletecompany, setDeletecompany] = useState(null);
  const handleDelete = (data) => {
    setDeletecompany(data);
  };
  const onDeleteCompany = async () => {
    //api call pass deletecompany
    let formData = new FormData();
    formData.append("product_id", deletecompany?.product_id);
    const result = await ctx.HttpPost(DELETE_PRODUCTS, formData);
    if (result.status) {
      setDeletecompany();
      getData();
    }
  };
  const tableHeader = [
    {
      key: "product_name",
      label: "Product Name",
      headerClasses: styles.headerClasses,
      sorting: true,
      cardColumn: styles.cardColumn,
      render: (column) => {
        return (
          <Box className={styles.avatarWrapper}>
            <img
              src={column?.product_images[0]?.product_image}
              alt=""
              width={"50px"}
              height={"40px"}
            />

            <div>{column.product_name}</div>
          </Box>
        );
      },
    },
    {
      key: "product_sku",
      label: "Product SKU",
    },
    {
      key: "sku_code",
      label: "SKU Code",
    },
    {
      key: "mrp",
      label: "MRP",
      cardColumn: styles.mrpcardColumn,
      render: (column) => {
        return (
          <div>
            ₹ {column.mrp}
          </div>
        );
      },
    },
    {
      label: "",
      render: (column) => {
        return (
          <div style={{ display: "flex" }}>
            <div
              style={{ marginRight: "35px" }}
              onClick={() => handleEdit(column.product_id)}
            >
              <EditIcon />
            </div>
            <div onClick={() => handleDelete(column)}>
              <DeleteIcon />
            </div>
          </div>
        );
      },
    },
  ];
  const searchInput = {
    key: "product_name",
    placeholder: "Search Product",
  };
  const inputRef = useRef(null);
  const handleFileUploadChange = (index, event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg")
    ) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // e.target.result contains the base64 encoded image data
        const base64Image = e.target.result;

        setSkuUIList((prevList) => {
          const newList = [...prevList];
          newList[index].product_image = base64Image; // Update the product_image property with base64 data
          newList[index].photoURL = URL.createObjectURL(file);
          return newList;
        });
      };

      reader.readAsDataURL(file);
    } else {
      ctx.showToastAlert({
        type: "error",
        message:
          "Please upload an image in either .jpg/.png/.jpeg formats. Other file types are not allowed.",
      });
    }
  };
  const handleImageRemove = (index) => {
    setSkuUIList((prevList) => {
      const newList = [...prevList];
      newList[index].product_image = null; // Clear the product_image
      newList[index].photoURL = null; // Clear the photoURL
      return newList;
    });
  };

  const SKUUI = ({
    index,
    handleInputChange,
    handleFileUploadChange,
    photoURL,
    handleDeleteSKU,
    editProduct,
    handleImageRemove,
  }) => {
    const showDeleteIcon = index > 0 || (index === 0 && editProduct);

    const handleFileUploadClick = () => {
      // Open the file manager when the user clicks on the image area
      document.getElementById(`product-sku-image-${index}`).click();
    };
    const handleDeleteClick = () => {
      // Call the handleDeleteSKU function with the specific index
      handleDeleteSKU(index);
    };

    return (
      <div className={styles.skuMainContainer}>
        <div className={styles.skuContainer}>
          <div
            className={`${styles.uploadImage} ${!photoURL && styles.uploadProfile
              }`}
            onClick={() => {
              if (!photoURL) {
                handleFileUploadClick(); // Open the file manager only if there's no photoURL
              }
            }}
            style={{ background: "#F3F5F86B" }}
          >
            {photoURL ? (
              <div className={styles.skuContainerImage}>
                <div
                  onClick={() => handleImageRemove()}
                  className={styles.crossSkuIcon}
                >
                  <SearchCloseIcon />
                </div>
                <img
                  src={photoURL}
                  width="150px"
                  height="87px"
                  alt="not found"
                />
              </div>
            ) : (
              <>
                <div className={styles.uploadIconClass}>
                  <UploadLogoIcon />
                </div>
                <p>Upload Image</p>
              </>
            )}
          </div>
          {/* Render the input element outside of the uploadImage div */}
          <input
            className={styles.profileImageInput}
            type="file"
            id={`product-sku-image-${index}`}
            ref={inputRef}
            onChange={(event) => handleFileUploadChange(index, event)}
            accept="image/png, image/jpg, image/jpeg"
            style={{ display: "none" }}
          />
          <div>
            <ControllerInput
              control={control}
              name={`products[${index}].product_sku`}
              label="SKU"
              placeholder="Enter SKU"
              inputWrapperClass={styles.inputWrapperClass}
              wrapperClass={styles.skuWrapperClass}
              onChange={(e) =>
                handleInputChange(index, "product_sku", e.target.value)
              }
            />
          </div>
          <div>
            <ControllerInput
              control={control}
              name={`products[${index}].sku_code`}
              label="SKU Code"
              placeholder="Enter SKU Code"
              inputWrapperClass={styles.inputWrapperClass}
              wrapperClass={styles.skucodeWrapperClass}
              onChange={(e) =>
                handleInputChange(index, "sku_code", e.target.value)
              }
            />
          </div>
          <div>
            <ControllerInput
              control={control}
              name={`products[${index}].mrp`}
              label="MRP"
              placeholder="Enter Amount"
              inputWrapperClass={styles.inputWrapperClass}
              wrapperClass={styles.skuWrapperClass}
              onChange={(e) => handleInputChange(index, "mrp", e.target.value)}
            />
          </div>
        </div>
        {showDeleteIcon && (
          <div onClick={handleDeleteClick} className={styles.deleteSKU}>
            <DeleteIcon />
          </div>
        )}
      </div>
    );
  };

  const [skuUIList, setSkuUIList] = useState([
    {
      product_sku: "",
      sku_code: "",
      mrp: "",
      product_image: null,
      photoURL: null,
    },
  ]);

  const handleInputChange = (index, field, value) => {
    setSkuUIList((prevList) => {
      const newList = [...prevList];
      newList[index][field] = value;
      return newList;
    });
  };

  const handleAddClick = () => {
    setSkuUIList((prevList) => [
      ...prevList,
      {
        product_sku: "",
        sku_code: "",
        mrp: "",
        product_image: null,
        photoURL: null,
      },
    ]);
  };

  const handleDeleteSKU = (indexToDelete) => {
    if (skuUIList.length === 1) {
      // If only one SKU is left, clear the values
      setValue(`products[${indexToDelete}].product_sku`, "");
      setValue(`products[${indexToDelete}].sku_code`, "");
      setValue(`products[${indexToDelete}].mrp`, "");
      setValue(`products[${indexToDelete}].photoURL`, null);
      setSkuUIList([
        {
          product_sku: "",
          sku_code: "",
          mrp: "",
          product_image: null,
          photoURL: null,
        },
      ]);
    } else {
      // Remove the SKU at the specified index
      const newSkuUIList = skuUIList.filter(
        (_, index) => index !== indexToDelete
      );
      // const test = getValues();
      const newData = getValues().products?.filter(
        (_, index) => index !== indexToDelete
      );
      // console.log(test, newData);
      setSkuUIList(newSkuUIList);
      // Remove the form values for the deleted SKU
      reset({
        ...getValues(),
        products: newSkuUIList.map((sku) => ({
          product_sku: sku.product_sku,
          sku_code: sku.sku_code,
          mrp: sku.mrp,
        })),
      });
      newData.forEach((variant, index) => {
        setValue(`products[${index}].product_sku`, variant.product_sku);
        setValue(`products[${index}].sku_code`, variant.sku_code);
        setValue(`products[${index}].mrp`, variant.mrp);
      });
    }
  };

  const handleFileChange = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let formData = new FormData();
    formData.append("company_id", ctx?.selectedCompany?.id);
    formData.append("excel", e.target?.files?.[0]);
    const result = await ctx.HttpPost(UPLOAD_EXCEL, formData);
    if (result.status) {
      ctx.showToastAlert({
        type: "success",
        position: "bottom-center",
        message: <div className="toastErrorMessage">uploaded successfully</div>,
      });
    }
  };

  const fileInputRef = useRef(null);
  const downloadExcel = async () => {
    let formData = new FormData();
    formData.append("company_id", ctx?.selectedCompany?.id);
    const result = await ctx.HttpPost(DOWNLOAD_EXCEL, formData);
    if (result.status) {
      downloadFile(result.msg);
    }
  };
  const downloadFile = async (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "example.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <AppLayout
      title={ctx?.selectedCompany?.name + "-" + "Campaign"}
      backButton={false}
    >
      <div className={styles.mainContainer}>
        <div className={styles.createCompanyBtn}>
          <CustomBreadcrumbs
            item1="Company"
            item2={ctx?.selectedCompany?.name}
            lastTypographyColor="#0000004D"
          />
        </div>
        <div className={styles.uploadBtns}>
          <div style={{ cursor: 'pointer' }}
            className={`${styles.btnTitleStyling} ${styles.downloadExcel}`}
            onClick={() => downloadExcel()}
          >
            <span className={styles.Iconstyling}>
              <DownloadIcon color="#6C5CFF" />
            </span>
            DOWNLOAD EXCEL
          </div>
          <div className={styles.profileImageDiv} style={{ cursor: 'pointer' }}>
            <label htmlFor="fileInput" className={styles.customInputLabel}>
              <CustomButton
                onClick={() => fileInputRef.current.click()}
                // style={{ cursor: 'pointer' }}
                title={
                  <div className={styles.btnTitleStyling}>
                    <span className={styles.Iconstyling}>
                      <UploadIcon />
                    </span>
                    UPLOAD EXCEL
                  </div>
                }
                wrapperClass={styles.wrapperClassUploadBtn}
              />
            </label>
            <input
              id="fileInput"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={handleFileChange}
              ref={fileInputRef}
              className={styles.uploadFile}
              style={{ position: 'absolute', left: '-9999px' }}
            />
          </div>
          <div
            onClick={(e) => {
              setAddProductModal(true);
              e.stopPropagation();
            }}
          >
            <CustomButton
              title={
                <div className={styles.btnTitleStyling}>
                  <span className={styles.Iconstyling}>
                    <CreateIcon />
                  </span>
                  Add Product
                </div>
              }
              wrapperClass={styles.wrapperClassBtn}
            />
          </div>
        </div>

        <Table
          tableParams={{ params, setParams }}
          tableHeader={tableHeader}
          tableData={data}
          tableSearchInput={searchInput}
          wrapClassName={styles.cardBodyList}
        />
        {addProductModal && (
          <CustomizedDialogs
            handleClose={() => {
              setEditProduct();
              setAddProductModal(false);
              setSkuUIList([
                {
                  product_sku: "",
                  sku_code: "",
                  mrp: "",
                  product_image: null,
                  photoURL: null,
                },
              ]);
              reset({});
            }}
            modalTitle={editProduct ? "Edit Product" : "Add Product"}
            modalContent={
              <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ControllerInput
                    control={control}
                    name="productName"
                    id="productName"
                    label="Product Name"
                    placeholder="Enter Product Name"
                    wrapperClass={styles.productNameInput}
                  />
                  {skuUIList.map((skuUI, index) => (
                    <div key={index}>
                      <SKUUI
                        index={index}
                        handleInputChange={handleInputChange}
                        handleFileUploadChange={handleFileUploadChange}
                        photoURL={skuUI.photoURL}
                        handleDeleteSKU={() => handleDeleteSKU(index)}
                        editProduct={editProduct}
                        handleImageRemove={() => handleImageRemove(index)}
                      />
                    </div>
                  ))}
                  <div
                    className={styles.addSKUContainer}
                    onClick={handleAddClick}
                  >
                    <AddSkuIcon />
                    <div className={styles.addSKUBtn}>ADD SKU</div>
                  </div>
                  <div className={styles.addProductContainer}>
                    <CustomButton
                      title={editProduct ? "SAVE" : "ADD PRODUCT"}
                      wrapperClass={styles.wrapperClassBtn}
                    />
                  </div>
                </form>
              </div>
            }
          />
        )}
        {deletecompany && (
          <CustomDeleteModal
            flag={deletecompany}
            content="Do you want to delete<br />this product ?"
            handleClose={() => setDeletecompany(null)}
            onClick={onDeleteCompany}
          />
        )}
      </div>
    </AppLayout>
  );
};
