/** @format */

import React from "react";

const SortIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.741"
      height="7.784"
      viewBox="0 0 12.741 7.784"
    >
      <path
        id="Path_233686"
        data-name="Path 233686"
        d="M3881.075,5426.839l4.957,4.956,4.956-4.956"
        transform="translate(-3879.661 -5425.425)"
        fill="none"
        stroke="gray"
        stroke-linecap="round"
        stroke-width="2"
      />
    </svg>
  );
};

export default SortIcon;
