/** @format */

import React from "react";

const CampaignUpload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45.985"
      height="46.296"
      viewBox="0 0 45.985 46.296"
    >
      <g
        id="Group_135708"
        data-name="Group 135708"
        transform="translate(1.061 1.061)"
      >
        <path
          id="Path_229001"
          data-name="Path 229001"
          d="M47.17,20.175V37.354a9.816,9.816,0,0,1-9.816,9.817H12.813A9.816,9.816,0,0,1,3,37.354V12.813A9.816,9.816,0,0,1,12.813,3H27.537"
          transform="translate(-2.996 -2.996)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Path_229002"
          data-name="Path 229002"
          d="M3,15.91l3.178-3.178a5.917,5.917,0,0,1,8.368,0L25.083,23.272"
          transform="translate(-2.996 8.632)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Path_229003"
          data-name="Path 229003"
          d="M7,31.182,22.45,15.733a5.917,5.917,0,0,1,8.368,0L40.485,25.4"
          transform="translate(2.823 12.993)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <g
          id="Group_135709"
          data-name="Group 135709"
          transform="translate(30.676 0)"
        >
          <path
            id="Path_229004"
            data-name="Path 229004"
            d="M18,5.5l6.135,6.135"
            transform="translate(-11.867 -5.497)"
            fill="none"
            stroke="#323232"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Path_229005"
            data-name="Path 229005"
            d="M15.5,11.633,21.637,5.5"
            transform="translate(-15.501 -5.497)"
            fill="none"
            stroke="#323232"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Path_229006"
            data-name="Path 229006"
            d="M18,3V15.267"
            transform="translate(-11.867 -2.996)"
            fill="none"
            stroke="#323232"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default CampaignUpload;
