/** @format */

import React from "react";

const Attachment = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.834"
      height="21.902"
      viewBox="0 0 11.834 21.902"
    >
      <g id="noun_Attachment_1084336" transform="translate(-37.506 -26.065)">
        <path
          id="Path_8"
          data-name="Path 8"
          d="M48.591,31.3V42.083a5.166,5.166,0,1,1-10.333-.066.334.334,0,0,1,0-.066h0V30.122a3.45,3.45,0,0,1,6.9.044V42.158a1.707,1.707,0,0,1-3.415,0h0V31.3"
          transform="translate(0)"
          fill="none"
          stroke={color}
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
    </svg>
  );
};

export default Attachment;
