/** @format */

import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import NavigateIcon from "../../../assets/icons/NavigateIcon";

function CustomBreadcrumbs({
  item1,
  item2,
  item3,
  item4,
  item5,
  lastTypographyColor,
}) {
  return (
    <Breadcrumbs
      separator={<NavigateIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <div style={{ marginBottom: "8px" }}>
        <HomeIcon
          sx={{
            mr: 0.5,
            marginTop: "15px",
            color: "#414146",
            fontSize: 14,
          }}
        />
      </div>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          fontWeight: 600,
        }}
        color={lastTypographyColor && !item2 ? lastTypographyColor : "#414146"}
      >
        {item1}
      </Typography>
      {item2 && (
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: 600,
          }}
          color={
            lastTypographyColor && !item3 ? lastTypographyColor : "#414146"
          }
        >
          {item2}
        </Typography>
      )}
      {item3 && (
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: 600,
          }}
          color={
            lastTypographyColor && !item4 ? lastTypographyColor : "#414146"
          }
        >
          {item3}
        </Typography>
      )}
      {item4 && (
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: 600,
          }}
          color={
            lastTypographyColor && !item5 ? lastTypographyColor : "#414146"
          }
        >
          {item4}
        </Typography>
      )}
      {item5 && (
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: 600,
          }}
          color={lastTypographyColor ? lastTypographyColor : "#414146"}
        >
          {item5}
        </Typography>
      )}
    </Breadcrumbs>
  );
}

export default CustomBreadcrumbs;
