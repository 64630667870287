/** @format */

import React, { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./styles.module.css";
import { ControllerInput } from "../../components/common/ControllerInput";
import CustomButton from "../../components/common/CustomButton";
import { AppContext } from "../../helpers/app-context";
import { useLocation, useNavigate } from "react-router-dom";
import { RESET_PASSWORD } from "../../helpers/api-endpoints";
import SuccessIcon from "../../assets/svgs/SuccessIcon.png";
const schema = yup.object().shape({
  newPassword: yup.string().required("This field is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("This field is required"),
});
const ResetPassword = () => {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const [resetPassword, setResetPassword] = useState(true);
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  const onSubmit = async (data) => {
    let formData = new FormData();
    formData.append("email", state);
    formData.append("password", data.confirmPassword);
    const result = await ctx.HttpPost(RESET_PASSWORD, formData);
    if (result.status) {
      setResetPassword(false);
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginWrapper}>
        <div className={styles.formTitle}>Magic Ads</div>
        {resetPassword ? (
          <>
            <div className={styles.formSubTitle}>Reset Password</div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ControllerInput
                control={control}
                name="newPassword"
                id="newPassword"
                label="New Password"
                placeholder="Please enter new password"
                labelWrapperClass={styles.labelWrapperClass}
                inputWrapperClass={styles.inputWrapperClass}
              />
              <ControllerInput
                control={control}
                name="confirmPassword"
                id="confirmPassword"
                label="Confirm Password"
                placeholder="Re enter new password"
                labelWrapperClass={styles.labelWrapperClass}
                inputWrapperClass={styles.inputWrapperClass}
              />

              <CustomButton title="SUBMIT" />
            </form>
          </>
        ) : (
          <div className={styles.successContainer}>
            <div className={styles.formSubTitle}>Congratulations!</div>
            <img
              src={SuccessIcon}
              alt="not found"
              width={"209px"}
              height={"157px"}
            />
            <div className={styles.successMsg}>
              Your password has been reset successfully
            </div>

            <CustomButton title="LOG IN" onClick={() => navigate("/login")} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
