/** @format */

import React from "react";

const BackwordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_30" data-name="Group 30" transform="translate(-3577 -2988)">
        <rect
          id="Rectangle_23"
          data-name="Rectangle 23"
          width="24"
          height="24"
          transform="translate(3577 2988)"
          fill="none"
        />
        <g id="Group_28" data-name="Group 28" transform="translate(-30.16 96)">
          <path
            id="Path_12"
            data-name="Path 12"
            d="M3619.618,2893.469l-5.458,5.353,5.458,5.458"
            transform="translate(-3 5.531)"
            fill="none"
            stroke="#707070"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_13"
            data-name="Path 13"
            d="M3611.691,2904.406h14.937"
            fill="none"
            stroke="#707070"
            stroke-linecap="round"
            stroke-width="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default BackwordIcon;
