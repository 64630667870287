/** @format */

import React, { useContext, useEffect, useState } from "react";
import styles from "./OutletDetails.module.css";
import AppLayout from "../../../components/layout";
import { Box } from "@mui/material";
import CustomButton from "../../../components/common/CustomButton";
import { useNavigate } from "react-router-dom";
import CopyLinkIcon from "../../../assets/icons/CopyLinkIcon";
import ViewMoreTable from "../../../components/common/ViewMoreTable";
import { AppContext } from "../../../helpers/app-context";
import {
  OUTLET_ACTIVE_INACTIVE,
  OUTLET_LIST,
} from "../../../helpers/api-endpoints";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";

const OutletDetails = () => {
  const ctx = useContext(AppContext);
  const [data, setData] = useState([]);
  const getData = async () => {
    setData([]);
    let formData = new FormData();
    formData.append("company_id", ctx?.selectedCompany?.id);
    const result = await ctx.HttpPost(OUTLET_LIST, formData);
    if (result.status) {
      setData(result.data);
    }
  };
  useEffect(() => {
    if (ctx?.selectedCompany?.id) {
      getData();
    }
  }, [ctx.selectedCompany]);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyLink = async (link) => {
    await navigator.clipboard.writeText(link);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000); // Reset the copied state after 2 seconds
  };
  const [params, setParams] = useState({});

  const AntSwitch = styled(Switch)(({ theme, active }) => ({
    width: 44,
    height: 24,
    // width: 34,
    // height: 17,
    padding: 0,
    display: "flex",
    borderRadius: 30,
    "& .MuiSwitch-switchBase": {
      padding: "4px 2px 4px 3.5px",
      "&.Mui-checked": {
        transform: "translateX(21px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: active ? "#C60303" : "#018B2F",
          // backgroundColor:
          //   theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 20,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(14px)",
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 16,
      height: 16,
      borderRadius: 8,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: active ? "#C60303" : "#018B2F",
      // backgroundColor:
      //   theme.palette.mode === "dark"
      //     ? "rgba(255,255,255,.35)"
      //     : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const handleToggle = async (row) => {
    // Find the index of the row in the data array
    const rowIndex = data.findIndex((item) => item.outlet_id === row.outlet_id);
    if (rowIndex !== -1) {
      // Create a shallow copy of the data array to modify the specific row
      const newData = [...data];
      // Update the 'is_active' value of the specific row
      newData[rowIndex].is_active =
        newData[rowIndex].is_active === "0" ? "1" : "0";
      setData(newData);

      let formData = new FormData();
      formData.append("outlet_id", row.outlet_id);
      formData.append("status", newData[rowIndex].is_active);

      const result = await ctx.HttpPost(OUTLET_ACTIVE_INACTIVE, formData);
      if (result.status) {
        ctx.showToastAlert({
          type: "success",
          position: "bottom-center",
          message: <div className="toastErrorMessage">{result.msg}</div>,
        });
      }
    }
  };

  const tableHeader = [
    {
      key: "outlet_name",
      label: "Outlets Name",
      sorting: true,
      cardColumn: styles.outletcardColumn,
      headerClasses: styles.outletheaderClasses,
      render: (column) => {
        return (
          <Box className={styles.stackToggle}>
            <Box onClick={() => handleToggle(column)}>
              <Stack>
                <AntSwitch
                  active={column.is_active !== "0"}
                  defaultChecked={column.is_active === "0"}
                  inputProps={{ "aria-label": "ant design" }}
                />
                <div
                  style={{
                    color: column.is_active !== "0" ? "#C60303" : "#018B2F",
                    fontSize: "10px",
                    paddingTop: "6px",
                  }}
                >
                  {column.is_active !== "0" ? "Inactive" : "Active"}
                </div>
              </Stack>
            </Box>

            <Box
              sx={{ marginLeft: "8px " }}
              onClick={() =>
                navigate("/outlet-details/campaigns-list", {
                  state: {
                    outlet_id: column?.outlet_id,
                    outlet_name: column?.outlet_name,
                  },
                })
              }
            >
              {column.outlet_name}
            </Box>
          </Box>
        );
      },
    },
    {
      key: "location",
      label: "Address",
      cardColumn: styles.cardColumn,
      headerClasses: styles.headerClasses,
    },
    {
      key: "locationUrl",
      label: "Location URL",
      cardColumn: styles.urlcardColumn,
      render: (column) => {
        return (
          <div className={styles.viewUrlContainer}>
            <div
              className={styles.viewUrl}
              onClick={() => handleCopyLink(column?.map_url)}
            >
              <Box className={styles.viewTitle}>view</Box>
              <div className={styles.copyContainer}>
                {isCopied ? (
                  <Box className={styles.copiedText}>Copied!</Box>
                ) : (
                  <Box className={styles.clickToCopyText}>copy link</Box>
                )}
                <Box>
                  <CopyLinkIcon />
                </Box>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      label: "Active Camp",
      cardColumn: styles.activecardColumn,
      render: (column) => {
        return (
          <Box
            sx={{ marginLeft: "8px " }}
            onClick={() =>
              navigate("/outlet-details/campaigns-list", {
                state: {
                  outlet_id: column?.outlet_id,
                  outlet_name: column?.outlet_name,
                },
              })
            }
          >
            {column.campaign.active}({column.campaign.total})
          </Box>
          // <div className={styles.activeCamp}>
          //   {column.campaign.active}({column.campaign.total})
          // </div>
        );
      },
    },
    {
      key: "sales_person_name",
      label: "Sales Person",
      cardColumn: styles.namecardColumn,
      headerClasses: styles.nameheaderClasses,
    },
    {
      label: "",
      cardColumn: styles.labelcardColumn,
    },
  ];
  const viewmoreTableHeader = [
    {
      label: "Tag Stock Request",
      viewMoreValuesCol: true,
      render: (column) => {
        return (
          <Box
            className={styles.viewOutletDetails}
            onClick={() =>
              navigate("/outlet-details/tag-stock-request", {
                state: {
                  outlet_id: column?.outlet_id,
                  outlet_name: column?.outlet_name,
                  outletCoupon: true,
                },
              })
            }
          >
            ({column.request.record})
          </Box>
        );
      },
    },
    {
      label: "Order Request",
      viewMoreValuesCol: true,
      render: (column) => {
        return (
          <Box
            className={styles.viewOutletDetails}
            onClick={() =>
              navigate("/outlet-details/order-request", {
                state: {
                  outlet_id: column?.outlet_id,

                  outlet_name: column?.outlet_name,

                  outletCoupon: true,
                },
              })
            }
          >
            ({column.request.order})
          </Box>
        );
      },
    },
    {
      label: "Active Coupon",
      viewMoreValuesCol: true,
      render: (column) => {
        return (
          <Box
            className={styles.viewOutletDetails}
            color={"#39579A"}
            onClick={() =>
              navigate("/outlet-details/active-coupon", {
                state: {
                  outlet_id: column?.outlet_id,

                  outlet_name: column?.outlet_name,

                  outletCoupon: true,
                },
              })
            }
          >
            ({column.coupons.live})
          </Box>
        );
      },
    },
    {
      label: "Redeem Coupon",
      viewMoreValuesCol: true,
      render: (column) => {
        return (
          <Box
            color={"#39579A"}
            className={styles.viewOutletDetails}
            onClick={() =>
              navigate("/outlet-details/redeemed-coupon", {
                state: {
                  outlet_id: column?.outlet_id,

                  outlet_name: column?.outlet_name,

                  outletCoupon: true,
                },
              })
            }
          >
            ({column.coupons.redeem})
          </Box>
        );
      },
    },
    {
      viewMoreValuesCol: true,
      render: (column) => {
        return (
          <CustomButton
            title="Credit History"
            wrapperClass={styles.creditHistoryBtn}
            onClick={() =>
              navigate("/outlet-details/credit-history", {
                state: {
                  outlet_id: column?.outlet_id,

                  outlet_name: column?.outlet_name,

                  outletCoupon: true,
                },
              })
            }
          />
        );
      },
    },
  ];
  const searchInput = {
    key: "outlet_name",
    placeholder: "Search outlet",
  };
  const navigate = useNavigate();
  return (
    <AppLayout
      title={`${ctx?.selectedCompany?.name}- Outlets`}
      backButton={false}
    >
      <div className={styles.mainContainer}>
        <div className={styles.createCompanyBtn}>
          <CustomBreadcrumbs
            item1="Outlets"
            item2={ctx?.selectedCompany?.name}
            lastBoxColor="#0000004D"
          />
        </div>
        {/* <div className={styles.uploadBtns}>
          <CustomButton
            title="CREATE OUTLET"
            wrapperClass={styles.wrapperClassBtn}
            onClick={() => navigate("/outlet-details/create-outlet")}
          />
        </div> */}

        <ViewMoreTable
          viewmoreTableHeader={viewmoreTableHeader}
          tableParams={{ params, setParams }}
          tableHeader={tableHeader}
          tableData={data}
          tableSearchInput={searchInput}
        />
      </div>
    </AppLayout>
  );
};

export default OutletDetails;
