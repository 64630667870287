/** @format */

import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styles from "./CampaignTabs.module.css";

export default function CampaignTabs({ onChange, value, campaignData }) {
  return (
    <Box
      sx={{
        bgcolor: "#FDFDFD",
        marginBottom: "28px",
        boxShadow: "0px 3px 6px #00000029",
        width: "100%",
      }}
    >
      <Tabs
        value={value}
        onChange={onChange}
        variant="scrollable"
        scrollButtons={false}
        aria-label="scrollable prevent tabs example"
        className={styles.tabContainer}
        sx={{
          "& .css-lk6x5s-MuiTabs-indicator": {
            height: "0px",
            backgroundColor: "none",
            boxShadow: "0px 3px 6px #00000029",
          },
          "& .MuiTabs-scroller": {
            display: "flex",
          },
          "& .MuiTabs-flexContainer": {
            width: "52.6%",
          },
          "& .Mui-selected": {
            color: "#f6f5ff",
            backgroundColor: "#6c5cff",
            textTransform: "none",
            textAlign: "center",
          },
          "& .css-16fpmzx": {
            width: "0px !important",
          },
        }}
      >
        {campaignData.map((item, index) => {
          return (
            <Tab
              key={index}
              label={item}
              className={styles.tabWrapper}
              sx={{
                "&.Mui-selected": {
                  color: "#f6f5ff",
                  backgroundColor: "#6c5cff",
                  textTransform: "none",

                  borderTopLeftRadius: index === 0 && "16px",
                  borderTopRightRadius: index === 1 && "16px",
                  flex: 1,
                  textAlign: "center",
                },
              }}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
