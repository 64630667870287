/** @format */

import React, { useContext, useEffect, useState } from "react";
import AppLayout from "../../../components/layout";
import Table from "../../../components/common/Table";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../helpers/app-context";
import { ACTIVE_COUPON_BY_CAMPAIGN } from "../../../helpers/api-endpoints";
import styles from "../TotalCampaign/TotalCampaign.module.css";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
const ActiveCoupon = () => {
  const ctx = useContext(AppContext);
  const { state } = useLocation();
  const [data, setData] = useState([]);

  const getData = async () => {
    setData([]);
    let formData = new FormData();
    formData.append("outlet_id", state.outlet_id);
    formData.append("company_id", ctx?.selectedCompany?.id);
    formData.append("campaign_id", 0);
    formData.append("type", "active");
    const result = await ctx.HttpPost(ACTIVE_COUPON_BY_CAMPAIGN, formData);
    if (result.status) {
      setData(result.data);
    }
  };
  useEffect(() => {
    if (ctx?.selectedCompany?.id) {
      getData();
    }
  }, [ctx?.selectedCompany]);
  const [params, setParams] = useState({});
  const tableHeader = [
    {
      key: "coupon_code",
      label: "Coupon Id",
    },
    // {
    //   key: "customer_name",
    //   label: "Customer Info",
    // },
    {
      key: "customer_mobile",
      label: "Mobile No.",
    },
    {
      key: "campaign_name",
      label: "Campaign Name",
    },
    {
      key: "expiry_date",
      label: "Expiry Date",
    },
  ];

  return (
    <AppLayout title="Active coupon" backButton={true}>
      <div className={styles.mainContainer}>
        <div className={styles.createCompanyBtn}>
          {state.outletCoupon ? (
            <CustomBreadcrumbs
              item1="Outlets"
              item2={ctx?.selectedCompany?.name}
              item3={state?.outlet_name}
              item4="Active coupon"
              lastTypographyColor="#0000004D"
            />
          ) : (
            <CustomBreadcrumbs
              item1="Company"
              item2={ctx?.selectedCompany?.name}
              item3="Sales person"
              item4="Outlet info"
              item5="Live coupon"
              lastTypographyColor="#0000004D"
            />
          )}
        </div>
        <Table
          tableParams={{ params, setParams }}
          tableHeader={tableHeader}
          tableData={data}
          tableWrapper={styles.tableWrapper}
        />
      </div>
    </AppLayout>
  );
};

export default ActiveCoupon;
