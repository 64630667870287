/** @format */

import { Route, Routes, Navigate, HashRouter } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { theme } from "./theme/index";
import Login from "./pages/authentication/Login";
import { CompanyList } from "./pages/Company/CompanyList";
import AddSalesPerson from "./pages/Company/AddSalesPerson";
import CreateCompany from "./pages/Company/CreateCompany";
import CreateCampaign from "./pages/Company/CreateCampaign";
import CampaignListDetails from "./pages/Company/CampaignListDetails";
import SalesPerson from "./pages/Company/SalesPerson";
import CampaignList from "./pages/Company/CampaignList";
import OutletDetails from "./pages/Outlets/OutletDetails";
import RecordRequest from "./pages/Outlets/RecordRequest";
import OutletCampaignList from "./pages/Outlets/OutletCampaignList";
import { ProductSku } from "./pages/Company/ProductSku";
import CreateOutlet from "./pages/Outlets/CreateOutlet";
import { ToastContainer } from "react-toastify";
import OutletInfo from "./pages/Company/OutletInfo";
import OrderRequest from "./pages/Company/OrderRequest";
import TagStockRequest from "./pages/Company/TagStockRequest";
import TotalCampaign from "./pages/Company/TotalCampaign";
import ActiveCoupon from "./pages/Company/ActiveCoupon";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import VerifyOTP from "./pages/authentication/VerifyOTP";
import ResetPassword from "./pages/authentication/ResetPassword";
import RedeemedCoupon from "./pages/Outlets/RedeemedCoupon";
import PublicRoutes from "./components/routing/publicRoutes";
import PrivateRoutes from "./components/routing/privateRouting";
import EditCampaignList from "./pages/Company/EditCampaignList";
import CreditHistory from "./pages/Company/CreditHistory";
import "./App.css";
import { useEffect } from "react";
import Tickets from "./pages/HelpSupport/Tickets";
import CustomerData from "./pages/Warranty/CustomerData";
import Payout from "./pages/Payout/Payout";
import UpdateEditList from "./pages/Company/EditCampaignList/UpdateEditList";
function App() {
  useEffect(() => {
    document.title = "Admin Panel";
  }, []);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <HashRouter>
          <Routes>
            <Route element={<PublicRoutes />}>
              <Route element={<Login />} path="/login" />
              <Route element={<ForgotPassword />} path="/forgot-password" />
              <Route element={<VerifyOTP />} path="/verify-otp" />
              <Route element={<ResetPassword />} path="/reset-password" />
              <Route path="*" element={<Navigate to="/login" />} />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route element={<CompanyList />} path="/company-list" />

              <Route
                element={<AddSalesPerson />}
                path="/sales-person/add-sales-person"
              />
              <Route
                element={<CreateCompany />}
                path="/company-list/create-company"
              />
              <Route
                element={<CreateCompany />}
                path="/company-list/edit-company"
              />
              <Route
                element={<CreateCampaign />}
                path="/campaign-list/create-campaign"
              />
              <Route
                element={<EditCampaignList />}
                path="/campaign-list/edit-campaign"
              />
               <Route
                element={<UpdateEditList />}
                path="/campaign-list/update-edit-campaign"
              />
              <Route element={<SalesPerson />} path="/sales-person" />
              <Route element={<ProductSku />} path="/product-sku" />
              <Route element={<CampaignList />} path="/campaign-list" />
              <Route
                element={<CampaignListDetails />}
                path="/campaign-list/campaign-list-details"
              />
              <Route
                element={<OutletInfo />}
                path="/sales-person/outlet-info"
              />
              <Route
                element={<OrderRequest />}
                path="/sales-person/order-request"
              />
              <Route
                element={<TagStockRequest />}
                path="/sales-person/tag-stock-request"
              />
              <Route
                element={<TotalCampaign />}
                path="/sales-person/total-campaign"
              />
              <Route
                element={<ActiveCoupon />}
                path="/sales-person/active-coupon"
              />
              <Route
                element={<RedeemedCoupon />}
                path="/sales-person/redeemed-coupon"
              />
              <Route
                element={<CreditHistory />}
                path="/sales-person/credit-history"
              />
              {/* Outlet */}

              <Route element={<OutletDetails />} path="/outlet-details" />
              <Route
                element={<OutletCampaignList />}
                path="/outlet-details/campaigns-list"
              />
              <Route
                element={<RecordRequest />}
                path="/outlet-details/record-request"
              />
              <Route
                element={<CreateOutlet />}
                path="/outlet-details/create-outlet"
              />
              <Route
                element={<RedeemedCoupon />}
                path="/outlet-details/redeemed-coupon"
              />
              <Route
                element={<OrderRequest />}
                path="/outlet-details/order-request"
              />
              <Route
                element={<TagStockRequest />}
                path="/outlet-details/tag-stock-request"
              />
              <Route
                element={<ActiveCoupon />}
                path="/outlet-details/active-coupon"
              />
              <Route
                element={<CreditHistory />}
                path="/outlet-details/credit-history"
              />

              {/* warranty managment */}
              <Route
                element={<CustomerData />}
                path="/warranty/customer-data"
              />

              <Route path="*" element={<Navigate to="/campaign-list" />} />
            </Route>
            {/* Help & Support */}
            <Route element={<Tickets />} path="/help-support/ticket" />
            {/* Payouts */}
            <Route element={<Payout />} path="/payout" />
          </Routes>
        </HashRouter>

        <ToastContainer limit={1} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
