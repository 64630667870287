/** @format */

import React, { useContext, useEffect, useState } from "react";
import styles from "./CampaignList.module.css";
import AppLayout from "../../../components/layout";
import { Box } from "@mui/material";
import ActiveIcon from "../../../assets/icons/ActiveIcon";
import Table from "../../../components/common/Table";
import CustomButton from "../../../components/common/CustomButton";
import CreateIcon from "../../../assets/icons/CreateIcon";
import { useNavigate } from "react-router-dom";
import CopyLinkIcon from "../../../assets/icons/CopyLinkIcon";
import { AppContext } from "../../../helpers/app-context";
import {
  CAMPAIGN_LIST,
  DELETE_CAMPAIGN,
  UPDATE_COST_PER_LEAD,
} from "../../../helpers/api-endpoints";
import EditIcon from "../../../assets/icons/EditIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import { CustomDeleteModal } from "../../../components/common/CustomDeleteModal";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import CustomizedDialogs from "../../../components/common/CustomModal";
import { useForm } from "react-hook-form";
import { ControllerInput } from "../../../components/common/ControllerInput";
const CampaignList = () => {
  const { handleSubmit, control, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [lead, setLead] = useState(false);
  const [deletecompany, setDeletecompany] = useState(null);
  const [params, setParams] = useState({});
  const ctx = useContext(AppContext);

  const getData = async () => {
    setData([]);
    let formData = new FormData();
    formData.append("company_id", ctx?.selectedCompany?.id);
    const result = await ctx.HttpPost(CAMPAIGN_LIST, formData);
    if (result.status) {
      setData(result.data);
    }
  };

  const handleDelete = (data) => {
    setDeletecompany(data);
  };

  const onDeleteCompany = async () => {
    //api call pass deletecompany
    let formData = new FormData();
    formData.append("campaign_id", deletecompany?.id);
    const result = await ctx.HttpPost(DELETE_CAMPAIGN, formData);
    if (result.status) {
      setDeletecompany();
      getData();
    }
  };

  // const handleView = () => {};
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyLink = async (link) => {
    await navigator.clipboard.writeText(link);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000); // Reset the copied state after 2 seconds
  };
  const tableHeader = [
    {
      key: "campaign_name",
      label: "Campaign Name",
      headerClasses: styles.headerClasses,
      cardColumn: styles.cardColumn,
      sorting: true,
      render: (column) => {
        return (
          <Box
            className={styles.avatarWrapper}
            onClick={() =>
              navigate("/campaign-list/campaign-list-details", {
                state: {
                  campaign_id: column.id,
                  campaign_name: column?.campaign_name,
                  column,
                },
              })
            }
          >
            <img src={column.image} alt="" width={"50px"} height={"40px"} />
            <div>{column?.campaign_name}</div>
          </Box>
        );
      },
    },
    {
      key: "budget",
      label: "Budget",
    },
    {
      key: "url",
      label: "URL",
      cardColumn: styles.urlcardColumn,
      render: (column) => {
        return (
          <div className={styles.viewUrlContainer}>
            <div
              className={styles.viewUrl}
              onClick={() => handleCopyLink(column?.url)}
            >
              <div className={styles.viewTitle}>view</div>
              <div className={styles.copyContainer}>
                {isCopied ? (
                  <div className={styles.copiedText}>Copied!</div>
                ) : (
                  <div className={styles.clickToCopyText}>copy link</div>
                )}
                <div>
                  <CopyLinkIcon />
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      key: "is_active",
      label: "Active Outlet Count",
      headerClasses: styles.activeOutletCount,

      render: (column) => {
        return (
          <Box className={styles.avatarWrapper}>
            <ActiveIcon />
            <div>
              {column.outlet_status.active}/{column.outlet_status.total}
            </div>
          </Box>
        );
      },
    },
    {
      label: "",
      render: (column) => {
        return (
          <div style={{ display: "flex" }}>
            <div
              style={{ marginRight: "35px", marginLeft: "5px" }}
              onClick={() =>
                navigate("/campaign-list/edit-campaign", {
                  state: {
                    column,
                    campId:column.id
                  },
                })
              }
            >
              <EditIcon />
            </div>
            <div onClick={() => handleDelete(column)}>
              <DeleteIcon />
            </div>
          </div>
        );
      },
    },
  ];

  const searchInput = {
    key: "campaign_name",
    placeholder: "Search Campaign",
  };

  const onSubmit = async (data) => {
    // Create an array to store cost_per_lead objects
    const costPerLeadArray = [];

    // Create a mapping of campaign_id to index
    const campaignIdToIndexMap = {};

    // Populate the mapping
    data.products.forEach((item, index) => {
      campaignIdToIndexMap[item.id] = index;
    });

    // Iterate through the data array and update cost_per_lead using the mapping
    data.products.forEach((item) => {
      const updatedLeadPrice = item.lead_price; // Get the updated lead_price
      const campaignId = item.id; // Get the campaign_id

      // Construct the cost_per_lead object
      costPerLeadArray.push({
        campaign_id: campaignId,
        cost_per_lead: updatedLeadPrice,
      });
    });

    // Create a FormData instance
    let formData = new FormData();

    // Append the company_id to the FormData
    formData.append("company_id", ctx?.selectedCompany?.id);

    // Append the cost_per_lead array as a JSON string
    formData.append("cost_per_lead", JSON.stringify(costPerLeadArray));
    const result = await ctx.HttpPost(UPDATE_COST_PER_LEAD, formData);

    if (result.status) {
      setLead(false);
      getData();
    }
  };

  useEffect(() => {
    if (ctx?.selectedCompany?.id) {
      getData();
    }
  }, [ctx?.selectedCompany]);

  useEffect(() => {
    data.forEach((item) => {
      setValue(`products[${item.id}].lead_price`, item.cost_per_lead);
    });
  }, [data, setValue]);

  return (
    <AppLayout
      title={ctx?.selectedCompany?.name + "-" + "Campaign"}
      backButton={false}
    >
      <div className={styles.mainContainer}>
        <div className={styles.createCompanyBtn}>
          <CustomBreadcrumbs
            item1="Company"
            item2={ctx?.selectedCompany?.name}
            lastTypographyColor="#0000004D"
          />
        </div>

        <div style={{ marginLeft: "18px", marginRight: "19px" }}>
          <>
            <div className={styles.uploadBtns}>
              <CustomButton
                onClick={() => navigate("/campaign-list/create-campaign")}
                title={
                  <div className={styles.btnTitleStyling}>
                    <span className={styles.Iconstyling}>
                      <CreateIcon color="#6C5CFF" />
                    </span>
                    Create Campaign
                  </div>
                }
                wrapperClass={styles.wrapperClassAddBtn}
              />

              {/* <CustomButton
                onClick={() => setLead(true)}
                title="Set Cost Per Lead"
                wrapperClass={styles.wrapperClassBtn}
              /> */}
            </div>

            <Table
              tableParams={{ params, setParams }}
              tableHeader={tableHeader}
              tableData={data}
              tableSearchInput={searchInput}
              wrapClassName={styles.cardBodyList}
              // tableWrapper={styles.tableWrapper}
            />
            {lead && (
              <CustomizedDialogs
                handleClose={() => {
                  setLead(false);
                }}
                modalTitle="Set Cost per lead"
                modalContent={
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className={styles.leadContainer}>
                        <div>Campaign Name</div>
                        <div className={styles.previousLabel}>
                          Cost per Lead ( ₹ )
                        </div>
                      </div>
                      {data.map((item) => {
                        return (
                          <div className={styles.leadInputContainer}>
                            <div className={styles.leadHeadings}>
                              {item?.campaign_name}
                            </div>
                            <ControllerInput
                              control={control}
                              name={`products[${item.id}].lead_price`}
                              placeholder="Lead Price"
                              inputWrapperClass={styles.inputWrapperClass}
                              wrapperClass={styles.skuWrapperClass}
                            />
                          </div>
                        );
                      })}
                      <div className={styles.leadwrapperClassBtn}>
                        <CustomButton
                          // onClick={() => setLead(false)}
                          title="SAVE"
                          wrapperClass={styles.wrapperClassBtn}
                        />
                      </div>
                    </form>
                  </div>
                }
              />
            )}
            {deletecompany && (
              <CustomDeleteModal
                flag={deletecompany}
                content="Do you want to delete<br />this Campaign?"
                handleClose={() => setDeletecompany(null)}
                onClick={onDeleteCompany}
              />
            )}
          </>
        </div>
      </div>
    </AppLayout>
  );
};

export default CampaignList;
