/** @format */

import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import styles from "./CustomModal.module.css";
import CloseIcon from "../../../assets/icons/CloseIcon";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "24px",
  },
  "& .MuiDialog-paperWidthSm": {
    minWidth: "794px",
    minHeigth: "250px",
    maxHeigth: "550px",
    overflow: "auto",
    boxSizing: "border-box",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ padding: "24px 40px" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  handleClose,
  modalTitle,
  modalContent,
}) {
  return (
    <div className={styles.modalContainer}>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        sx={{ borderRadius: "24px" }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={styles.modalTitle}
        >
          {modalTitle}
        </BootstrapDialogTitle>
        <DialogContent className={styles.modalContent}>
          {modalContent}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
