/** @format */

import { useState, createContext, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  AuthenticationError,
  HttpHelper,
  SessionTimeoutError,
  PartialContentError,
  InternalServerError,
  DataInsufficent,
} from "./httpHelper";
import { PAGINATION_DETAILS, SELECTED_COMPANY, TOKEN_NAME } from "./constant";
import { COMPANY_LIST, LOGIN, VERIFYOTP } from "./api-endpoints";
import { generateLocalToken } from "./functions";
import "./toast.css";
export const AppContext = createContext();

const partnerInfo = {
  language: localStorage.getItem("i18nextLng") || "",
  mobile: "",
  otp: "",
  entityType: "",
  email: "",
  firm_name: "",
  first_name: "",
  firm_email: "",
  middle_name: "",
  last_name: "",
  contact_person_email: "",
  pin_code: "",
  contact_person_mobile: "",
  state: "",
  city: "",
  constitution_id: "",
  panNo: "",
};

export const AppProvider = ({ children }) => {
  const [baseURL, setBaseURL] = useState(null);
  const [isLogout, setIsLogout] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [deviceID, setDeviceID] = useState(null);
  const [notification_token, setNotification_token] = useState(null);
  const [appReferrer, setAppReferrer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [companyList, setCompanyList] = useState([]);
  const [filterCount, setFilterCount] = useState();
  const [updateCompanyList, setUpdateCompanyList] = useState();
  const [modalData, setModalData] = useState({
    icon: "",
    showModal: false,
    titleText: "",
    messageText: "",
    showCancelButton: false,
    confirmButtonText: "Okay",
    type: null,
  });
  console.log(modalData);
  const [moduleState, setModuleState] = useState({
    moduleName: "",
    searchValue: "",
    currentpage: PAGINATION_DETAILS.CURRENT_PAGE,
    pageSize: PAGINATION_DETAILS.PAGE_SIZE,
    fromDate: "",
    toDate: "",
  });
  console.log(selectedCompany);
  const [contribution, setContribution] = useState(partnerInfo);
  const hasToken = localStorage.getItem(TOKEN_NAME);
  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    if (hasToken) {
      const storedSelectedCompany = localStorage.getItem(SELECTED_COMPANY);
      if (storedSelectedCompany) {
        setSelectedCompany(JSON.parse(storedSelectedCompany));
        getData();
      } else {
        getData();
      }
    }
  }, [hasToken, updateCompanyList]);

  const getData = async () => {
    const result = await HttpGet(COMPANY_LIST);
    if (result.status) {
      setCompanyList(result.data);
      if (selectedCompany?.id) {
        const foundCompany = result?.data?.find(
          (company) => company?.id === selectedCompany?.id
        );
        if (foundCompany) {
          setSelectedCompany(foundCompany);
          localStorage.setItem(SELECTED_COMPANY, JSON.stringify(foundCompany));
        } else {
          setSelectedCompany(result.data[0]); // Fallback to the first company in the array
          localStorage.setItem(
            SELECTED_COMPANY,
            JSON.stringify(result.data[0])
          );
        }
      } else if (JSON.parse(localStorage.getItem(SELECTED_COMPANY))) {
        const foundCompany = result?.data?.find(
          (company) =>
            company?.id ===
            JSON.parse(localStorage.getItem(SELECTED_COMPANY))?.id
        );
        if (foundCompany) {
          setSelectedCompany(foundCompany);
          localStorage.setItem(SELECTED_COMPANY, JSON.stringify(foundCompany));
        } else {
          setSelectedCompany(result.data[0]); // Fallback to the first company in the array
          localStorage.setItem(
            SELECTED_COMPANY,
            JSON.stringify(result.data[0])
          );
        }
        setSelectedCompany(JSON.parse(localStorage.getItem(SELECTED_COMPANY)));
      } else {
        setSelectedCompany(result.data[0]);
        localStorage.setItem(SELECTED_COMPANY, JSON.stringify(result.data[0]));
      }
    } else {
      setCompanyList(result.data);
      setSelectedCompany();
      localStorage.removeItem(SELECTED_COMPANY);
    }
  };

  const SetHttpContext = async () => {
    // eslint-disable-next-line no-undef
    let webURL =
      process.env.REACT_APP_API_ENDPOINT ||
      "https://magicadsadmin.omni.direct/";

    setBaseURL(webURL);
    let oContext = {
      BaseURL: webURL,
      AccessToken: localStorage.getItem(TOKEN_NAME),
    };
    HttpHelper.SetContext(oContext);
  };

  const HttpGet = async (aFunction, aParams, aIsCritical = false) => {
    setLoading(true);
    try {
      await SetHttpContext();

      let apiRes = await HttpHelper.HttpGet(aFunction, aParams);
      if (apiRes.status) {
        setLoading(false);
        return apiRes;
      }
      if (apiRes.msg) {
        setLoading(false);
        showToastAlert({
          type: "error",
          message: apiRes.msg,
        });
      }
      return false;
    } catch (e) {
      ErrorHandler(e, aIsCritical);
    } finally {
      // if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const HttpPost = async (aFunction, aPayload, aIsCritical = false) => {
    setLoading(true);
    try {
      await SetHttpContext();
      let apiRes = await HttpHelper.HttpPost(aFunction, aPayload);

      if (apiRes.status) {
        setLoading(false);
        return apiRes;
      }
      if (apiRes.msg) {
        setLoading(false);
        showToastAlert({
          type: "error",
          message: apiRes.msg,
        });
      }
      return false;
    } catch (e) {
      ErrorHandler(e, aIsCritical);
    } finally {
      // if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };
  const HttpPut = async (aFunction, aPayload, aIsCritical = false) => {
    try {
      await SetHttpContext();
      // if (aShowLoader) setShowSpinner(true);
      /*       await getAccessToken(api_type);
       */
      let apiRes = await HttpHelper.HttpPut(aFunction, aPayload);

      if (apiRes.status) {
        return apiRes;
      }
      if (apiRes.msg) {
        showToastAlert({
          type: "error",
          message: apiRes.msg,
        });
      }

      return false;
    } catch (e) {
      ErrorHandler(e, aIsCritical);
    } finally {
      // if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const HttpDelete = async (aFunction, aParams, aIsCritical = false) => {
    try {
      await SetHttpContext();
      // if (aShowLoader) setShowSpinner(true);
      // await checkRefreshToken();
      let apiRes = await HttpHelper.HttpDelete(aFunction, aParams);
      if (apiRes.status) {
        return apiRes;
      }
      if (apiRes.msg) {
        showToastAlert({
          type: "error",
          message: apiRes.msg,
        });
      }
      return false;
    } catch (e) {
      ErrorHandler(e, aIsCritical);
    } finally {
      // if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const ErrorHandler = (errorInfo, isCritical) => {
    // based on the type of error we will set the modal data and show the modal
    // setShowSpinner(false);
    // setIsTimeout(false);
    if (errorInfo instanceof SessionTimeoutError) {
      // setIsTimeout(true);
      // setModalData({
      //   icon: "",
      //   showModal: true,
      //   titleText: "Session Expired",
      //   messageText: errorInfo.msg,
      //   showCancelButton: false,
      //   confirmButtonText: "Okay",
      //   type: "SessionTimeout",
      // });
      showToastAlert({
        type: "error",
        message: errorInfo.message,
      });
    } else if (errorInfo instanceof AuthenticationError) {
      showToastAlert({
        type: "error",
        message: errorInfo.message,
      });
      localStorage.removeItem(TOKEN_NAME);
      window.location = "/";
    } else if (errorInfo instanceof InternalServerError) {
      showToastAlert({
        type: "error",
        message: errorInfo.message,
      });
    } else if (errorInfo instanceof PartialContentError) {
      setModalData({
        icon: "",
        showModal: true,
        titleText: "",
        messageText: errorInfo.msg,
        secondaryBtnClassName: "btn-primary",
        secondaryBtnText: "Done",
        type: "error",
      });
      showToastAlert({
        type: "error",
        message: errorInfo.message,
      });
    } else if (errorInfo instanceof DataInsufficent) {
      setModalData({
        icon: "",
        showModal: true,
        titleText: "",
        messageText: errorInfo.msg,
        secondaryBtnClassName: "btn-primary",
        secondaryBtnText: "Done",
        type: "error",
      });

      showToastAlert({
        type: "error",
        message: errorInfo.messages[0],
      });
    } else if (isCritical) {
      setModalData({
        icon: "",
        showModal: true,
        titleText: "",
        messageText: errorInfo.msg,
        secondaryBtnClassName: "btn-primary",
        secondaryBtnText: "Done",
        type: "error",
      });
      showToastAlert({
        type: "error",
        message: errorInfo.message,
      });
    } else if (!isOnline) {
      showToastAlert({
        type: "error",
        message: " Please check internet connection",
      });
    } else {
      showToastAlert({
        type: "error",
        message: errorInfo.message,
      });
    }
  };

  const AppLogin = async (appOtpverify = false, data) => {
    if (!appOtpverify) {
      let apiResponse = await HttpPost(LOGIN, data);
      if (apiResponse.status) {
        const accessObj = apiResponse.features;
        let accesses = [];
        if (accessObj) {
          accessObj.forEach((accessSet) =>
            accessSet.apis.forEach((api) => accesses.push(api))
          );
        }
      }
      return apiResponse;
    } else {
      let apiOtpResponse = await HttpPost(VERIFYOTP, data);
      if (apiOtpResponse.status) {
        setUserInfo({
          id: apiOtpResponse.id,
          mobile: apiOtpResponse.mobile,
          onboarding_step: apiOtpResponse.step,
          accessToken: apiOtpResponse["access_token"],
        });
        generateLocalToken(apiOtpResponse);
        await SetHttpContext(null, apiOtpResponse["access_token"]);
      }
      return apiOtpResponse;
    }
  };

  /*  const getAccessToken = async () => {
    const getToken = localStorage.getItem("scorer_app");
    await SetHttpContext(getDecrypted(getToken));
  }; */
  const AppLogout = async (callLogoutApi = true) => {
    try {
      /*       await getAccessToken(api_type);
       */ if (callLogoutApi) {
        // const result =  await HttpPost(LOGOUT, data);

        // if (result.status === true) {
        // showToastAlert({ type: "success", message: "Logout Successfully" });
        // window.location.href("/login");
        // }
        // showToastAlert({ type: "success", message: "Logout Successfully" });
        window.location.href("/login");
      }
    } catch (e) {
      // showToastAlert({ type: 'error', message: e.message })
    } finally {
      if (callLogoutApi) {
        setIsLogout(true);
      }
      localStorage.clear();
      setUserInfo(null);
      HttpHelper.SetContext(null);
    }
  };

  const getTitle = (id, features) => {
    let result;

    features.forEach((item) => {
      if (item.id === id) {
        result = { title: item.name, subTitle: item.description };
      } else if (item.submenu?.length && !result) {
        const result1 = getTitle(id, item.submenu);
        if (result1) result = result1;
      }
    });
    return result;
  };

  const makeFirstLetterUppercase = (word) => {
    return word?.charAt(0).toUpperCase() + word?.slice(1);
  };

  const showToastAlert = (messageInfo) => {
    if (messageInfo.type === "error") {
      toast.error(
        <div
          style={{ padding: "8px 10px", width: "max-content" }}
          // className={`${hasToken && "hasToken"}`}
        >
          {messageInfo.message}
        </div>,
        {
          position: "bottom-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          icon: false,
          closeButton: false,
          className: "toast-msg toast-error",
        }
      );
    } else if (messageInfo.type === "info") {
      toast.info(messageInfo.message, {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme: "light",
        icon: false,
        className: "toast-msg toast-info",
      });
    } else {
      toast.success(messageInfo.message, {
        position: messageInfo.position,
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        icon: false,
        closeButton: false,
        className: "toast-msg toast-success",
      });
    }
  };

  const resetModuleState = () => {
    setModuleState({
      moduleName: "",
      searchValue: "",
      currentpage: PAGINATION_DETAILS.CURRENT_PAGE,
      pageSize: PAGINATION_DETAILS.PAGE_SIZE,
      fromDate: "",
      toDate: "",
    });
  };

  const contextValue = {
    getTitle,
    isLogout,
    AppLogin,
    AppLogout,
    HttpGet,
    HttpPost,
    HttpPut,
    HttpDelete,
    userInfo,
    setUserInfo,
    notification_token,
    setNotification_token,
    deviceID,
    setDeviceID,
    makeFirstLetterUppercase,
    showToastAlert,
    baseURL,
    refreshPage,
    setRefreshPage,
    moduleState,
    setModuleState,
    resetModuleState,
    isOnline,
    setContribution,
    contribution,
    appReferrer,
    setAppReferrer,
    loading,
    selectedCompany,
    setSelectedCompany,
    setCompanyList,
    companyList,
    filterCount,
    setFilterCount,
    setUpdateCompanyList,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
