/** @format */

import React from "react";

const ProductSku = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24.009"
      viewBox="0 0 24 24.009"
    >
      <path
        id="product"
        d="M3.994.528A3.488,3.488,0,0,0,.529,4V21.064a3.488,3.488,0,0,0,3.464,3.474H21.065a3.488,3.488,0,0,0,3.464-3.474V4A3.488,3.488,0,0,0,21.065.528Zm0,2.4H8.928v8.406a1.2,1.2,0,0,0,2.046.841l1.561-1.549,1.549,1.549a1.2,1.2,0,0,0,2.046-.841V2.931h4.934A1.05,1.05,0,0,1,22.129,4V21.064a1.052,1.052,0,0,1-1.064,1.074H3.994a1.05,1.05,0,0,1-1.062-1.074V4A1.048,1.048,0,0,1,3.994,2.931Zm7.334,0h2.4V8.439l-.354-.354a1.2,1.2,0,0,0-1.692,0l-.356.354Z"
        transform="translate(-0.529 -0.528)"
        fill={color}
      />
    </svg>
  );
};

export default ProductSku;
