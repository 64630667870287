/** @format */

import dateFormat from "dateformat";
import { TOKEN_NAME } from "./constant";
export const FormatDate = (text, format) => {
  if (text) {
    return dateFormat(text, format);
  }
};
export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}
export function dataURLtoFile(dataurl, filename) {
  // only works for dataURL, not for other types of url.
  const arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function urltoFile(url, filename, mimeType) {
  // works for any type of url, (http url, dataURL, blobURL, etc...)
  // return a promise that resolves with a File instance
  if (url.startsWith("data:")) {
    var arr = url.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var file = new File([u8arr], filename, { type: mime || mimeType });
    return Promise.resolve(file);
  }
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }));
}

export const DownloadFileBlob = (
  fileData,
  fileName,
  fileExtensions = "xlsx"
) => {
  var a = document.createElement("a"); //Create <a>
  a.href = "data:application/;base64," + fileData; //Base64 Goes here
  a.download = `${fileName}.${fileExtensions}`; //File name Here
  a.click(); //Downloaded file
};

export const capFirstLetter = (str) => {
  return (str + "").replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
};

export const generateLocalToken = (apiOtpResponse, step = "") => {
  if (!apiOtpResponse) {
    apiOtpResponse = JSON.parse(getDecrypted(localStorage.getItem(TOKEN_NAME)));
  }
  const encodedData = getEncrypted(
    JSON.stringify({
      id: apiOtpResponse.id,
      mobile: apiOtpResponse.mobile,
      onboarding_step: apiOtpResponse.step,
      kycStep: step ? step : apiOtpResponse.kycStep,
      accessToken: apiOtpResponse.accessToken
        ? apiOtpResponse.accessToken
        : apiOtpResponse["access_token"],
    })
  );
  localStorage.setItem(TOKEN_NAME, encodedData);
};

export const getEncrypted = (str) => {
  return btoa(str);
};

export const getDecrypted = (str) => {
  return atob(str);
};
export function sorting(data, key, order) {
  const filterData = [...data];

  filterData.sort(function (a, b) {
    const key1 = key ? a[key] : "";
    const key2 = key ? b[key] : "";

    if (key1 === null || key1 === undefined) {
      return order === "ASC" ? 1 : -1;
    }

    if (key2 === null || key2 === undefined) {
      return order === "ASC" ? -1 : 1;
    }

    if (typeof key1 === "string" && typeof key2 === "string") {
      const comparison = key1.localeCompare(key2);
      return order === "ASC" ? comparison : -comparison;
    }

    if (key1 < key2) {
      return order === "ASC" ? -1 : 1;
    }

    if (key1 > key2) {
      return order === "ASC" ? 1 : -1;
    }

    return 0;
  });

  return filterData;
}
export function searchedData(data, searchText, key) {
  const value = searchText.trim();
  if (value.length === 0) return data;
  const reg = new RegExp(value, "gi");
  const filterData = data.filter((item) => {
    const itemValue = item[key];
    return itemValue.search(reg) !== -1;
  });
  return filterData;
}
export function filterData(data, values, key) {
  const filterData = [];

  if (values.length === 0) return data;

  const smallValues = values.map((item) => {
    return item ? item.toLowerCase() : "";
  });

  for (const item of data) {
    const itemKey = item[key] ? item[key].toLowerCase() : "";
    if (smallValues.includes(itemKey)) filterData.push(item);
  }
  return filterData;
}
