/** @format */

import React, { useContext } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./styles.module.css";
import { ControllerInput } from "../../components/common/ControllerInput";
import CustomButton from "../../components/common/CustomButton";
import { AppContext } from "../../helpers/app-context";
import { useLocation, useNavigate } from "react-router-dom";
import { VERIFY_OTP } from "../../helpers/api-endpoints";
const schema = yup.object().shape({
  otp: yup
    .string()
    .trim()
    .required("This field is required")
    .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, "Please enter a valid OTP"),
});
const VerifyOTP = () => {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const onSubmit = async (data) => {
    let formData = new FormData();
    formData.append("email", state);
    formData.append("otp", data.otp);
    const result = await ctx.HttpPost(VERIFY_OTP, formData);
    if (result.status) {
      navigate("/reset-password", { state: state });
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginWrapper}>
        <div className={styles.formTitle}>Magic Ads</div>
        <div className={styles.formSubTitle}>OTP Verification</div>
        <div className={styles.formDesc}>
          Please enter verification code sent to your registered email id
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControllerInput
            control={control}
            name="otp"
            id="otp"
            type="number"
            placeholder="Please enter 6 digit OTP"
            labelWrapperClass={styles.labelWrapperClass}
            inputWrapperClass={styles.inputWrapperClass}
            wrapperClass={styles.wrapperClass}
          />

          <CustomButton title="SUBMIT" />
        </form>
      </div>
    </div>
  );
};

export default VerifyOTP;
