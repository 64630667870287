/** @format */

import React from "react";

const PayoutIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.402"
      height="22.336"
      viewBox="0 0 24.402 22.336"
    >
      <g id="Layer_1" data-name="Layer 1" transform="translate(0 0.007)">
        <g
          id="Group_137891"
          data-name="Group 137891"
          transform="translate(0 -0.007)"
        >
          <path
            id="Path_233743"
            data-name="Path 233743"
            d="M0,521.4a1.615,1.615,0,0,0,.024-.392c.007-.154,0-.31.011-.464A.466.466,0,0,1,.49,520.1c.543-.026,1.086.006,1.629,0,.1,0,.13.08.192.124a.475.475,0,0,1,.185.445c-.007,2.1-.006,4.205-.007,6.307,0,.106-.013.212-.007.318a.568.568,0,0,1-.235.465.164.164,0,0,1-.122.038c-.526,0-1.051,0-1.577-.008a1.121,1.121,0,0,1-.235-.033.33.33,0,0,1-.267-.319c-.035-.392-.013-.786-.028-1.178,0-.047.016-.1-.018-.14V521.4Z"
            transform="translate(0 -506.284)"
            fill={color}
          />
          <path
            id="Path_233744"
            data-name="Path 233744"
            d="M153.714,587.778c.8.1,1.574.186,2.346.3a1.265,1.265,0,0,0,.726-.124c1.458-.639,2.892-1.332,4.34-1.993a11.229,11.229,0,0,1,1.2-.511.945.945,0,0,1,1.069.354,1.012,1.012,0,0,1,.07,1.132,1.091,1.091,0,0,1-.484.417c-.373.181-.746.361-1.124.532-.1.047-.149.1-.125.216a1.106,1.106,0,0,1-.036.537.912.912,0,0,1-.514.617c-.97.434-1.949.846-2.93,1.256-.875.365-1.756.714-2.631,1.077a1.944,1.944,0,0,1-1.489,0c-.829-.3-1.632-.669-2.43-1.044a20.519,20.519,0,0,0-2.505-1.067c-.069-.022-.1-.049-.1-.127,0-1.427-.029-2.854-.015-4.281,0-.13.033-.157.164-.138.746.11,1.494.212,2.242.312.586.078,1.172.157,1.759.223a5.092,5.092,0,0,0,1.055-.069c.817-.079,1.629-.2,2.447-.266.123-.01.247-.006.371,0a.855.855,0,0,1,.642.373,1.242,1.242,0,0,1-.186,1.65,1.365,1.365,0,0,1-.676.2c-.63.084-1.261.159-1.892.237-.38.047-.761.091-1.142.136a.3.3,0,0,0-.156.05Z"
            transform="translate(-145.124 -569.404)"
            fill={color}
          />
          <path
            id="Path_233745"
            data-name="Path 233745"
            d="M355.144,60.143c1.038,0,2.076,0,3.114,0,.108,0,.1.056.1.124q0,1.3,0,2.6c0,.46-.005.919,0,1.378,0,.127-.038.149-.155.149-.738-.005-1.476,0-2.213,0a.3.3,0,0,0-.195.062c-.536.418-1.082.823-1.607,1.255a9.04,9.04,0,0,0-.992,1.065,1.247,1.247,0,0,1-.9.474,1.072,1.072,0,0,1-1-1.542,5.971,5.971,0,0,1,1-1.472c.262-.248.55-.47.83-.7.091-.075.074-.133,0-.2s-.1-.015-.151.018a4.77,4.77,0,0,0-1.5,1.541c-.074.135-.176.255-.248.391a.218.218,0,0,1-.308.113c-.6-.194-1.212-.375-1.818-.563-.64-.2-1.278-.4-1.919-.6-.084-.026-.1-.057-.1-.147a3.292,3.292,0,0,1,.48-1.455,2.646,2.646,0,0,1,1.02-.942,30.649,30.649,0,0,1,3.156-1.427,2.85,2.85,0,0,1,1-.089c.8-.009,1.607,0,2.411,0v-.019Z"
            transform="translate(-337.865 -58.543)"
            fill={color}
          />
          <path
            id="Path_233746"
            data-name="Path 233746"
            d="M212.57,208.539c.024-.1.051-.224.086-.346.329-1.181.712-2.345,1.09-3.51a.481.481,0,0,1,.663-.357c.806.224,1.6.482,2.4.729,1.006.312,2.01.63,3.016.939a.163.163,0,0,1,.138.2c-.008.141.015.284,0,.423a1.291,1.291,0,0,0,1.1,1.222,1.6,1.6,0,0,0,1.58-.7c.152-.194.149-.194.387-.129a2.644,2.644,0,0,1,.546.2.413.413,0,0,1,.238.535c-.353,1.223-.73,2.439-1.123,3.65a.89.89,0,0,1-.16.3.383.383,0,0,1-.444.139c-1.224-.379-2.451-.749-3.676-1.128q-1.876-.58-3.747-1.172c-.55-.175-1.1-.338-1.639-.555a1.618,1.618,0,0,1-.319-.16A.316.316,0,0,1,212.57,208.539Zm7.39-.547a1.474,1.474,0,0,0-.216-.858,1.808,1.808,0,0,0-2.847-.3,1.655,1.655,0,0,0-.464,1.146,1.776,1.776,0,0,0,.268,1.081,1.6,1.6,0,0,0,1.381.771,2.073,2.073,0,0,0,.744-.073,1.753,1.753,0,0,0,1.134-1.764Zm-5.9-.965a.835.835,0,1,0,1.671-.016.835.835,0,0,0-1.671.016Zm8.277,2.085a.813.813,0,0,0-.82-.839.836.836,0,0,0-.848.819.864.864,0,0,0,.851.855.838.838,0,0,0,.818-.836Z"
            transform="translate(-206.927 -198.871)"
            fill={color}
          />
          <path
            id="Path_233747"
            data-name="Path 233747"
            d="M826.553,3.843c0,1.122,0,2.244,0,3.367a.387.387,0,0,1-.113.276.573.573,0,0,1-.5.207c-.459-.018-.919-.005-1.378-.005a.461.461,0,0,1-.474-.362,1.661,1.661,0,0,1-.046-.473c-.006-1.74-.013-3.481.011-5.221.005-.406-.007-.812.035-1.218A.426.426,0,0,1,824.5,0c.574-.008,1.149,0,1.723,0,.021,0,.051,0,.062.014.114.147.28.245.278.481-.013,1.118-.006,2.235-.006,3.353Z"
            transform="translate(-802.157 0.007)"
            fill={color}
          />
          <path
            id="Path_233748"
            data-name="Path 233748"
            d="M105.792,553.283c0-.981,0-1.962,0-2.943,0-.107.03-.139.132-.129s.186,0,.278,0a.39.39,0,0,1,.406.413c0,1.323,0,2.646,0,3.969a13.114,13.114,0,0,1-.042,1.389.788.788,0,0,1-.05.218.206.206,0,0,1-.194.135c-.132.008-.265.009-.4.025-.106.013-.132-.021-.132-.126,0-.985,0-1.971,0-2.956Z"
            transform="translate(-102.981 -535.604)"
            fill={color}
          />
          <path
            id="Path_233749"
            data-name="Path 233749"
            d="M783.43,32.9c0,.972,0,1.944,0,2.917,0,.126-.038.159-.155.149a1.57,1.57,0,0,1-.429-.067.281.281,0,0,1-.212-.294c0-.605-.012-1.211-.013-1.816q0-1.809,0-3.619a.345.345,0,0,1,.309-.347c.141-.011.283,0,.424-.008.07,0,.078.032.077.089,0,.186,0,.371,0,.557V32.9Z"
            transform="translate(-761.844 -29.023)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default PayoutIcon;
