/** @format */

import React, { useContext, useEffect, useState } from "react";
import AppLayout from "../../../components/layout";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import ViewMoreTable from "../../../components/common/ViewMoreTable";
import { AppContext } from "../../../helpers/app-context";
import { useLocation } from "react-router";
import styles from "./CreditHistory.module.css";
import { Box } from "@mui/material";
import { CREDIT_HISTORY } from "../../../helpers/api-endpoints";
const CreditHistory = () => {
  const [params, setParams] = useState({});
  const ctx = useContext(AppContext);
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const getData = async () => {
    setData([]);
    let formData = new FormData();
    formData.append("outlet_id", state?.outlet_id);
    const result = await ctx.HttpPost(CREDIT_HISTORY, formData);
    if (result.status) {
      setData(result.data);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const tableHeader = [
    {
      key: "transaction_id",
      label: "Transaction Id",
      cardColumn: styles.transactioncardColumn,
      headerClasses: styles.transactionheaderClasses,
    },
    {
      key: "date_day",
      label: "Redeemed Date & Time",
      headerClasses: styles.redeemedheaderClasses,
      cardColumn: styles.redeemedcardColumn,
    },
    {
      key: "amount",
      label: "Amount",
      headerClasses: styles.amountheaderClasses,
      render: (column) => {
        return <Box color={"#6C5CFF"}>₹{column.amount}</Box>;
      },
    },

    {
      label: "Status",
      headerClasses: styles.statusheaderClasses,
      render: (column) => {
        return (
          <Box color={column.status === "Success" ? "#35BF0D" : "#E41E29"}>
            {column.status}
          </Box>
        );
      },
    },
    {
      label: "",
      cardColumn: styles.labelcardColumn,
    },
  ];
  const viewmoreTableHeader = [
    // {
    //   label: "Customer Name",
    //   render: (column) => {
    //     return <Box color={"#6C5CFF"}>{column.customer_name}</Box>;
    //   },
    // },
    {
      key: "date",
      label: "Date & Time",
    },

    {
      key: "coupon_code",
      label: "Coupon Id",
    },
    {
      label: "Product Name",
      key: "product_name",
      headerClasses: styles.productheaderClasses,
      cardColumn: styles.productcardColumn,
    },
    {
      label: "Price",
      key: "MRP",
      render: (column) => {
        return <Box color={"#6C5CFF"}>₹ {column.MRP}</Box>;
      },
    },
  ];
  return (
    <AppLayout title="Credit history" backButton={true}>
      <div className={styles.mainContainer}>
        <div className={styles.createCompanyBtn}>
          {state.outletCoupon ? (
            <CustomBreadcrumbs
              item1="Outlets"
              item2={ctx?.selectedCompany?.name}
              item3={state?.outlet_name}
              item4={state?.campaign_name}
              item5="Credit history"
              lastTypographyColor="#0000004D"
            />
          ) : (
            <CustomBreadcrumbs
              item1="Company"
              item2={ctx?.selectedCompany?.name}
              item3="Sles person"
              item4="Outlet info"
              item5="Credit history"
              lastTypographyColor="#0000004D"
            />
          )}
        </div>

        <ViewMoreTable
          viewmoreTableHeader={viewmoreTableHeader}
          tableParams={{ params, setParams }}
          tableHeader={tableHeader}
          tableData={data}
          innerTable={true}
        />
      </div>
    </AppLayout>
  );
};

export default CreditHistory;
