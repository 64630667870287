/** @format */

import React from "react";

const UnCheckedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.988"
      height="20"
      viewBox="0 0 19.988 20"
    >
      <g id="check" transform="translate(-2 -2)">
        <path
          id="Path_233668"
          data-name="Path 233668"
          d="M13.2,10.2l-1.7,2.5-.5-.5a.99.99,0,0,0-1.4,1.4L11,15a.908.908,0,0,0,.7.3h.1a.842.842,0,0,0,.7-.4l2.4-3.6a1.012,1.012,0,1,0-1.7-1.1Z"
          fill="#0dbf48"
        />
        <path
          id="Path_233669"
          data-name="Path 233669"
          d="M20.8,9.2a1.974,1.974,0,0,1-.6-1.4,4.012,4.012,0,0,0-4-4,1.974,1.974,0,0,1-1.4-.6,3.984,3.984,0,0,0-5.7,0,1.612,1.612,0,0,1-1.3.6,4.012,4.012,0,0,0-4,4,1.974,1.974,0,0,1-.6,1.4,3.984,3.984,0,0,0,0,5.7,1.974,1.974,0,0,1,.6,1.4,4.012,4.012,0,0,0,4,4,1.974,1.974,0,0,1,1.4.6A4.3,4.3,0,0,0,12,22a3.784,3.784,0,0,0,2.8-1.2,1.974,1.974,0,0,1,1.4-.6,4.012,4.012,0,0,0,4-4,2.389,2.389,0,0,1,.5-1.4,3.788,3.788,0,0,0,.1-5.6Zm-1.4,4.2a3.949,3.949,0,0,0-1.2,2.8,2.006,2.006,0,0,1-2,2,3.631,3.631,0,0,0-2.8,1.2,1.933,1.933,0,0,1-2.8,0,3.949,3.949,0,0,0-2.8-1.2,2.006,2.006,0,0,1-2-2,3.631,3.631,0,0,0-1.2-2.8,1.933,1.933,0,0,1,0-2.8A3.949,3.949,0,0,0,5.8,7.8a2.006,2.006,0,0,1,2-2,3.631,3.631,0,0,0,2.8-1.2,1.933,1.933,0,0,1,2.8,0,3.949,3.949,0,0,0,2.8,1.2,2.006,2.006,0,0,1,2,2,3.631,3.631,0,0,0,1.2,2.8,1.933,1.933,0,0,1,0,2.8Z"
          fill="#0dbf48"
        />
      </g>
    </svg>
  );
};

export default UnCheckedIcon;
