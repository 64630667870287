/** @format */

import { Navigate, Outlet } from "react-router-dom";
import { TOKEN_NAME } from "../../helpers/constant";

const PrivateRoutes = () => {
  const getTokenFromLs = localStorage.getItem(TOKEN_NAME);

  return getTokenFromLs ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
