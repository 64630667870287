/** @format */

import { Navigate, Outlet } from "react-router-dom";
import { TOKEN_NAME } from "../../helpers/constant";

const PublicRoutes = () => {
  const getTokenFromLs = localStorage.getItem(TOKEN_NAME);

  return getTokenFromLs ? <Navigate to="/campaign-list" /> : <Outlet />;
};

export default PublicRoutes;
