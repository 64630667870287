/** @format */

import React, { useContext, useEffect, useState } from "react";
import styles from "./RecordRequest.module.css";
import AppLayout from "../../../components/layout";
import { Box } from "@mui/material";
import Table from "../../../components/common/Table";
import CustomButton from "../../../components/common/CustomButton";
import {
  CAMPAIGN_DETAILS,
  SEND_RECORD_REQUEST,
} from "../../../helpers/api-endpoints";
import { AppContext } from "../../../helpers/app-context";
import { useLocation } from "react-router-dom";
import ToastIcon from "../../../assets/icons/ToastIcon";
import { toast } from "react-toastify";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
const RecordRequest = () => {
  const ctx = useContext(AppContext);
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const getData = async () => {
    setData([]);
    let formData = new FormData();
    formData.append("campaign_id", state.campaign_id);
    const result = await ctx.HttpPost(CAMPAIGN_DETAILS, formData);
    if (result.status) {
      setData(result.data.products);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handleCloseToast = () => {
    toast.dismiss();
  };
  const handleRecord = async (product_id, variant_id) => {
    let formData = new FormData();
    formData.append("outlet_id", state?.outlet_id);
    formData.append("product_id", product_id);
    formData.append("variant_id", variant_id);
    const result = await ctx.HttpPost(SEND_RECORD_REQUEST, formData);
    if (result.status) {
      ctx.showToastAlert({
        type: "success",
        position: "bottom-left",
        message: (
          <div className="toastContainer">
            <div className="toastIcon">
              <ToastIcon />
            </div>
            <div className="toastContent">Record Request Sent Successfully</div>
            <div className="toastClose" onClick={handleCloseToast}>
              CLOSE
            </div>
          </div>
        ),
      });
    }
  };
  const [params, setParams] = useState({});
  const tableHeader = [
    {
      key: "product_name",
      label: "Product Name",
      headerClasses: styles.headerClasses,
      cardColumn: styles.cardColumn,
      render: (column) => {
        return (
          <Box className={styles.avatarWrapper}>
            <img
              src={column?.images[0]?.product_image}
              alt=""
              width={"50px"}
              height={"40px"}
            />
            <div>{column.product_name}</div>
          </Box>
        );
      },
    },
    {
      key: "description",
      label: "Product SKU",
    },
    {
      key: "sku_code",
      label: "SKU Code",
    },
    {
      label: "MRP",
      headerClasses: styles.mrpHeaderClasses,
      render: (column) => {
        return <div>₹ {column.mrp}</div>;
      },
    },
    {
      label: "",
      render: (column) => {
        return (
          <CustomButton
            title="RECORD"
            style={{height:"29px",width:"80px"}}
            wrapperClass={styles.wrapperClassBtn}
            onClick={() => handleRecord(column.product_id, column.variant_id)}
          />
        );
      },
    },
  ];
  const searchInput = {
    key: "product_name",
    placeholder: "Search Product",
  };
  return (
    <AppLayout title="Record Request" backButton={true}>
      <div className={styles.mainContainer}>
        <div className={styles.breadcrumbsContainer}>
          <CustomBreadcrumbs
            item1="Outlets"
            item2={ctx?.selectedCompany?.name}
            item3={state?.outlet_name}
            item4={state?.campaign_name}
            lastTypographyColor="#0000004D"
          />
        </div>

        <Table
          tableParams={{ params, setParams }}
          tableHeader={tableHeader}
          tableData={data}
          tableSearchInput={searchInput}
        />
      </div>
    </AppLayout>
  );
};

export default RecordRequest;
