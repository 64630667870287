/** @format */
export const LOGOUT = "api/v1/user-logout";
export const LOGIN = "api/v1/login";
export const VERIFYOTP = "";
export const COMPANY_LIST = "api/v1/admin/get-companies";
export const CREATE_COMPANY = "api/v1/admin/add-company";
export const EDIT_COMPANY = "api/v1/admin/edit-company";
export const DELETE_COMPANY = "api/v1/admin/delete-company";
export const CAMPAIGN_LIST = "api/v1/admin/campaign-list-by-company";
export const DELETE_CAMPAIGN = "api/v1/admin/delete-campaign";
export const SALES_PERSONS_LIST = "api/v1/admin/get-salesperson";
export const ADD_SALES_PERSON = "api/v1/admin/add-sales-person";
export const PRODUCT_LIST = "api/v1/admin/product-list";
export const DELETE_PRODUCTS = "api/v1/admin/delete-product";
export const ADD_PRODUCTS = "api/v1/admin/add-product";
export const VIEW_PRODUCTS = "api/v1/admin/view-product";
export const EDIT_PRODUCTS = "api/v1/admin/edit-product";
export const CREATE_OUTLET = "api/v1/admin/add-outlet";
export const OUTLET_LIST = "api/v1/admin/get-outlet-list";
export const CAMPAIGN_LIST_BY_OUTLET = "api/v1/admin/campaign-list";
export const CAMPAIGN_DETAILS = "api/v1/admin/campaign-products";
export const SEND_RECORD_REQUEST = "api/v1/admin/record-request";
export const OUTLET_INFO_BY_SALESPERSON = "api/v1/admin/salespersons-outlets";
export const OUTLET_ORDER_REQUEST = "api/v1/admin/get-requests";
export const TOTAL_CAMPAIGN_BY_OUTLET = "api/v1/admin/campaign-list";
export const ACTIVE_COUPON_BY_CAMPAIGN = "api/v1/admin/get-coupons-data";
export const FORGOT_PASSWORD = "api/v1/admin/send-otp";
export const VERIFY_OTP = "api/v1/admin/verify-otp";
export const DELETE_PRODUCTS_FROM_CAMPAIGN =
  "api/v1/admin/remove-campaign-product";
export const UPLOAD_EXCEL = "api/v1/admin/add-product-excel";
export const DOWNLOAD_EXCEL = "api/v1/admin/download-product-excel";
export const RESET_PASSWORD = "api/v1/admin/reset-password";
export const ADD_PRODUCTS_TO_CAMPAIGN = "api/v1/admin/add-campaign-product";
export const OUTLET_ACTIVE_INACTIVE = "api/v1/admin/set-outlet-status";
export const CREATE_CAMPAIGN = "api/v1/admin/add-campaign";
export const EDIT_CAMPAIGN = "api/v1/admin/edit-campaign";
export const UPDATE_COST_PER_LEAD = "api/v1/admin/update-cost-per-lead";
export const CREDIT_HISTORY = "api/v1/admin/credit-history";
export const GET_FAQ_LISTS = "api/v1/admin/get-faqs";
export const DELETE_FAQS = "api/v1/admin/delete-faqs";
export const GET_FAQ_ISSUE = "api/v1/admin/get-faq-issues";
export const CREATE_FAQ = "api/v1/admin/create-faq";
export const UPDATE_FAQ = "api/v1/admin/update-faq";
export const GET_SCRATCH_CODE = "api/v1/admin/get-scratch-code-list";
export const CREATE_SCRATCH_CODE_LIST = "api/v1/admin/create-scratch-code";
export const PRODUCT_WARRANTY_LIST = "api/v1/admin/products-warranty-list";
export const DOWNLOAD_WARRANTY_EXCEL = "api/v1/admin/download-warranty-list";
export const TICKET_LIST = "api/v1/admin/tickets-list";
export const CLOSE_TICKET = "api/v1/admin/close-ticket";
export const SENT_CHAT_MESSAGE = "api/v1/admin/ticket-issue-chat";
export const DOWNLOAD_SCRATCH_CODE_LIST = "api/v1/admin/download-serial";
export const SHOW_TICKET_CHAT_DETAILS = "api/v1/admin/ticket-chat-details";
export const PAYOUT_LIST = "api/v1/admin/payout";
