/** @format */

import React, { useEffect, useRef } from "react";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBase,
} from "@mui/material";
import { Controller } from "react-hook-form";
import styles from "./Controller.module.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const ControllerInput = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      if (!showPassword) {
        const value = inputRef.current.querySelector("input").value;
        inputRef.current
          .querySelector("input")
          .setSelectionRange(value.length, value.length);
      }
    }
  }, [showPassword]);
  return (
    <div className={`${styles["customInput"]} ${props.wrapperClass}`}>
      <Controller
        name={props.name}
        control={props.control}
        render={({
          field: { onChange, value, name },
          fieldState: { invalid, error },
        }) => {
          return (
            <FormControl fullWidth variant="standard" id="formControl">
              {props?.label && (
                <label
                  className={`${styles["customLabel"]} ${props.labelWrapperClass}`}
                >
                  {props.label}
                </label>
              )}
              {props.name !== "password" ? (
                <div className={styles.inputContainer}>
                  <input
                    {...props}
                    // type="number"
                    className={`${props.inputWrapperClass} ${
                      styles.noSpinButtons
                    } ${styles.inputField} ${
                      invalid && styles.inputErrorField
                    }`}
                    error={invalid}
                    name={name}
                    onChange={onChange}
                    value={value}
                  />
                </div>
              ) : (
                <div className={styles.inputContainer} ref={inputRef}>
                  <InputBase
                    {...props}
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    error={invalid}
                    name={name}
                    onChange={onChange}
                    value={value}
                    classes={{
                      root: styles.muiInputBaseRoot,
                      focused: styles.muiInputBaseFocused,
                      error: styles.muiInputBaseError,
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
              )}

              {invalid && (
                <FormHelperText
                  id="component-error-text"
                  className={styles.errorMessage}
                >
                  {error?.message}
                </FormHelperText>
              )}
            </FormControl>
          );
        }}
      />
    </div>
  );
};
