/** @format */

import React from "react";

const RefreshIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76.001"
      height="14"
      viewBox="0 0 76.001 14"
    >
      <g
        id="Group_137903"
        data-name="Group 137903"
        transform="translate(-320.999 -44.399)"
      >
        <g
          id="Layer_1"
          data-name="Layer 1"
          transform="translate(320.996 44.395)"
        >
          <g
            id="Group_137901"
            data-name="Group 137901"
            transform="translate(0.003 0.005)"
          >
            <path
              id="Path_233757"
              data-name="Path 233757"
              d="M263.615,3.882c-.007.016-.021.017-.034.017h-3.732c-.154,0-.132,0-.132-.132q0-1.837,0-3.673c0-.031-.018-.076.016-.089s.048.031.069.051L261.046,1.3c.012.012.026.024.037.037a.053.053,0,0,0,.08.008c.091-.06.186-.114.282-.167a6.611,6.611,0,0,1,1.834-.692,6.756,6.756,0,0,1,.835-.126c.106-.009.213-.017.321-.018.153,0,.305,0,.458,0a6.431,6.431,0,0,1,1,.108,6.558,6.558,0,0,1,1.759.591,6.648,6.648,0,0,1,1.266.827,6.449,6.449,0,0,1,.576.536,6.825,6.825,0,0,1,1.04,1.427,6.61,6.61,0,0,1,.8,2.855c.007.153.012.306.008.459a6.723,6.723,0,0,1-.154,1.287,6.615,6.615,0,0,1-.368,1.154q-.035.083-.11.04l-1-.584-.023-.013c-.058-.035-.058-.035-.034-.1a5.191,5.191,0,0,0,.265-.912,5.107,5.107,0,0,0,.091-.761c0-.109.011-.218.008-.328a5.44,5.44,0,0,0-.235-1.491,5.309,5.309,0,0,0-.673-1.409A5.394,5.394,0,0,0,267,2.2a5.22,5.22,0,0,0-1.451-.462,6.445,6.445,0,0,0-.7-.069,4.617,4.617,0,0,0-.524.008,5.122,5.122,0,0,0-1.134.2,5.437,5.437,0,0,0-1.017.417l-.017.009c-.069.037-.07.038-.013.094l1.424,1.424c.018.018.042.032.047.059Z"
              transform="translate(-258.009 -0.005)"
              fill="#6c5cff"
            />
            <path
              id="Path_233758"
              data-name="Path 233758"
              d="M7.734,681.2a.1.1,0,0,1,.067-.012h3.771c.048,0,.055.009.058.059,0,.017,0,.035,0,.052q0,1.843,0,3.686c0,.031.017.076-.017.089s-.048-.031-.069-.052L10.3,683.773c-.08-.08-.066-.071-.151-.019a6.637,6.637,0,0,1-2.314.884,6.406,6.406,0,0,1-.7.086c-.148.01-.3.019-.445.018a6.666,6.666,0,0,1-2.743-.583,6.592,6.592,0,0,1-1.4-.852,6.7,6.7,0,0,1-1.731-2.051,6.6,6.6,0,0,1-.8-2.742,7.211,7.211,0,0,1-.007-.78,6.331,6.331,0,0,1,.149-1.1,6.6,6.6,0,0,1,.328-1.05l.007-.018c.028-.067.031-.068.093-.031l.9.542c.049.029.1.058.146.088s.046.03.029.079c-.032.093-.066.185-.094.28a5.508,5.508,0,0,0-.208,1.02,5.162,5.162,0,0,0-.021.805,5.261,5.261,0,0,0,.45,1.89,5.363,5.363,0,0,0,1.809,2.2,5.3,5.3,0,0,0,1.443.721,5.54,5.54,0,0,0,1.678.256,5.294,5.294,0,0,0,2.026-.429,4.873,4.873,0,0,0,.454-.22l.029-.016c.021-.012.02-.026,0-.042l-.041-.042L7.785,681.26a.152.152,0,0,1-.05-.064Z"
              transform="translate(-0.003 -671.074)"
              fill="#6c5cff"
            />
          </g>
        </g>
        <text
          id="Refresh_Chat"
          data-name="Refresh Chat"
          transform="translate(397 55.399)"
          fill="#6c5cff"
          font-size="10"
          font-family="Roboto-Medium, Roboto"
          font-weight="500"
          letter-spacing="0.01em"
        >
          <tspan x="-59" y="0">
            Refresh Chat
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default RefreshIcon;
