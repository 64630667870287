/** @format */

import React from "react";

const ActiveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <circle
        id="Ellipse_43865"
        data-name="Ellipse 43865"
        cx="7"
        cy="7"
        r="7"
        fill="#1ec82f"
      />
    </svg>
  );
};

export default ActiveIcon;
