/** @format */

import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import Select, { components } from "react-select";
import styles from "./SelectTag.module.css";
import { Box, Checkbox } from "@mui/material";

const { Option } = components;

const CheckboxOption = (props) => {
  const { isSelected, ...restProps } = props;
  return (
    <Option sx={{ maxHeight: 200, overflowY: "auto" }} {...restProps}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <img src={props?.data?.image} height={"31px"} width={"31px"} alt="" />
          <div className={styles.propsLabel}>{props.label}</div>
        </div>
        <Checkbox type="checkbox" onChange={() => null} checked={isSelected} />
      </Box>
    </Option>
  );
};
// const Placeholder = (props) => {
//   const { isFocused, ...restProps } = props;
//   return isFocused ? (
//     <>
//       <span style={{ marginRight: "8px" }} {...restProps}>
//         <SearchIcon />
//       </span>
//       <span>Search Product</span>
//     </>
//   ) : (
//     <>
//       <span {...restProps}>Select Product</span>
//     </>
//   );
// };

export default function ControllerSelectV2(props) {
  // ... (previous code remains unchanged)
  const { options, name, control, label, id, isMulti } = props;
  console.log(options);
  const error = control?._formState?.errors?.[id]?.message;
  const customStyles = {
    option: (base) => ({
      ...base,
      color: "#0E0750",
      background: "white",
      margin: "4px 0",
      height: "100%",
    }),
    multiValue: (base) => ({
      ...base,
      background: "#ffffff",
      boxShadow: "0px 0px 4px #00000029",
      borderRadius: "19px",
      height: "32px",
      padding: "2px 10px",
      textAlign: "center",
    }),
    control: (base) => ({
      ...base,

      // minWidth: 240,
      // margin: 8,
      height: "54px",
      background: "#ffffff",
      borderRadius: "4px",
      marginTop: "10px",
      borderColor: error ? "red" : "#0000001F",
      "&:focus": {
        borderColor: error ? "red" : "#0E0750",
        outline: error ? "red" : "#0E0750",
        boxShadow: "none",
      },
    }),
    singleValue: (base) => ({
      ...base,
      padding: "2px 10px",
      textAlign: "center",
      background: "#ffffff",
      boxShadow: "0px 0px 4px #00000029",
      borderRadius: "19px",
      height: "32px",
    }),
    multiValueGeneric: (base) => ({
      ...base,
      background: "#ffffff",
      boxShadow: "0px 0px 4px #00000029",
      borderRadius: "19px",
      height: "32px",
      padding: "2px 10px",
      textAlign: "center",
    }),
    indicatorSeparator: () => ({
      display: "none",
      cursor: "pointer",
    }),
  };
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const handleOutsideClick = (e) => {
    if (
      e.target.closest(`.${styles.selectWrapper}`) === null &&
      menuIsOpen &&
      isMulti
    ) {
      setMenuIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [menuIsOpen]);

  return (
    <div className={` ${props.wrapperClass}`}>
      <label shrink className={styles.customLabel}>
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, onBlur } }) => (
          <Select
            id={id}
            isClearable={false}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            isMulti={isMulti}
            styles={customStyles}
            className={styles.selectWrapper}
            classNamePrefix="react-select"
            options={options && options}
            components={{
              Option: CheckboxOption,
              // Placeholder: Placeholder,
            }}
            placeholder={!menuIsOpen ? "Select Product" : "Search Product"}
            closeMenuOnSelect={false}
            hideSelectedOptions={true}
            filterOption={(option, rawInput) =>
              option?.label.toLowerCase().includes(rawInput.toLowerCase())
            }
            onMenuClose={() => {
              if (isMulti) {
                setMenuIsOpen(false);
              } else {
                setMenuIsOpen(true);
              }
            }}
            menuIsOpen={menuIsOpen}
            onMenuOpen={() => {
              setMenuIsOpen(true);
            }}
            controlShouldRenderValue={false}
          />
        )}
      />
      <div className="w-full">
        {error && (
          <p role="alert" className={styles.errorMessage}>
            {error}
          </p>
        )}
      </div>
    </div>
  );
}
