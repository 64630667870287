/** @format */

import React, { useContext, useEffect, useState } from "react";
import { ControllerInput } from "../../../components/common/ControllerInput";
import CustomButton from "../../../components/common/CustomButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styles from "./CreateOutlet.module.css";
import AppLayout from "../../../components/layout";
import { YUP_REQUIRED_MESSAGE } from "../../../helpers/constant";
import SingleSelectDropdown from "../../../components/common/CustomDropdown";
import {
  CREATE_OUTLET,
  SALES_PERSONS_LIST,
} from "../../../helpers/api-endpoints";
import { AppContext } from "../../../helpers/app-context";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import ToastIcon from "../../../assets/icons/ToastIcon";
import { toast } from "react-toastify";
const schema = yup.object().shape({
  name: yup
    .string()
    .required(YUP_REQUIRED_MESSAGE)
    .matches(
      /^[a-zA-Z0-9](?=(?:[^\s]*\s){0,3}[^\s]*$)[a-zA-Z0-9\s]{2,199}$/,
      "Please enter a valid Outlet Name"
    ),
  mobileNumber: yup
    .string()
    .trim()
    .required(YUP_REQUIRED_MESSAGE)
    .matches(/^[6-9]\d{9}$/, "Please enter a valid Phone Number"),
});
const CreateOutlet = () => {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      mobileNumber: "",
    },
  });
  const navigate = useNavigate();
  const ctx = useContext(AppContext);
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const getData = async () => {
    setData([]);
    let formData = new FormData();
    formData.append("company_id", ctx?.selectedCompany?.id);
    const result = await ctx.HttpPost(SALES_PERSONS_LIST, formData);
    if (result.status) {
      setData(result.data);
    }
  };

  useEffect(() => {
    if (ctx?.selectedCompany?.id) getData();
  }, [ctx?.selectedCompany]);
  const handleSalesPersonSelect = (selectedOption) => {
    setSelectedValue(selectedOption);
  };
  const handleCloseToast = () => {
    toast.dismiss();
  };
  const onSubmit = async (data) => {
    if (selectedValue) {
      let formData = new FormData();
      formData.append("outlet_name", data.name);
      formData.append("mobile", data.mobileNumber);
      formData.append("sales_person_id", selectedValue.value);
      formData.append("company_id", ctx?.selectedCompany?.id);

      const result = await ctx.HttpPost(CREATE_OUTLET, formData);
      if (result.status) {
        navigate("/outlet-details");
        ctx.showToastAlert({
          type: "success",
          position: "bottom-left",
          message: (
            <div className="toastContainer">
              <div className="toastIcon">
                <ToastIcon />
              </div>
              <div className="toastContent">
                New Outlet Created Successfully
              </div>
              <div className="toastClose" onClick={handleCloseToast}>
                CLOSE
              </div>
            </div>
          ),
        });
      }
    } else {
      ctx.showToastAlert({
        type: "error",
        message: "Please select Assigned Sales Person",
      });
    }
  };
  return (
    <AppLayout title="Create Outlet" backButton={true}>
      <div className={styles.mainContainer}>
        <div className={styles.breadcrumbsContainer}>
          <CustomBreadcrumbs
            item1="Outlets"
            item2={ctx?.selectedCompany?.name}
            item3="Create outlet"
            lastTypographyColor="#0000004D"
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControllerInput
            control={control}
            name="name"
            id="name"
            label="Outlet Name"
            placeholder="Enter Outlet Name"
            wrapperClass={styles.addSalesPersonInput}
          />
          <ControllerInput
            control={control}
            type="number"
            name="mobileNumber"
            id="mobileNumber"
            label="Mobile Number"
            placeholder="Enter Mobile Number"
            wrapperClass={styles.addSalesPersonInput}
          />
          <div>
            <label shrink className={styles.customLabel}>
              Select Campaign Objectives
            </label>
            <SingleSelectDropdown
              wraperClass={`${styles.addSalesPersonInput} ${styles.wraperClass}`}
              onOptionSelect={handleSalesPersonSelect}
              options={data.map((item) => ({
                mobile: item?.mobile,
                label: item?.name,
                value: item?.sales_person_id,
              }))}
              placeholder="Assigned Sales Person"
              searchPlaceholder="Search salesperson"
            />
          </div>
          <div className={styles.buttonWrapper}>
            <CustomButton title="CREATE OUTLET" />
          </div>
        </form>
      </div>
    </AppLayout>
  );
};

export default CreateOutlet;
