/** @format */

import React from "react";

const CampaignIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="25.8"
      viewBox="0 0 29 25.8"
    >
      <g id="campaign" transform="translate(0.5 0.5)">
        <path
          id="Path_233699"
          data-name="Path 233699"
          d="M8.951,14.2H21.557a.352.352,0,0,0,0-.7H8.951a.352.352,0,0,0,0,.7Zm0,3.524h7a.352.352,0,0,0,0-.7h-7a.352.352,0,0,0,0,.7Zm0,3.524h4.9a.352.352,0,0,0,0-.7h-4.9a.352.352,0,0,0,0,.7Zm0,3.524c8.649.014,5.666.007,6.317.007a.352.352,0,0,0,0-.7c-8.649-.014-5.666-.007-6.317-.007a.352.352,0,0,0,0,.7Zm0,3.524h7.7a.352.352,0,0,0,0-.7h-7.7a.352.352,0,0,0,0,.7ZM6.85,17.729a.348.348,0,0,0,.35-.352v-.712a.35.35,0,1,0-.7,0v.712A.348.348,0,0,0,6.85,17.729Zm19.609-1.41a4.322,4.322,0,0,0,.833-.261.352.352,0,0,0-.217-.67l-.721.24a.351.351,0,0,0,.112.684Z"
          transform="translate(-6.5 -8.5)"
          fill="#f9fafb"
          stroke={color}
          stroke-width="1"
        />
        <path
          id="Path_233700"
          data-name="Path 233700"
          d="M32.916,13.676l-1.352.448a.4.4,0,0,0,.248.76c1.456-.488,1.5-.552,1.712-.392.24.176.168-.768.168,12.5a.4.4,0,0,1-.528.376l-8.272-2.76V17.188L27.8,16.22a.4.4,0,0,0-.248-.76l-1.056.352V11.3a2.8,2.8,0,0,0-2.8-2.8H9.3a2.8,2.8,0,0,0-2.8,2.8v3.208a.4.4,0,1,0,.8,0V11.3a2,2,0,0,1,2-2H23.7a2,2,0,0,1,2,2v4.776l-1.264.424H19.7a1.2,1.2,0,0,0-1.2,1.2v.4h-.4a2,2,0,0,0-2,2v1.6a2,2,0,0,0,2,2h.4v.4a1.2,1.2,0,0,0,.96,1.176L20,29.068A1.206,1.206,0,0,0,21.2,30.1h.9a1.2,1.2,0,0,0,1.2-1.2V25.3h1.136l1.264.424V30.5a2,2,0,0,1-2,2H9.3a2,2,0,0,1-2-2V18.532a.4.4,0,1,0-.8,0V30.5a2.8,2.8,0,0,0,2.8,2.8H23.7a2.8,2.8,0,0,0,2.8-2.8V25.988c6.984,2.328,6.528,2.2,6.8,2.2a1.2,1.2,0,0,0,1.2-1.2V14.812A1.2,1.2,0,0,0,32.916,13.676ZM18.1,22.9a1.2,1.2,0,0,1-1.2-1.2V20.1a1.2,1.2,0,0,1,1.2-1.2h.4v4Zm1.2,1.2V17.7a.4.4,0,0,1,.4-.4h4.4v7.2H19.7A.4.4,0,0,1,19.3,24.1Zm3.2,4.8a.4.4,0,0,1-.4.4h-.9a.412.412,0,0,1-.4-.344l-.52-3.656H22.5v3.6Z"
          transform="translate(-6.5 -8.5)"
          fill="#f9fafb"
          stroke={color}
          stroke-width="1"
        />
      </g>
    </svg>
  );
};

export default CampaignIcon;
