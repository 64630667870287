/** @format */

import React, { useContext, useEffect, useState } from "react";
import styles from "./OutletCampaignList.module.css";
import AppLayout from "../../../components/layout";
import { Box } from "@mui/material";
import Table from "../../../components/common/Table";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../helpers/app-context";
import { CAMPAIGN_LIST_BY_OUTLET } from "../../../helpers/api-endpoints";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
const OutletCampaignList = () => {
  const ctx = useContext(AppContext);
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const getData = async () => {
    setData([]);
    let formData = new FormData();
    formData.append("outlet_id", state?.outlet_id);
    formData.append("company_id", ctx?.selectedCompany?.id);
    const result = await ctx.HttpPost(CAMPAIGN_LIST_BY_OUTLET, formData);
    if (result.status) {
      setData(result.data);
    }
  };
  useEffect(() => {
    if (ctx?.selectedCompany?.id) {
      getData();
    }
  }, [ctx?.selectedCompany]);
  const [params, setParams] = useState({});
  const tableHeader = [
    {
      key: "campaign_name",
      label: "Campaign Name",
      cardColumn: styles.campaignNameCardColumn,
      headerClasses: styles.campaignNameHeaderClasses,
      render: (column) => {
        return (
          <Box
            className={styles.avatarWrapper}
            onClick={() =>
              navigate("/outlet-details/record-request", {
                state: {
                  campaign_id: column.id,
                  outlet_id: state?.outlet_id,
                  outlet_name: state?.outlet_name,
                  campaign_name: column?.campaign_name,
                },
              })
            }
          >
            <img src={column.image} alt="" width={"50px"} height={"40px"} />
            <div>{column.campaign_name}</div>
          </Box>
        );
      },
    },
    {
      key: "campaign_status",
      label: "Campaign Status",
      render: (column) => {
        return (
          <Box
            className={`${
              column.campaign_status !== "inactive"
                ? styles.activeKey
                : styles.inactiveKey
            }`}
          >
            {column.campaign_status !== "inactive" ? "Active" : "Inactive"}
          </Box>
        );
      },
    },
    {
      label: "SKU Recorded / Units",
      render: (column) => {
        return (
          <Box>
            {column.sku_recorded}/{column.unit_recorded}
          </Box>
        );
      },
    },
    {
      label: "Active Coupon",
      render: (column) => {
        return (
          <Box
            className={styles.activeCamp}
            onClick={() =>
              navigate("/outlet-details/active-coupon", {
                state: {
                  outlet_id: state?.outlet_id,
                  outlet_name: column?.outlet_name,
                  campaign_name: column?.campaign_name,
                  outletCoupon: true,
                },
              })
            }
          >
            {column.couponStats.active}
          </Box>
        );
      },
    },
    {
      label: "Redeem Coupon",
      render: (column) => {
        return (
          <Box
            className={styles.activeCamp}
            onClick={() =>
              navigate("/outlet-details/redeemed-coupon", {
                state: {
                  outlet_id: state?.outlet_id,
                  outlet_name: column?.outlet_name,
                  campaign_name: column?.campaign_name,
                  outletCoupon: true,
                },
              })
            }
          >
            {column.couponStats.redeem}
          </Box>
        );
      },
    },
  ];

  const searchInput = {
    key: "campaign_name",
    placeholder: "Search Product",
  };
  const navigate = useNavigate();
  return (
    <AppLayout title={state?.outlet_name} backButton={true}>
      <div className={styles.mainContainer}>
        <div className={styles.breadcrumbsContainer}>
          <CustomBreadcrumbs
            item1="Outlets"
            item2={ctx?.selectedCompany?.name}
            item3={state?.outlet_name}
            lastTypographyColor="#0000004D"
          />
        </div>

        <Table
          tableParams={{ params, setParams }}
          tableHeader={tableHeader}
          tableData={data}
          tableSearchInput={searchInput}
        />
      </div>
    </AppLayout>
  );
};

export default OutletCampaignList;
