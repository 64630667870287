/** @format */

import React, { useContext, useEffect, useState } from "react";
import AppLayout from "../../../components/layout";
import Table from "../../../components/common/Table";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../helpers/app-context";
import { TOTAL_CAMPAIGN_BY_OUTLET } from "../../../helpers/api-endpoints";
import { Box } from "@mui/material";
import styles from "./TotalCampaign.module.css";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
const TotalCampaign = () => {
  const ctx = useContext(AppContext);
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const getData = async () => {
    setData([]);
    let formData = new FormData();
    formData.append("outlet_id", state.outlet_id);
    formData.append("company_id", ctx?.selectedCompany?.id);
    const result = await ctx.HttpPost(TOTAL_CAMPAIGN_BY_OUTLET, formData);
    if (result.status) {
      setData(result.data);
    }
  };
  useEffect(() => {
    if (ctx?.selectedCompany?.id) {
      getData();
    }
  }, [ctx?.selectedCompany]);
  const [params, setParams] = useState({});
  const tableHeader = [
    {
      key: "campaign_name",
      label: "Campaign Name",
    },
    {
      key: "start_date",
      label: "Start Date",
    },
    {
      key: "end_date",
      label: "End Date",
    },
    {
      label: "Active Coupon",
      render: (column) => {
        return (
          <Box color="#6C5CFF">
            {column.couponStats.active}({column.couponStats.total})
          </Box>
        );
      },
    },
    {
      label: "Status",
      render: (column) => {
        return (
          <Box
            color={
              column.campaign_status === "inactive" ? "#F51313" : "#35BF0D"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {column.campaign_status}
          </Box>
        );
      },
    },
  ];

  return (
    <AppLayout title="Total Campaign" backButton={true}>
      <div className={styles.mainContainer}>
        <div className={styles.createCompanyBtn}>
          <CustomBreadcrumbs
            item1="Company"
            item2={ctx?.selectedCompany?.name}
            item3="Sales person"
            item4="Outlet info"
            item5="Total campaign"
            lastTypographyColor="#0000004D"
          />
        </div>
        <Table
          tableParams={{ params, setParams }}
          tableHeader={tableHeader}
          tableData={data}
          tableWrapper={styles.tableWrapper}
        />
      </div>
    </AppLayout>
  );
};

export default TotalCampaign;
