/** @format */

import React from "react";

const CreateIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g
        id="Group_135685"
        data-name="Group 135685"
        transform="translate(-5811 -5129)"
      >
        <rect
          id="Rectangle_27993"
          data-name="Rectangle 27993"
          width="22"
          height="22"
          transform="translate(5811 5129)"
          fill="none"
        />
        <g
          id="Group_135699"
          data-name="Group 135699"
          transform="translate(5810 5128)"
        >
          <path
            id="Path_228996"
            data-name="Path 228996"
            d="M12,8v8"
            fill="none"
            stroke={color ? color : "#fff"}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Path_228997"
            data-name="Path 228997"
            d="M16,12H8"
            fill="none"
            stroke={color ? color : "#fff"}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Path_228998"
            data-name="Path 228998"
            d="M12,21h0a9,9,0,0,1-9-9H3a9,9,0,0,1,9-9h0a9,9,0,0,1,9,9h0A9,9,0,0,1,12,21Z"
            fill="none"
            stroke={color ? color : "#fff"}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            fill-rule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};

export default CreateIcon;
