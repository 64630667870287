/** @format */

import React, { useContext, useEffect, useState } from "react";
import AppLayout from "../../../components/layout";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import styles from "./Payout.module.css";
import { AppContext } from "../../../helpers/app-context";
import { PAYOUT_LIST } from "../../../helpers/api-endpoints";
import CustomButton from "../../../components/common/CustomButton";
import SortIcon from "../../../assets/icons/SortIcon";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import ViewMoreTable from "../../../components/common/ViewMoreTable";
import { Box } from "@mui/material";
const Payout = () => {
  const [data, setData] = useState([]);
  const [params, setParams] = useState({});
  const [calender, setCalender] = useState(false);
  const ctx = useContext(AppContext);
  const [startDate, setStartDate] = React.useState(false);
  const [endDate, setEndDate] = React.useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [date, setDate] = React.useState({
  });
  const paymentMethods = [
    "Ready For Payout",
    "cancelled",
    "reversed",
    "failed",
  ];
  const getData = async () => {
    setData([]);
    const dataToSend = {
      company_id: ctx?.selectedCompany?.id,
      to: date?.endSelectedDate,
      from: date?.startSelectedDate,
    };
    const result = await ctx.HttpPost(PAYOUT_LIST, dataToSend);
    if (result.status) {
      // const data = [
      //   {
      //     amount: "2880.00",
      //     date_day: "-",
      //     outlet_name: "ST-Sunday_Tech Outlet1",
      //     payment_id: "-",
      //     products: [
      //       {
      //         MRP: "360.00",
      //         coupon_code: "J83PP1AM00B",
      //         customer_name: "Sandip kolpe",
      //         product_name:
      //           "ST-Palm Smallest Powerbank (10000mAh PD20W Type C )",
      //         redeem_at: "2023-10-09 11:44:24",
      //         transaction_id: null,
      //       },
      //     ],
      //     status: "Ready For Payout",
      //     upi_id: "",
      //   },
      //   {
      //     amount: "2880.00",
      //     date_day: "-",
      //     outlet_name: "ST-Sunday_Tech Outlet1",
      //     payment_id: "-",
      //     products: [
      //       {
      //         MRP: "360.00",
      //         coupon_code: "J83PP1AM00B",
      //         customer_name: "Sandip kolpe",
      //         product_name:
      //           "ST-Palm Smallest Powerbank (10000mAh PD20W Type C )",
      //         redeem_at: "2023-10-09 11:44:24",
      //         transaction_id: null,
      //       },
      //     ],
      //     status: "Ready For Payout",
      //     upi_id: "",
      //   },
      // ];
      setData(result?.data);
      setDate({
        startSelectedDate: "",
        endSelectedDate: "",
      });
    }
  };
  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  useEffect(() => {
    if (ctx?.selectedCompany?.id) {
      getData();
    }
  }, [ctx?.selectedCompany]);

  // useEffect(() => {
  //   getData();
  // }, []);
  const tableHeader = [
    {
      key: "outlet_name",
      label: "Outlet Name",
      cardColumn: styles.outletNameCard,
      headerClasses: styles.outletNameHeader,
    },
    {
      key: "payment_id",
      label: "Transaction ID",
      cardColumn: styles.paymentIdCard,
      headerClasses: styles.paymentIdHeader,
      render: (column) => {
        return <Box ml={1}>{column.transaction_id}</Box>;
        // if (column.status === "Ready For Payout") {
        //   return <Box>{column.transaction_id}</Box>;
        // } else {
        //   return <Box ml={1}>-</Box>; 
        // }
      },
    },
    {
      label: "Amount",
      key: "amount",
      cardColumn: styles.amountCard,
      headerClasses: styles.amountHeader,
      render: (column) => {
        return <Box ml={1}><span>&#8377;</span>{column.amount}</Box>;
      },
    },
    {
      key: "upi_id",
      label: "UPI ID",
      cardColumn: styles.upiIdCard,
      headerClasses: styles.upiIdHeader,
      render: (column) => {
        return <Box color={"#6C5CFF"} ml={1}>{column.upi_id}</Box>;
      },
    },
    {
      label: "Status",
      cardColumn: styles.statusCard,
      headerClasses: styles.statusHeader,
      render: (column) => {
        const status = column?.status.toLowerCase();
        const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1);
        return (
          <Box
            color={paymentMethods.includes(column?.status) ? "#E41E29" : "#0DBF48"}
            ml={2}
          >
            {capitalizedStatus}
          </Box>
        );
      },
    },

    {
      label: "",
      cardColumn: styles.labelcardColumn,
    },
  ];

  const searchInput = {
    key: "outlet_name",
    placeholder: "Search",
  };
  const handleCalendarClose = () => {
    setCalender(false);
    getData();
  };
  const viewmoreTableHeader = [
    {
      label: "Product Name",
      key: "product_name",
      headerClasses: styles.viewmoreProductHeader,
      cardColumn: styles.viewmoreProductCard,
    },
    {
      label: "Redeemed Date & Time",
      key: "redeem_at",
      // viewMoreValuesCol: true,
    },
    {
      label: "Amount",
      render: (column) => {
        return <Box color={"#6C5CFF"}>₹ {column?.MRP}</Box>;
      },
    },
    {
      label: "Transaction ID",
      key: "transaction_id",
    },
  ];
  return (
    <AppLayout title="Payout" backButton={false}>
      <div className={styles.mainContainer}>
        <div className={styles.breadcrumbsContainer}>
          <CustomBreadcrumbs item1="Payout" lastTypographyColor="#0000004D" />
        </div>
        <div>
          <div className={styles.filterContainer}>
            <div className={styles.uploadBtns}>
              <CustomButton
                title={
                  <div className={styles.btnTitleStyling}>
                    Filter
                    <span className={styles.Iconstyling}>
                      <SortIcon />
                    </span>
                  </div>
                }
                wrapperClass={styles.wrapperClassUploadBtn}
                onClick={() => {
                  setCalender(!calender);
                  setStartDate(true);
                  setEndDate(false);
                }}
              />
            </div>
            {calender && (
              <div className={styles.calenderContainer}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className={styles.buttonContainer}>
                    <div
                      className={
                        startDate ? styles.activeButton : styles.inactiveButton
                      }
                      onClick={() => {
                        setStartDate(true);
                        setEndDate(false);
                      }}
                    >
                      From
                    </div>
                    <div
                      className={
                        endDate ? styles.activeButton : styles.inactiveButton
                      }
                      onClick={() => {
                        setEndDate(true);
                        setStartDate(false);
                      }}
                    >
                      To
                    </div>
                  </div>
                  {startDate && (
                    <DemoContainer components={["StaticDatePicker"]}>
                      <DemoItem>
                        <StaticDatePicker
                          onChange={(selectedDate) => {
                            setDate({
                              ...date,
                              startSelectedDate: dayjs(selectedDate).format('YYYY-MM-DD')
                            })
                          }
                          }
                          value={dayjs(date?.startSelectedDate)}

                          orientation="portrait"
                          disableFuture={true}
                          onAccept={() => {
                            setEndDate(true);
                            setStartDate(false);
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  )}
                  {endDate && (
                    <DemoContainer components={["StaticDatePicker"]}>
                      <DemoItem>
                        {/* Pass the setDate function to StaticDatePicker */}
                        <StaticDatePicker
                          onChange={(selectedDate) =>
                            setDate({ ...date, endSelectedDate: dayjs(selectedDate).format('YYYY-MM-DD') })
                          }
                          value={dayjs(date?.endSelectedDate)}
                          minDate={dayjs(date.startSelectedDate)}
                          orientation="portrait"
                          disableFuture={true}
                          onAccept={handleCalendarClose}
                        />
                      </DemoItem>
                    </DemoContainer>
                  )}
                </LocalizationProvider>
              </div>
            )}
          </div>

          <ViewMoreTable
            viewmoreTableHeader={viewmoreTableHeader}
            tableParams={{ params, setParams }}
            tableHeader={tableHeader}
            tableData={data}
            tableSearchInput={searchInput}
            innerTable={true}
            viewMoreTableIcon={<SortIcon />}
            viewLessTableIcon={<SortIcon />}
            headerColumnColor={styles.headerColumnColor}
          />
        </div>
      </div>
    </AppLayout>
  );
};

export default Payout;
