/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import styles from "./Sidebar.module.css";
import CompanyIcon from "../../../assets/icons/OutletIcon";
import OutletIcon from "../../../assets/icons/CompanyIcon";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "../../../assets/icons/DashboardIcon";
import CampaignIcon from "../../../assets/icons/CampaignIcon";
import ProductSku from "../../../assets/icons/ProductSku";
import ActiveSalesPerson from "../../../assets/svgs/ActiveSalesPerson.svg";
import InActiveSalesPerson from "../../../assets/svgs/InActiveSalesPerson.svg";
import ActiveHelpSupportIcon from "../../../assets/svgs/ActiveHelpSupportIcon.svg";
import InActiveHelpSupportIcon from "../../../assets/svgs/InActiveHelpSupportIcon.svg";
import ActiveWarrantyIcon from "../../../assets/svgs/ActiveWarrantyIcon.svg";
import InActiveWarrantyIcon from "../../../assets/svgs/InActiveWarrantyIcon.svg";
import PayoutIcon from "../../../assets/icons/PayoutIcon";
const drawerWidth = 244;
export const Sidebar = (props) => {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleItemClick = (text) => {
    setSelectedItem(text);
    if (text === "dashboard") {
      navigate("/dashboard");
    } else if (text === "campaign-list") {
      navigate("/campaign-list");
    } else if (text === "outlet-details") {
      navigate("/outlet-details");
    } else if (text === "sales-person") {
      navigate("/sales-person");
    } else if (text === "product-sku") {
      navigate("/product-sku");
    } else if (text === "company-list") {
      navigate("/company-list");
    } else if (text === "help-support") {
      navigate("/help-support/ticket");
    } else if (text === "warranty") {
      navigate("/warranty/customer-data");
    } else if (text === "payout") {
      navigate("/payout");
    }
  };
  const location = useLocation();
  const sideBar = [
    // {
    //   title: "Dashboard",
    //   icon: <DashboardIcon color="white" />,
    //   urlTitle: "dashboard",
    // },
    {
      title: "Campaign",
      icon: <CampaignIcon color="white" />,
      urlTitle: "campaign-list",
    },
    {
      title: "Outlets",
      icon: <OutletIcon color="white" />,
      urlTitle: "outlet-details",
    },
    {
      title: "Sales Person",
      // icon: <SalesPerson color="white" />,
      icon: (
        <img
          src={
            location.pathname.split("/")[1] === "sales-person"
              ? ActiveSalesPerson
              : InActiveSalesPerson
          }
          alt=""
        />
      ),

      urlTitle: "sales-person",
    },
    {
      title: "Product SKU",
      icon: <ProductSku color="white" />,
      urlTitle: "product-sku",
    },
    {
      title: "Company",
      icon: <CompanyIcon color="white" />,
      urlTitle: "company-list",
    },
    {
      title: "Help & Support",
      icon: (
        <img
          src={
            location.pathname.split("/")[1] === "help-support"
              ? ActiveHelpSupportIcon
              : InActiveHelpSupportIcon
          }
          alt=""
        />
      ),
      urlTitle: "help-support",
    },
    {
      title: "Warranty",
      icon: (
        <img
          src={
            location.pathname.split("/")[1] === "warranty"
              ? ActiveWarrantyIcon
              : InActiveWarrantyIcon
          }
          alt=""
        />
      ),
      urlTitle: "warranty",
    },
    {
      title: "Payout",
      icon: <PayoutIcon color="white" />,
      urlTitle: "payout",
    },
  ];
  const drawer = (
    <div>
      <h2 className={styles.sidebarHeading}>Magic Ads</h2>
      <Divider
        sx={{
          color: { sm: "white" },
        }}
      />
      <List>
        {sideBar.map((item, index) => (
          <ListItem key={index} className={styles.noPaddingListItem}>
            <ListItemButton
              onClick={() => handleItemClick(item.urlTitle)}
              className={`${
                location.pathname.split("/")[1] === item?.urlTitle &&
                styles.activebtn
              } ${styles.listItems}`}
            >
              <ListItemIcon
                className={`${styles.noMinWidthListItemIcon} ${
                  location.pathname.split("/")[1] === item?.urlTitle
                    ? styles.activeIconColor // Apply the activeIconColor class for active icons
                    : ""
                }`}
              >
                {React.cloneElement(item.icon, {
                  color:
                    location.pathname.split("/")[1] === item?.urlTitle
                      ? "#6C5CFF" // Set the color to red when active
                      : "white", // Set the color to white when not active
                })}
              </ListItemIcon>
              <div
                // primary={item.title}
                className={
                  location.pathname.split("/")[1] === item?.urlTitle
                    ? styles.activeHeading
                    : styles.listHeading
                }
              >
                {item.title}
              </div>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
        background: "white",
        border: "1px solid white",
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        // variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            outline: "none",
            backgroundColor: "#6C5CFF",
            border: "1px solid white",
          },
        }}
        className={styles.sidebarContainer}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            outline: "none",
            backgroundColor: "#6C5CFF",
            border: "1px solid white",
          },
        }}
        className={styles.sidebarContainer}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
