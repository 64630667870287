/** @format */

import React, { useContext, useEffect, useState } from "react";
import AppLayout from "../../../components/layout";
import Table from "../../../components/common/Table";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../helpers/app-context";
import { OUTLET_ORDER_REQUEST } from "../../../helpers/api-endpoints";
import styles from "../TotalCampaign/TotalCampaign.module.css";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
const OrderRequest = () => {
  const ctx = useContext(AppContext);
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const getData = async () => {
    setData([]);
    let formData = new FormData();
    formData.append("outlet_id", state.outlet_id);
    formData.append(
      "sales_person_id",
      state.sales_person_id ? state.sales_person_id : 0
    );
    formData.append("company_id", ctx?.selectedCompany?.id);
    formData.append("request_type", "order");
    const result = await ctx.HttpPost(OUTLET_ORDER_REQUEST, formData);
    if (result.status) {
      setData(result.data);
    }
  };
  useEffect(() => {
    if (ctx?.selectedCompany?.id) getData();
  }, [ctx?.selectedCompany]);
  const [params, setParams] = useState({});
  const tableHeader = [
    {
      key: "product_name",
      label: "Product Name",
      headerClasses: styles.orderHeaderClasses,
      sorting: true,
      cardColumn: styles.orderCardColumn,
    },
    {
      key: "sku_code",
      label: "SKU Code",
    },
    {
      key: "order_date",
      label: "Order Date",
    },
    {
      key: "order_time",
      label: "Time",
    },
    {
      key: "quantity",
      label: "Quantity",
    },
  ];

  return (
    <AppLayout title="Order Request" backButton={true}>
      <div className={styles.mainContainer}>
        <div className={styles.createCompanyBtn}>
          {state.outletCoupon ? (
            <CustomBreadcrumbs
              item1="Outlets"
              item2={ctx?.selectedCompany?.name}
              item3={state?.outlet_name}
              item4="Order requests"
              lastTypographyColor="#0000004D"
            />
          ) : (
            <CustomBreadcrumbs
              item1="Company"
              item2={ctx?.selectedCompany?.name}
              item3="Sales Person"
              item4="Outlet Info"
              item5="Order Request"
              lastTypographyColor="#0000004D"
            />
          )}
        </div>

        <Table
          tableParams={{ params, setParams }}
          tableHeader={tableHeader}
          tableData={data}
          tableWrapper={styles.tableWrapper}
        />
      </div>
    </AppLayout>
  );
};

export default OrderRequest;
