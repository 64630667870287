/** @format */

import React, { useContext, useEffect, useState } from "react";
import styles from "./OutletInfo.module.css";
import AppLayout from "../../../components/layout";
import { Box } from "@mui/material";
import CustomButton from "../../../components/common/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import ViewMoreTable from "../../../components/common/ViewMoreTable";
import { AppContext } from "../../../helpers/app-context";
import { OUTLET_INFO_BY_SALESPERSON } from "../../../helpers/api-endpoints";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";

const OutletInfo = () => {
  const ctx = useContext(AppContext);
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const getData = async () => {
    setData([]);
    let formData = new FormData();
    formData.append("company_id", ctx?.selectedCompany?.id);
    formData.append("sales_person_id", state.sales_person_id);
    const result = await ctx.HttpPost(OUTLET_INFO_BY_SALESPERSON, formData);
    if (result.status) {
      setData(result.data);
    }
  };
  useEffect(() => {
    if (ctx?.selectedCompany?.id) {
      getData();
    }
  }, [ctx?.selectedCompany]);

  const [params, setParams] = useState({});

  const tableHeader = [
    {
      key: "outlet_name",
      label: "Outlets Name",
      sorting: true,
      cardColumn: styles.outletcardColumn,
      headerClasses: styles.outletheaderClasses,
      render: (column) => {
        return <Box>{column.outlet_name}</Box>;
      },
    },
    {
      key: "location",
      label: "Address",
      cardColumn: styles.cardColumn,
      headerClasses: styles.headerClasses,
      render: (column) => {
        return <Box>{column.location}</Box>;
      },
    },
    {
      label: "Active /Total Campaign",
      render: (column) => {
        return (
          <Box
            className={styles.activeCamp}
            onClick={() =>
              navigate("/sales-person/total-campaign", {
                state: {
                  outlet_id: column.outlet_id,
                },
              })
            }
          >
            {column.campaign.active}({column.campaign.total})
          </Box>
        );
      },
    },
    {
      label: "Tag Stock Request",
      render: (column) => {
        return (
          <Box
            className={styles.activeCamp}
            onClick={() =>
              navigate("/sales-person/tag-stock-request", {
                state: {
                  sales_person_id: state?.sales_person_id,
                  outlet_id: column.outlet_id,
                },
              })
            }
          >
            {column.request.record}
          </Box>
        );
      },
    },
    {
      label: "Order Request",
      render: (column) => {
        return (
          <Box
            className={styles.activeCamp}
            onClick={() =>
              navigate("/sales-person/order-request", {
                state: {
                  sales_person_id: state?.sales_person_id,
                  outlet_id: column.outlet_id,
                },
              })
            }
          >
            {column.request.order}
          </Box>
        );
      },
    },
    {
      label: "",
      cardColumn: styles.labelcardColumn,
    },
  ];
  const viewmoreTableHeader = [
    {
      label: " ",
    },
    {
      label: "Active Coupon",
      viewMoreValuesCol: true,
      render: (column) => {
        return (
          <Box
            className={styles.viewOutletDetails}
            onClick={() =>
              navigate("/sales-person/active-coupon", {
                state: {
                  outlet_id: column.outlet_id,
                },
              })
            }
          >
            ({column.coupons.live})
          </Box>
        );
      },
    },
    // {
    //   label: " ",
    // },
    {
      label: "Redeem Coupon",
      viewMoreValuesCol: true,
      render: (column) => {
        return (
          <Box
            className={styles.viewOutletDetails}
            onClick={() =>
              navigate("/sales-person/redeemed-coupon", {
                state: {
                  outlet_id: column.outlet_id,
                },
              })
            }
          >
            ({column.coupons.redeem})
          </Box>
        );
      },
    },
    {
      label: " ",
    },
    {
      viewMoreValuesCol: true,
      render: (column) => {
        return (
          <CustomButton
            title="Credit History"
            wrapperClass={styles.creditHistoryBtn}
            onClick={() =>
              navigate("/sales-person/credit-history", {
                state: {
                  outlet_id: column?.outlet_id,
                },
              })
            }
          />
        );
      },
    },
  ];
  const searchInput = {
    key: "outlet_name",
    placeholder: "Search Outlet",
  };
  const navigate = useNavigate();
  return (
    <AppLayout title="Outlet Info" backButton={true}>
      <div className={styles.mainContainer}>
        <div className={styles.createCompanyBtn}>
          <CustomBreadcrumbs
            item1="Company"
            item2={ctx?.selectedCompany?.name}
            item3="Sales Person"
            item4="Outlet Info"
            lastTypographyColor="#0000004D"
          />
        </div>

        <ViewMoreTable
          viewmoreTableHeader={viewmoreTableHeader}
          tableParams={{ params, setParams }}
          tableHeader={tableHeader}
          tableData={data}
          tableSearchInput={searchInput}
        />
      </div>
    </AppLayout>
  );
};

export default OutletInfo;
