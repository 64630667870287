/** @format */

import React from "react";

const SettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.192"
      height="18.787"
      viewBox="0 0 19.192 18.787"
    >
      <g
        id="Group_134003"
        data-name="Group 134003"
        transform="translate(-2.283 -2.468)"
      >
        <path
          id="Path_227314"
          data-name="Path 227314"
          d="M3.885,15.478l1.379.211a1.974,1.974,0,0,1,1.671,2.095l-.1,1.392a.99.99,0,0,0,.557.964l1.02.491a.992.992,0,0,0,1.1-.165l1.025-.947a1.974,1.974,0,0,1,2.679,0l1.025.947a.99.99,0,0,0,1.1.165l1.022-.492a.986.986,0,0,0,.556-.961l-.1-1.394a1.974,1.974,0,0,1,1.671-2.095l1.379-.211a.991.991,0,0,0,.816-.759l.252-1.1a.988.988,0,0,0-.406-1.037l-1.152-.788a1.974,1.974,0,0,1-.6-2.612l.7-1.21A.991.991,0,0,0,19.4,6.858l-.706-.885a.991.991,0,0,0-1.065-.33l-1.334.409A1.975,1.975,0,0,1,13.877,4.89l-.511-1.3a.991.991,0,0,0-.924-.627l-1.131,0a.991.991,0,0,0-.921.632l-.5,1.281a1.973,1.973,0,0,1-2.419,1.17L6.084,5.627a.991.991,0,0,0-1.068.332l-.7.886A.99.99,0,0,0,4.238,7.96l.711,1.213A1.974,1.974,0,0,1,4.362,11.8l-1.138.78a.989.989,0,0,0-.406,1.037l.252,1.1a.988.988,0,0,0,.815.76Z"
          transform="translate(0 0)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          fill-rule="evenodd"
        />
        <path
          id="Path_227315"
          data-name="Path 227315"
          d="M13.855,10.073a2.674,2.674,0,1,1-3.781,0,2.674,2.674,0,0,1,3.781,0"
          transform="translate(-0.086 -0.084)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
      </g>
    </svg>
  );
};

export default SettingsIcon;
