/** @format */

import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.297"
      height="24"
      viewBox="0 0 19.297 24"
    >
      <path
        id="delete"
        d="M97.93,85.339A2.625,2.625,0,0,0,100.5,88h10.292a2.625,2.625,0,0,0,2.573-2.661V70H97.93ZM115.3,66h-4.824l-1.615-2H102.44l-1.615,2H96v2h19.3Z"
        transform="translate(-96 -64)"
        fill="#6c5cff"
      />
    </svg>
  );
};

export default DeleteIcon;
