/** @format */

import React, { useContext, useEffect, useState } from "react";
import styles from "./SalesPerson.module.css";
import Table from "../../../components/common/Table";
import CustomButton from "../../../components/common/CustomButton";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../helpers/app-context";
import { SALES_PERSONS_LIST } from "../../../helpers/api-endpoints";
import AddSalesPersonIcon from "../../../assets/icons/AddSalesPersonIcon";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import AppLayout from "../../../components/layout";

const SalesPerson = () => {
  const [params, setParams] = useState({});
  const ctx = useContext(AppContext);
  const [data, setData] = useState([]);
  const getData = async () => {
    setData([]);
    let formData = new FormData();
    formData.append("company_id", ctx?.selectedCompany?.id);
    const result = await ctx.HttpPost(SALES_PERSONS_LIST, formData);
    if (result.status) {
      setData(result.data);
    }
  };
  useEffect(() => {
    if (ctx?.selectedCompany?.id) {
      getData();
    }
  }, [ctx?.selectedCompany]);
  const tableHeader = [
    {
      key: "name",
      label: "Name",
      sorting: true,
    },
    {
      key: "address",
      label: "Address",
      headerClasses: styles.addressheaderClasses,
      cardColumn: styles.addresscardColumn,
    },
    // {
    //   label: "",
    //   render: (column) => {
    //     return <div className={styles.heatmap}>Heatmap</div>;
    //   },
    // },

    {
      key: "outletCount",
      label: "Registered Outlet",
    },
    {
      label: "",
      render: (column) => {
        return (
          <div
            className={styles.outletInfoBtn}
            onClick={() =>
              navigate("/sales-person/outlet-info", {
                state: {
                  sales_person_id: column.sales_person_id,
                },
              })
            }
          >
            Outlet Info
          </div>
        );
      },
    },
  ];
  const searchInput = {
    key: "name",
    placeholder: "Search Sales Person",
  };
  const navigate = useNavigate();
  return (
    <AppLayout
      title={ctx?.selectedCompany?.name + "-" + "Campaign"}
      backButton={false}
    >
      <div className={styles.mainContainer}>
        <div className={styles.createCompanyBtn}>
          <CustomBreadcrumbs
            item1="Company"
            item2={ctx?.selectedCompany?.name}
            lastTypographyColor="#0000004D"
          />
        </div>
        <div className={styles.uploadBtns}>
          <CustomButton
            onClick={() => navigate("/sales-person/add-sales-person")}
            title={
              <div className={styles.btnTitleStyling}>
                <span className={styles.Iconstyling}>
                  <AddSalesPersonIcon />
                </span>
                Add Sales Person
              </div>
            }
            wrapperClass={styles.wrapperClassBtn}
          />
        </div>

        <Table
          tableParams={{ params, setParams }}
          tableHeader={tableHeader}
          tableData={data}
          tableSearchInput={searchInput}
          wrapClassName={styles.cardBodyList}
          // tableWrapper={styles.tableWrapper}
        />
      </div>
    </AppLayout>
  );
};

export default SalesPerson;
