/** @format */

import React from "react";

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g
        id="Group_135787"
        data-name="Group 135787"
        transform="translate(-51.04 -470.039)"
      >
        <path
          id="Path_229049"
          data-name="Path 229049"
          d="M2485.908,12925.26v14"
          transform="translate(-2427.118 -12454.471)"
          fill="none"
          stroke="#2e2e2e"
          stroke-linecap="round"
          stroke-width="1.5"
        />
        <path
          id="Path_229050"
          data-name="Path 229050"
          d="M0,0V14"
          transform="translate(65.79 477.79) rotate(90)"
          fill="none"
          stroke="#2e2e2e"
          stroke-linecap="round"
          stroke-width="1.5"
        />
      </g>
    </svg>
  );
};

export default PlusIcon;
