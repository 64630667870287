/** @format */

import React from "react";

const UploadLogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.346"
      height="23.657"
      viewBox="0 0 23.346 23.657"
    >
      <g
        id="Group_135708"
        data-name="Group 135708"
        transform="translate(1.061 1.061)"
      >
        <path
          id="Path_229001"
          data-name="Path 229001"
          d="M24.531,11.371v8.375a4.786,4.786,0,0,1-4.786,4.786H7.782A4.786,4.786,0,0,1,3,19.746V7.782A4.786,4.786,0,0,1,7.782,3H14.96"
          transform="translate(-2.996 -2.996)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Path_229002"
          data-name="Path 229002"
          d="M3,13.393l1.55-1.55a2.884,2.884,0,0,1,4.079,0l5.139,5.139"
          transform="translate(-2.996 -1.429)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Path_229003"
          data-name="Path 229003"
          d="M7,22.376l7.532-7.532a2.884,2.884,0,0,1,4.079,0l4.713,4.713"
          transform="translate(-2.212 -0.841)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <g
          id="Group_135709"
          data-name="Group 135709"
          transform="translate(14.955 0)"
        >
          <path
            id="Path_229004"
            data-name="Path 229004"
            d="M18,5.5l2.991,2.991"
            transform="translate(-15.011 -5.497)"
            fill="none"
            stroke="#323232"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Path_229005"
            data-name="Path 229005"
            d="M15.5,8.488,18.492,5.5"
            transform="translate(-15.501 -5.497)"
            fill="none"
            stroke="#323232"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Path_229006"
            data-name="Path 229006"
            d="M18,3V8.978"
            transform="translate(-15.012 -2.996)"
            fill="none"
            stroke="#323232"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default UploadLogoIcon;
