/** @format */

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import styles from "./Header.module.css";
import { Box, Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";
import ProfileIc from "../../../assets/icons/ProfileIcon";
import SettingsIcon from "../../../assets/icons/SettingsIcon";
import ProfileDetailsIcon from "../../../assets/icons/ProfileDetailsIcon";
import {
  DEVICE_ID,
  SELECTED_COMPANY,
  TOKEN_NAME,
  USER_DETAILS,
} from "../../../helpers/constant";
import { AppContext } from "../../../helpers/app-context";
import { LOGOUT } from "../../../helpers/api-endpoints";
import { useLocation, useNavigate } from "react-router-dom";
import BackwordIcon from "../../../assets/icons/BackwordIcon";
import CompanyDropdown from "../CompanyDropdown";

const drawerWidth = 244;

export const Header = ({ title, backButton }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleIconClick = () => {
    navigate(-1);
  };

  const routesWithCompanyDropdown = [
    "/campaign-list",
    "/outlet-details",
    "/sales-person",
    "/product-sku",
    "/company-list",
    "/help-support/ticket",
    "/warranty/customer-data",
    "/payout",
  ];

  // Check if the current route is at the end of the URL path
  const shouldRenderCompanyDropdown = routesWithCompanyDropdown.some((route) =>
    location.pathname.endsWith(route)
  );
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
      className={styles.headerContainer}
    >
      <Toolbar className={styles.headerWrapper}>
        <Box sx={{ display: "flex" }}>
          {backButton && (
            <div className={styles.backIcon} onClick={handleIconClick}>
              <BackwordIcon />
            </div>
          )}
          <Typography
            variant="h6"
            noWrap
            component="div"
            className={styles.headerTitle}
          >
            {title}
          </Typography>
        </Box>
        <div className={styles.headerDropdown}>
          {shouldRenderCompanyDropdown && <CompanyDropdown />}
          <ProfileDropdown />
        </div>
      </Toolbar>
    </AppBar>
  );
};

const ProfileDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // const handleNavigate = (path) => {
  //   // history.push(path);
  //   handleCloseMenu();
  // };
  const data = JSON.parse(localStorage.getItem(USER_DETAILS));
  const ctx = React.useContext(AppContext);
  const navigate = useNavigate();
  const handleLogout = async () => {
    let formData = new FormData();
    formData.append("device_id", localStorage.getItem(DEVICE_ID));
    const result = await ctx.HttpPost(LOGOUT, formData);
    if (result.status) {
      localStorage.removeItem(TOKEN_NAME);
      localStorage.removeItem(DEVICE_ID);
      localStorage.removeItem(USER_DETAILS);
      localStorage.removeItem(SELECTED_COMPANY);
      ctx.showToastAlert({
        type: "success",
        position: "bottom-center",
        message: <div className="toastErrorMessage">{result.msg}</div>,
      });
      navigate("/login");
    }
  };

  return (
    <React.Fragment>
      <IconButton color="inherit" onClick={handleOpenMenu}>
        <ProfileIc />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        className={styles.menuLists}
        PaperProps={{
          style: {
            width: 320,
            right: 40,
            padding: 0,
          },
        }}
      >
        <MenuItem className={styles.headerWraper}>
          <Box className={styles.headerName}>
            {data.image ? (
              <img
                src={data.image}
                alt="no img"
                className={styles.profileImage1}
              />
            ) : (
              <div className={styles.profileImage1}>
                <ProfileIc />
              </div>
            )}
            <h6 className={styles.profileName}>{data.name}</h6>
          </Box>
        </MenuItem>
        <MenuItem className={styles.menuItems}>
          <ListItemIcon>
            <ProfileDetailsIcon />
          </ListItemIcon>
          <span className={styles.profileNames}>Profile</span>
        </MenuItem>
        <Divider style={{ marginLeft: 24, marginRight: 24 }} />

        <MenuItem className={styles.menuItems}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <span className={styles.profileNames}> Settings & privacy</span>
        </MenuItem>
        <Divider style={{ marginLeft: 24, marginRight: 24 }} />
        <MenuItem>
          <Box className={styles.logoutDiv} onClick={handleLogout}>
            <span className={styles.logoutBtn}>Logout</span>{" "}
          </Box>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ProfileDropdown;
