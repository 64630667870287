/** @format */

import React, { useContext } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./styles.module.css";
import { ControllerInput } from "../../components/common/ControllerInput";
import CustomButton from "../../components/common/CustomButton";
import { AppContext } from "../../helpers/app-context";
import { useNavigate } from "react-router-dom";
import { FORGOT_PASSWORD } from "../../helpers/api-endpoints";
const schema = yup.object().shape({
  email: yup
    .string()
    .required("This field is required")
    .email("Please provide a valid email address.")
    .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/,
      "Please provide a valid email address."
    ),
});
const ForgotPassword = () => {
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
    },
  });
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    let formData = new FormData();
    formData.append("email", data.email);
    const result = await ctx.HttpPost(FORGOT_PASSWORD, formData);
    if (result.status) {
      navigate("/verify-otp", { state: data.email });
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginWrapper}>
        <div className={styles.formTitle}>Magic Ads</div>
        <div className={styles.formSubTitle}>Forgot Password</div>
        <div className={styles.formDesc}>
          Please enter your registered email address to reset your password.
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControllerInput
            control={control}
            name="email"
            id="email"
            label="Email ID"
            placeholder="Enter your email id"
            labelWrapperClass={styles.labelWrapperClass}
            inputWrapperClass={styles.inputWrapperClass}
            wrapperClass={styles.wrapperClass}
          />

          <CustomButton title="SUBMIT" />
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
