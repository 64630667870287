/** @format */

import React from "react";

function IconFilter() {
  return (
    <svg
      id="Component_41_4"
      data-name="Component 41 – 4"
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="39"
      viewBox="0 0 80 42"
    >
      <g id="sliders_1_" data-name="sliders (1)" transform="translate(8 14)">
        <line
          id="Line_1412"
          data-name="Line 1412"
          y1="5.444"
          transform="translate(2.333 8.556)"
          fill="none"
          stroke="#6c5cff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <line
          id="Line_1413"
          data-name="Line 1413"
          y1="5.444"
          transform="translate(2.333)"
          fill="none"
          stroke="#6c5cff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <line
          id="Line_1414"
          data-name="Line 1414"
          y1="7"
          transform="translate(8.556 7)"
          fill="none"
          stroke="#6c5cff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <line
          id="Line_1415"
          data-name="Line 1415"
          y1="3.889"
          transform="translate(8.556)"
          fill="none"
          stroke="#6c5cff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <line
          id="Line_1416"
          data-name="Line 1416"
          y1="3.889"
          transform="translate(14.778 10.111)"
          fill="none"
          stroke="#6c5cff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <line
          id="Line_1417"
          data-name="Line 1417"
          y1="7"
          transform="translate(14.778)"
          fill="none"
          stroke="#6c5cff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <line
          id="Line_1418"
          data-name="Line 1418"
          x2="4.667"
          transform="translate(0 8.556)"
          fill="none"
          stroke="#6c5cff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <line
          id="Line_1419"
          data-name="Line 1419"
          x2="4.667"
          transform="translate(6.222 3.889)"
          fill="none"
          stroke="#6c5cff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <line
          id="Line_1420"
          data-name="Line 1420"
          x2="4.667"
          transform="translate(12.444 10.111)"
          fill="none"
          stroke="#6c5cff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
      </g>
    </svg>
  );
}

export default IconFilter;
