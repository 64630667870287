/** @format */

import React, { useContext, useEffect, useState } from "react";
import styles from "../../Company/OutletInfo/OutletInfo.module.css";
import AppLayout from "../../../components/layout";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import ViewMoreTable from "../../../components/common/ViewMoreTable";
import { AppContext } from "../../../helpers/app-context";
import { ACTIVE_COUPON_BY_CAMPAIGN } from "../../../helpers/api-endpoints";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";

const RedeemedCoupon = () => {
  const ctx = useContext(AppContext);
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const getData = async () => {
    setData([]);
    let formData = new FormData();
    formData.append("outlet_id", state?.outlet_id);
    formData.append("company_id", ctx?.selectedCompany?.id);
    formData.append("campaign_id", state?.campaign_id ? state?.campaign_id : 0);
    formData.append("type", "redeem");
    const result = await ctx.HttpPost(ACTIVE_COUPON_BY_CAMPAIGN, formData);
    if (result.status) {
      setData(result.data);
    }
  };
  useEffect(() => {
    if (ctx?.selectedCompany?.id) getData();
  }, [ctx?.selectedCompany]);

  const [params, setParams] = useState({});

  const tableHeader = [
    {
      key: "coupon_code",
      label: "Coupon Id",
      cardColumn: styles.couponcardColumn,
    },
    {
      key: "redeem_at",
      label: "Redeemed Date & Time",
      headerClasses: styles.redeemedheaderClasses,
      cardColumn: styles.redeemedcardColumn,
    },
    {
      key: "product_name",
      label: "Product Name",
      cardColumn: styles.productcardColumn,
      headerClasses: styles.productheaderClasses,
    },
    // {
    //   key: "mrp",
    //   label: "MRP",
    //   headerClasses: styles.priceheaderClasses,
    // },
    {
      key: "final_price",
      label: "Final Price",
      headerClasses: styles.priceheaderClasses,
    },
    {
      label: "Status",
      headerClasses: styles.statusheaderClasses,
      render: (column) => {
        return (
          <Box
            color={
              (column.is_redeem === "0" && "yellow") ||
              (column.is_redeem === "1" && "#35BF0D") ||
              (column.is_redeem === "2" && "#F51313")
            }
          >
            {(column.is_redeem === "0" && "Pending") ||
              (column.is_redeem === "1" && "Success") ||
              (column.is_redeem === "2" && "Failed")}
          </Box>
        );
      },
    },
    {
      label: "",
      cardColumn: styles.labelcardColumn,
    },
  ];
  const viewmoreTableHeader = [
    {
      label: " ",
    },
    {
      key: "customer_mobile",
      label: "Customer Mobile",
      viewMoreValuesCol: false,

      render: (column) => {
        return (
          <Box className={styles.customer_mobile}>{column.customer_mobile}</Box>
        );
      },
    },
    {
      // key: "customer_name",
      label: "",
      // viewMoreValuesCol: false,
    },
    {
      label: "",
    },
    {
      label: "",
    },
    {
      label: "",
    },
  ];

  return (
    <AppLayout title="redeemed coupon" backButton={true}>
      <div className={styles.mainContainer}>
        <div className={styles.createCompanyBtn}>
          {state.outletCoupon ? (
            <CustomBreadcrumbs
              item1="Outlets"
              item2={ctx?.selectedCompany?.name}
              item3={state?.outlet_name}
              item4="Redeemed coupon"
              lastTypographyColor="#0000004D"
            />
          ) : (
            <CustomBreadcrumbs
              item1="Company"
              item2={ctx?.selectedCompany?.name}
              item3="Sales person"
              item4="Outlet info"
              item5="Redeemed coupon"
              lastTypographyColor="#0000004D"
            />
          )}
        </div>

        <ViewMoreTable
          viewmoreTableHeader={viewmoreTableHeader}
          tableParams={{ params, setParams }}
          tableHeader={tableHeader}
          tableData={data}
        />
      </div>
    </AppLayout>
  );
};

export default RedeemedCoupon;
