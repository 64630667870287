/** @format */

import React from "react";
import ErrorIcon from "../../../assets/svgs/ErrorIcon.svg";
import CrossIcon from "../../../assets/svgs/CrossIcon.svg";
import { Box, Modal, Typography } from "@mui/material";
import CustomButton from "../CustomButton";
import styles from "./CustomDeleteModal.module.css";

export const CustomDeleteModal = ({
  flag,
  content,
  onClick,
  handleClose,
  btnTItle,
}) => {
  return (
    <Modal
      open={flag}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "331px",
          width: "420px",
          boxShadow: "#00000029 0 4 10",
          border: "1px solid white",
          outline:"#fff",
          backgroundColor: "#FFFFFF ",
        }}
        className={styles.modalProduct}
      >
        <div className={styles.crossIcon}>
          <img src={CrossIcon} onClick={handleClose} alt="not found" />
        </div>
        <Box className={styles.Mwrapper}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img src={ErrorIcon} alt="not found" />
          </Typography>

          <Typography
            id="modal-modal-description"
            className={styles.modalContent}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div className={styles.buttonContainer}>
            <CustomButton
              onClick={handleClose}
              title="Cancel"
              wrapperClass={styles.wrapperClassBtn}
            />
            <CustomButton
              title={btnTItle ? btnTItle : "Delete"}
              onClick={onClick}
              wrapperClass={styles.wrapperClassDeleteBtn}
            />
          </div>
        </Box>
      </Box>
    </Modal>
  );
};
