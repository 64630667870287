/** @format */

import React, { useContext, useEffect, useRef, useState } from "react";
import CustomButton from "../../../components/common/CustomButton";
import styles from "../CreateCampaign/CreateCampaign.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { YUP_REQUIRED_MESSAGE } from "../../../helpers/constant";
import * as yup from "yup";
import { EDIT_CAMPAIGN, CAMPAIGN_DETAILS } from "../../../helpers/api-endpoints";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../helpers/app-context";
import { useForm } from "react-hook-form";
import AppLayout from "../../../components/layout";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import CampaignTabs from "../../../components/common/CampaignTabs";
import CampaignUpload from "../../../assets/icons/CampaignUpload";
import { ControllerInput } from "../../../components/common/ControllerInput";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import CloseIcon from '@mui/icons-material/Close';
import dayjs from "dayjs";
import { toast } from "react-toastify";
const UpdateEditList = () => {
  const [campProducts, setCampProducts] = useState();
  const [photo, setPhoto] = useState();
  const inputRef = useRef(null);
  const { state } = useLocation();
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [previousDiscountType, setPreviousDiscountType] = useState(null);
  const schema = yup.object().shape({
    name: yup.string().required(YUP_REQUIRED_MESSAGE),
    campaignDiscount:
      state?.column?.campaign_type === "1"
        ? yup.string().required(YUP_REQUIRED_MESSAGE)
        : yup.string(),
    discount:
      state?.column?.campaign_type === "1"
        ? yup.string()
          // .matches(/^[0-9]+$/, '% OFF must contain only digits')

          .required(YUP_REQUIRED_MESSAGE) : "",
    campaignURL: yup.string().required(YUP_REQUIRED_MESSAGE),
    campaignBudget: yup.string().required(YUP_REQUIRED_MESSAGE),
    campaignMode: yup.string().required(YUP_REQUIRED_MESSAGE),
    startDate: yup.date().required(YUP_REQUIRED_MESSAGE),
    endDate: yup.string().required(YUP_REQUIRED_MESSAGE),
    products: yup
      .array()
      .of(
        yup.object().shape({
          minQty: yup.number().typeError("min quantity must be a number").required(YUP_REQUIRED_MESSAGE).min(1, 'Quantity must be greater than 0'),
          discPrice: yup
            .number()
            .typeError("Offer price must be a number")
            .positive("Offer price must be a positive number")
            .required("Offer price is required")
            .integer("Offer price must be an integer"),
        })
      )
      .required("At least one product must be added"),

    couponCount:
      yup.string().required(YUP_REQUIRED_MESSAGE)
        .matches(/^[0-9]+$/, 'Pleae enter valid coupon count'),
    // offerprice:
    //   yup.string().required(YUP_REQUIRED_MESSAGE)
  });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const handleFileUploadChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (
      file &&
      (file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg")
    ) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Image = e.target.result;
        const photoURL = URL.createObjectURL(file);

        setPhoto({ base64: base64Image, url: photoURL }); // Set both formats in your state
        setIsImageUploaded(true);
      };

      reader.readAsDataURL(file);
    } else {
      ctx.showToastAlert({
        type: "error",
        message:
          "Please upload an image in either .jpg/.png/.jpeg formats. Other file types are not allowed.",
      });
    }
  };
  useEffect(() => {
    setValue("campaignMode", state?.column?.campaign_platform);
    setValue("campaignDiscount", state?.column?.discount_type);
    setValue("discount", state?.column?.discount);
    setValue("name", state?.column?.campaign_name);
    setValue("campaignBudget", state?.column?.budget);
    setValue("campaignURL", state?.column?.url);
    setValue("startDate", dayjs(state?.column?.start_date).format('YYYY-MM-DD'));
    setValue("endDate", dayjs(state?.column?.end_date).format('YYYY-MM-DD'));
    setValue("couponCount", state?.column?.coupon_count);
    campProducts?.products.map((item) => (
      setValue("minQty", item.min_quantity),
      setValue("discprice", item.discount_price)
    ));

    // setValue("offerprice", state?.column?.discount_price);
    setPhoto(state?.column?.image);
    getData();
  }, []);
  const [data, setData] = useState({
    campaignMode: state?.column?.campaign_platform,
    campaignDiscount: state?.column?.discount_type,
    startDate: dayjs(state?.column?.start_date).format('YYYY-MM-DD'),
    endDate: dayjs(state?.column?.end_date).format('YYYY-MM-DD'),
  });
  const campaignModeWatch = watch("campaignMode");
  const campaignDiscountWatch = watch("campaignDiscount");



  const getData = async () => {
    // setData([]);
    // setCampProducts([]);
    let formData = new FormData();
    formData.append("campaign_id", state?.campId);
    const result = await ctx.HttpPost(CAMPAIGN_DETAILS, formData);
    if (result.status) {
      // setData(result.data.products);
      setCampProducts(result.data)
      const prods = result.data;
      setCampProducts(prods);
    }
  };

  useEffect(() => {
    setData({
      ...data,
      campaignMode: campaignModeWatch,
      campaignDiscount: campaignDiscountWatch,
    });
  }, [campaignDiscountWatch, campaignModeWatch]);

  const onSubmit = async (data) => {
    console.log('formadat', data);
    if (photo) {
      const dataToSend = {
        name: data.name,
        budget: data.campaignBudget,
        max_budget_outlet: "0",
        url: data.campaignURL,
        start_date: data.startDate,
        end_date: data.endDate,
        campaign_type: state?.column?.campaign_type,
        campaign_platform: data.campaignMode,
        company_id: ctx?.selectedCompany?.id,
        campaign_id: state?.column?.id,
        discount_type: data.campaignDiscount,
        discount: data.discount,
        coupon_count: data.couponCount,
        // min_quantity: data.minQty,
        // discount_price: data.discprice,
        campaign_logo: photo.base64 ? photo.base64 : "",
        products: campProducts?.products.map((item, index) => ({
          product_id: item.product_id,
          variant_id: item.value,
          min_quantity: watch(`minQty_${index}`),
          discount_price: watch(`discprice_${index}`),
        })),
      };
      const result = await ctx.HttpPost(EDIT_CAMPAIGN, dataToSend);
      if (result.status) {
        navigate("/campaign-list");
      }
    } else {
      ctx.showToastAlert({
        type: "error",
        message: "Please Upload Campaign Banner",
      });
    }
  };
  const handleDiscountTypeChange = (newValue) => {
    if (newValue !== previousDiscountType) {
      setValue('discount', '');
      setPreviousDiscountType(newValue);
    }
  };
  const removeImage = () => {
    setIsImageUploaded(false);
    setPhoto(null)
  }
  return (
    <AppLayout title="Edit Campaign" backButton={true}>
      <div className={styles.mainContainer}>
        <div className={styles.breadcrumbsContainer}>
          <CustomBreadcrumbs
            item1="Company"
            item2="Edit campaign"
            lastTypographyColor="#0000004D"
          />
        </div>

        <CampaignTabs
          value={0}
          campaignData={[
            state?.column?.campaign_type === "0"
              ? "Ad Campaign"
              : "Coupon Campaign",
          ]}
        />

        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="0"
                control={
                  <Radio
                    checked={data?.campaignMode === "0"}
                    {...register("campaignMode")}
                    onClick={() => handleDiscountTypeChange("0")}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                      },
                      color: "#6C5CFF",
                      "&.Mui-checked": {
                        color: "#6C5CFF",
                      },
                    }}
                  />
                }
                label="Facebook Campaign"
                sx={{
                  "& .MuiTypography-root": {
                    color: "#0E0750",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginRight: "85px",
                  },
                }}
              />
              <FormControlLabel
                value="1"
                control={
                  <Radio
                    checked={data?.campaignMode === "1"}
                    {...register("campaignMode")}
                    onClick={() => handleDiscountTypeChange("1")}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                      },
                      color: "#6C5CFF",
                      "&.Mui-checked": {
                        color: "#6C5CFF",
                      },
                    }}
                  />
                }
                sx={{
                  "& .MuiTypography-root": {
                    color: "#0E0750",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginRight: "85px",
                  },
                }}
                label="Google Campaign"
              />
              <FormControlLabel
                value="2"
                control={
                  <Radio
                    checked={data?.campaignMode === "2"}
                    {...register("campaignMode")}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                      },
                      color: "#6C5CFF",
                      "&.Mui-checked": {
                        color: "#6C5CFF",
                      },
                    }}
                  />
                }
                sx={{
                  "& .MuiTypography-root": {
                    color: "#0E0750",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginRight: "85px",
                  },
                }}
                label="Both"
              />
            </RadioGroup>
            <div className={styles.errorMessage}>
              {errors.campaignMode?.message}
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Typography className={styles.uploadBanner}>
                  Upload Campaign Banner
                </Typography>
                <div
                  className={styles.uploadCampaign}
                  onClick={() => {
                    document.getElementById("edit-campaign-image")
                  }}
                >
                  <input
                    className={styles.profileImageInput}
                    ref={inputRef}
                    type="file"
                    id="edit-campaign-image"
                    onChange={handleFileUploadChange}
                    accept="image/png, image/jpg, image/jpeg"
                  />
                  {
                    photo && (
                      <div className={styles.closeButton} style={{ zIndex: '51' }} onClick={removeImage}>
                        <CloseIcon />
                      </div>
                    )
                  }
                  {photo ? (
                    <img
                      src={photo.url ? photo.url : photo}
                      width="430px"
                      height="110px"
                      className={styles.profileImage}
                      alt="not found"

                    />
                  ) : (
                    <>
                      <CampaignUpload className={styles.uploadIconClass} />
                      <p className={styles.uploadText}>Select logo to Upload</p>
                    </>
                  )}
                </div>
                <p className={styles.uploadContent}>
                  Banner resolution will be 450px by 250 px
                </p>
              </div>
              {state?.column?.campaign_type === "1" && (
                <div style={{ marginTop: "40px" }}>
                  {/* <div className={styles.campaignContainer}>
                <ControllerInput
                  control={control}
                  name="offerprice"
                  id="offerprice"
                  label="Offer Price"
                  placeholder="Enter Name"
                  wrapperClass={styles.WrapperClassleftInput}
                />
              </div> */}

                  {/* <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ marginLeft: "16px" }}
                  >
                    <FormControlLabel
                      value="0"
                      {...register("campaignDiscount")}
                      control={
                        <Radio
                          checked={data?.campaignDiscount === "0"}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                            },
                            color: "#6C5CFF",
                            "&.Mui-checked": {
                              color: "#6C5CFF",
                            },
                          }}
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          color: "#0E0750",
                          fontSize: "16px",
                          fontWeight: "600",
                          marginRight: "64px",
                        },
                      }}
                      label="% OFF"
                    />
                    <FormControlLabel
                      value="1"
                      {...register("campaignDiscount")}
                      control={
                        <Radio
                          checked={data?.campaignDiscount === "1"}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                            },
                            color: "#6C5CFF",
                            "&.Mui-checked": {
                              color: "#6C5CFF",
                            },
                          }}
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          color: "#0E0750",
                          fontSize: "16px",
                          fontWeight: "600",
                        },
                      }}
                      label="₹ OFF"
                    />
                  </RadioGroup>
                  <div className={styles.errorMessage}>
                    {errors.campaignDiscount?.message}
                  </div>
                  {watch("campaignDiscount") && (
                    <ControllerInput
                      control={control}
                      name="discount"
                      id="discount"
                      value={data?.campaignDiscount}
                      placeholder={
                        watch("campaignDiscount") === "0"
                          ? "Enter % OFF"
                          : "Enter ₹ OFF"
                      }
                    />
                  )} */}
                </div>
              )}
            </div>
            <div className={styles.campaignForm}>
              <div className={styles.campaignContainer}>
                <ControllerInput
                  control={control}
                  name="name"
                  id="name"
                  label="Campaign Name"
                  placeholder="Enter Name"
                  wrapperClass={styles.WrapperClassleftInput}
                />
                <ControllerInput
                  control={control}
                  name="campaignBudget"
                  id="campaignBudget"
                  label="Set Campaign Budget"
                  placeholder="Enter Campaign Budget"
                  wrapperClass={styles.WrapperClassrightInput}
                />
              </div>
              <div style={{ display: "flex" }}>
                <ControllerInput
                  control={control}
                  name="campaignURL"
                  id="campaignURL"
                  label="Campaign URL"
                  placeholder="Enter URL"
                  wrapperClass={styles.WrapperClassleftInput}
                />
                {state?.column?.campaign_type === "1" && (
                  <ControllerInput
                    control={control}
                    name="couponCount"
                    id="couponCount"
                    label="Coupon Count"
                    placeholder="Enter Coupon Count"
                    wrapperClass={styles.WrapperClassrightInput}
                  />
                )}
              </div>
              <div className={styles.campaignContainer}>
                <div className={styles.WrapperClassleftInput}>
                  <label shrink className={styles.customLabel}>
                    Set Campaign Start Date
                  </label>
                  <input
                    {...register("startDate")}
                    type="date"
                    className={`${styles.selectDate} ${styles.startDate}`}
                    value={data?.startDate}
                    onChange={(e) =>
                      setData({ ...data, startDate: e.target.value })
                    }
                    max={new Date(2099, 11, 31).toISOString().split("T")[0]}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                  <div className={styles.errorMessage}>
                    {errors.startDate?.message}
                  </div>
                </div>
                <div className={styles.WrapperClassrightInput}>
                  <label shrink className={styles.customLabel}>
                    Set Campaign End Date
                  </label>
                  <input
                    type="date"
                    className={styles.selectDate}
                    {...register("endDate")}
                    value={data?.endDate}
                    min={new Date().toISOString().split("T")[0]}
                    onKeyDown={(e) => e.preventDefault()}
                    max={new Date(2099, 11, 31).toISOString().split("T")[0]}
                    onChange={(e) =>
                      setData({ ...data, endDate: e.target.value })
                    }
                  />
                  <div className={styles.errorMessage}>
                    {errors.endDate?.message}
                  </div>
                </div>
              </div>
            </div>

            {campProducts?.products.map((item, index) => (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "30px",
                    color: "#0E0750",
                    fontSize: "16px",
                    fontWeight: "600",
                    paddingTop: '15px'
                  }}
                >
                  <div style={{ flex: "1", marginBottom: "10px", width: '400px' }}>Product Name</div>
                  <div style={{ flex: "1", textAlign: "center", marginBottom: '10px', marginLeft: '40px' }}>MRP</div>
                  <div style={{ flex: "1", textAlign: "center", marginBottom: '10px', marginRight: '60px' }}>Min Qty</div>
                  <div style={{ flex: "1", textAlign: "center", marginBottom: '10px', marginRight: '40px' }}>Offer Price</div>
                </div>
                <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                  <div style={{ flex: "1" }}>
                    {index + 1}. {item.product_name}
                  </div>
                  <div style={{ flex: "1", textAlign: "center" }}>{item.mrp}</div>
                  <div style={{ flex: "1", textAlign: "center", display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5px' }}>
                    <ControllerInput
                      style={{ width: '100%', maxWidth: '150px' }}
                      control={control}
                      id={`minQty_${index}`}
                      name={`minQty_${index}`}
                      placeholder="Enter Qty"
                      defaultValue={item.min_quantity}
                      inputWrapperClass={styles.addProductInput}
                    />
                  </div>
                  <div style={{ flex: "1", textAlign: "center", display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5px' }}>
                    <ControllerInput
                      style={{ width: '100%', maxWidth: '150px' }}
                      control={control}
                      id={`discprice_${index}`}
                      name={`discprice_${index}`}
                      placeholder="Enter offer price"
                      defaultValue={item.discount_price}
                      inputWrapperClass={styles.addProductInput}
                    />
                  </div>
                </div>
              </>
            ))}
            <CustomButton
              title="SAVE CAMPAIGN"
              wrapperClass={styles.wrapperClassBtn}
            />
          </form>
        </div>
      </div>
    </AppLayout>
  );
};

export default UpdateEditList;

