/** @format */

import React from "react";

function IconSorting({ className }) {
  return (
    <div className={className}>
     <svg id="sort" xmlns="http://www.w3.org/2000/svg" width="24" height="36" viewBox="0 0 24 36">
  <g id="Group_129223" data-name="Group 129223" transform="translate(5899 -810) rotate(90)">
    <path id="Path_226101" data-name="Path 226101" d="M14,8l-4,4,4,4" transform="translate(810 5875)" fill="none" stroke="#a1a4b2" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
    <rect id="Rectangle_25640" data-name="Rectangle 25640" width="24" height="24" transform="translate(810 5875)" fill="none"/>
  </g>
  <g id="Group_129224" data-name="Group 129224" transform="translate(24 12) rotate(90)">
    <path id="Path_226101-2" data-name="Path 226101" d="M10,8l4,4-4,4" fill="none" stroke="#a1a4b2" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
    <rect id="Rectangle_25640-2" data-name="Rectangle 25640" width="24" height="24" fill="none"/>
  </g>
</svg>
    </div>
  );
}

export default IconSorting;
