/** @format */

import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  TextField,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

import styles from "./CustomDropdown.module.css";
import SearchIcon from "../../../assets/icons/SearchIcon";

const SingleSelectDropdown = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown open/close
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
    props.onOptionSelect(option); // Call the callback function with the selected option
  };

  const filteredOptions = props?.options?.filter((option) =>
    option?.label?.toLowerCase().includes(searchValue.toLowerCase())
  );
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column" }}
      className={`${props.wraperClass} ${styles.overlayContainer}`}
    >
      <InputBase
        variant="outlined"
        className={`${styles.selectField} ${props.wrapperSelectInput}`}
        value={selectedOption ? selectedOption.label : props.selectedValue}
        // defaultValue={props.selectedValue}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        readOnly
        placeholder={props.placeholder}
        endAdornment={
          <InputAdornment position="end">
            <IconButton>
              <ExpandMoreOutlinedIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      {dropdownOpen && ( // Render dropdown content only when dropdown is open
        <div
          className={`${styles.dropdownContainer} ${styles.overlayDropdown}`}
        >
          <TextField
            variant="outlined"
            placeholder={props.searchPlaceholder}
            value={searchValue}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: <SearchIcon />,
              sx: {
                backgroundColor: "#fff",
                width: "110%",
                marginBottom: "-4px",
                "& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "12px 14px 8px 12px !important",
                },
                "& .css-1ixds2g": {
                  padding: "12px 14px 8px 12px !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  outline: "none",
                },
                "& input::placeholder": {
                  // paddingLeft: "10px",
                  color: "#00000099",
                  fontSize: "14px",
                },
              },
            }}
          />
          <hr className={styles.searchSeparation} />
          <Box sx={{ maxHeight: 200, overflowY: "auto", cursor: "pointer" }}>
            {filteredOptions &&
              filteredOptions?.map((option) => (
                <Box
                  key={option.value}
                  className={`${styles.dropdownList} `}
                  onClick={() => handleOptionChange(option)}
                >
                  <Box sx={{ display: "flex" }}>
                    {option?.image && (
                      <img
                        src={option?.image}
                        height={"31px"}
                        width={"31px"}
                        alt=""
                        className={styles.optionImages}
                      />
                    )}
                    <div>{option.label}</div>
                  </Box>
                  {option.mobile && <div>{option.mobile}</div>}
                </Box>
              ))}
          </Box>
        </div>
      )}
    </Box>
  );
};

export default SingleSelectDropdown;
