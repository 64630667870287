/** @format */

import React, { useContext, useEffect, useRef, useState } from "react";
import AppLayout from "../../../components/layout";
import styles from "./CreateCampaign.module.css";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { ControllerInput } from "../../../components/common/ControllerInput";
import { useForm } from "react-hook-form";
import CampaignUpload from "../../../assets/icons/CampaignUpload";
import CampaignTabs from "../../../components/common/CampaignTabs";
import ControllerSelectV2 from "../../../components/common/SelectTag";
import { yupResolver } from "@hookform/resolvers/yup";
import { YUP_REQUIRED_MESSAGE } from "../../../helpers/constant";
import * as yup from "yup";
import { CREATE_CAMPAIGN, PRODUCT_LIST } from "../../../helpers/api-endpoints";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../helpers/app-context";
import CustomButton from "../../../components/common/CustomButton";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import ToastIcon from "../../../assets/icons/ToastIcon";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';

const CreateCampaign = () => {
  // const [selectedValue, setSelectedValue] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [addProducts, setAddProducts] = useState();
  // const [submitted, setSubmitted] = useState(false);
  const [photo, setPhoto] = useState();
  const inputRef = useRef(null);
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  // const data = ["CONVERSIONS", "LEAD GENERATION", "TRAFFIC"];
  const schemaWithoutDiscount = yup.object().shape({
    name: yup
      .string()
      .required(YUP_REQUIRED_MESSAGE)
      .matches(
        /^[a-zA-Z0-9](?=(?:[^\s]*\s){0,3}[^\s]*$)[a-zA-Z0-9\s]{2,199}$/,
        "Please enter a valid Compaign Name"
      ),
    campaignURL: yup
      .string()
      .required(YUP_REQUIRED_MESSAGE)
      .matches(
        /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
        "Please enter a valid Campaign URL"
      ),
    campaignMode: yup.string().required(YUP_REQUIRED_MESSAGE),
    startDate: yup.string().required(YUP_REQUIRED_MESSAGE),
    endDate: yup.string().required(YUP_REQUIRED_MESSAGE),
    market: yup.array().required(YUP_REQUIRED_MESSAGE),
    products: yup
      .array()
      .of(
        yup.object().shape({
          minQty: yup.number().typeError("Min quantity must be a number").required(YUP_REQUIRED_MESSAGE).min(1, 'Quantity must be greater than 0'),
          discPrice: yup
            .number()
            .typeError("Offer price must be a number")
            .positive("Offer price must be a positive number")
            .required("Offer price is required")
            .integer("Offer price must be an integer"),
        })
      )
      .required("At least one product must be added"),
    couponCount: yup.string().required(YUP_REQUIRED_MESSAGE)
      .matches(/^[0-9]+$/, "Coupon Count must contain only digits"),
  });

  const schemaWithDiscount = schemaWithoutDiscount.shape({
    // campaignDiscount: yup.string().required(YUP_REQUIRED_MESSAGE),
    // discount: yup
    //   .number().typeError(YUP_REQUIRED_MESSAGE)
    //   // .max(100, '₹ OFF value must be less than 100')
    //   .required(YUP_REQUIRED_MESSAGE),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(
      selectedTab === 1 ? schemaWithDiscount : schemaWithoutDiscount
    ),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const [date, setDate] = useState({ startDate: "", endDate: "" });
  // const handleSalesPersonSelect = (selectedOption) => {
  //   setSelectedValue(selectedOption);
  // };

  const handleAddProduct = async () => {
    let formData = new FormData();
    formData.append("company_id", ctx?.selectedCompany?.id);
    const result = await ctx.HttpPost(PRODUCT_LIST, formData);
    if (result.status) {
      setAddProducts(result.data);
    }
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    // Update header content based on selected tab index
  };
  const watchMarket = watch("market");

  const handleRemoveInputField = (itemValue) => {
    // Find the index of the item to be removed
    const itemIndex = watchMarket.findIndex(
      (market) => market.value === itemValue
    );

    // Remove the item from the watchMarket array
    const updatedMarket = [...watchMarket];
    updatedMarket.splice(itemIndex, 1);

    // Set the updated value in the form
    setValue("market", updatedMarket);

    // Clear the corresponding input field value
    setValue(`products[${itemIndex}].minQty`, "");
    setValue(`products[${itemIndex}].discPrice`, "");
  };
  useEffect(() => {
    handleAddProduct();
    setValue('discount', '');
  }, [watch('campaignDiscount')]);


  const handleFileUploadChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (
      file &&
      (file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg")
    ) {
      // inputRef.current.focus();
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Image = e.target.result;
        const photoURL = URL.createObjectURL(file);

        setPhoto({ base64: base64Image, url: photoURL }); // Set both formats in your state
        setIsImageUploaded(true);
      };

      reader.readAsDataURL(file);
    } else {
      ctx.showToastAlert({
        type: "error",
        message:
          "Please upload an image in either .jpg/.png/.jpeg formats. Other file types are not allowed.",
      });
    }
  };
  const handleCloseToast = () => {
    toast.dismiss();
  };
  const onSubmit = async (data) => {
    // setSubmitted(true);
    if (photo) {
      const dataToSend = {
        name: data.name,
        budget: "0",
        max_budget_outlet: "1000",
        url: data.campaignURL,
        start_date: data.startDate,
        end_date: data.endDate,
        campaign_type: selectedTab,
        campaign_platform: data.campaignMode,
        company_id: ctx?.selectedCompany?.id,
        coupon_count: data.couponCount ? data.couponCount : "",
        discount_type: data.campaignDiscount ? data.campaignDiscount : "0",
        discount: data.discount ? data.discount : "0",
        campaign_logo: photo.base64,
        objectives: "OUTCOME_LEADS",
        products: data.market.map((sku, index) => ({
          product_id: sku.product_id,
          variant_id: sku.value,
          min_quantity: data.products[index].minQty,
          discount_price: data.products[index].discPrice,
        })),
      };
      const result = await ctx.HttpPost(CREATE_CAMPAIGN, dataToSend);
      if (result.status) {
        navigate("/campaign-list");
        ctx.showToastAlert({
          type: "success",
          position: "bottom-left",
          message: (
            <div className="toastContainer">
              <div className="toastIcon">
                <ToastIcon />
              </div>
              <div className="toastContent">Campaign Created Successfully</div>
              <div className="toastClose" onClick={handleCloseToast}>
                CLOSE
              </div>
            </div>
          ),
        });
      }
    } else if (!photo) {
      ctx.showToastAlert({
        type: "error",
        message: "Please Upload Campaign Banner",
      });
    }
  };

  const RemoveImage = () => {
    setIsImageUploaded(false);
    setPhoto(null)
  }
  return (
    <AppLayout title="Create Campaign" backButton={true}>
      <div className={styles.mainContainer}>
        <div className={styles.createCompanyBtn}>
          <div className={styles.breadcrumbsContainer}>
            <CustomBreadcrumbs
              item1="Company"
              item2="Create campaign"
              lastTypographyColor="#0000004D"
            />
          </div>
        </div>

        <CampaignTabs
          onChange={handleTabChange}
          value={selectedTab}
          campaignData={["Ad Campaign", "Coupon Campaign"]}
        />

        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="0"
                control={
                  <Radio
                    {...register("campaignMode")}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                      },
                      color: "#6C5CFF",
                      "&.Mui-checked": {
                        color: "#6C5CFF",
                      },
                    }}
                  />
                }
                label="Facebook Campaign"
                sx={{
                  "& .MuiTypography-root": {
                    color: "#0E0750",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginRight: "85px",
                  },
                }}
              />
              <FormControlLabel
                value="1"
                control={
                  <Radio
                    {...register("campaignMode")}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                      },
                      color: "#6C5CFF",
                      "&.Mui-checked": {
                        color: "#6C5CFF",
                      },
                    }}
                  />
                }
                sx={{
                  "& .MuiTypography-root": {
                    color: "#0E0750",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginRight: "85px",
                  },
                }}
                label="Google Campaign"
              />
              <FormControlLabel
                value="2"
                control={
                  <Radio
                    {...register("campaignMode")}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                      },
                      color: "#6C5CFF",
                      "&.Mui-checked": {
                        color: "#6C5CFF",
                      },
                    }}
                  />
                }
                sx={{
                  "& .MuiTypography-root": {
                    color: "#0E0750",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginRight: "85px",
                  },
                }}
                label="Both"
              />
            </RadioGroup>
            <div className={styles.errorMessage}>
              {errors.campaignMode?.message}
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Typography className={styles.uploadBanner}>
                  Upload Campaign Banner
                </Typography>
                <div
                  className={styles.uploadCampaign}
                // onClick={() => {
                //   const fileInput = document.getElementById("campaign-image");
                //   if (fileInput) {
                //     fileInput.click();
                //   }
                // }}
                >
                  <input
                    className={styles.profileImageInput}
                    // ref={inputRef}
                    type="file"
                    id="campaign-image"
                    onChange={handleFileUploadChange}
                    accept="image/png, image/jpg, image/jpeg"
                  />
                  {
                    photo && isImageUploaded && (
                      <div className={styles.closeButton} style={{ zIndex: '51' }} onClick={RemoveImage}>
                        <CloseIcon className={styles.closeIcon} />
                      </div>
                    )
                  }
                  {photo ? (
                    <img
                      src={photo.url ? photo.url : photo}
                      width="430px"
                      height="110px"
                      className={styles.profileImage}
                      alt="not found"
                    />
                  ) : (
                    <>
                      <CampaignUpload className={styles.uploadIconClass} />
                      <p className={styles.uploadText}>Select logo to Upload</p>
                    </>
                  )}
                </div>
                <p className={styles.uploadContent}>
                  Banner resolution will be 450px by 250 px
                </p>
              </div>
              {/* {selectedTab === 1 && (
                <div style={{ marginTop: "40px" }}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ marginLeft: "16px" }}
                  >
                    <FormControlLabel
                      value="0"
                      {...register("campaignDiscount")}
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                            },
                            color: "#6C5CFF",
                            "&.Mui-checked": {
                              color: "#6C5CFF",
                            },
                          }}
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          color: "#0E0750",
                          fontSize: "16px",
                          fontWeight: "600",
                          marginRight: "64px",
                        },
                      }}
                      label="% OFF"
                    />
                    <FormControlLabel
                      value="1"
                      {...register("campaignDiscount")}
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                            },
                            color: "#6C5CFF",
                            "&.Mui-checked": {
                              color: "#6C5CFF",
                            },
                          }}
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          color: "#0E0750",
                          fontSize: "16px",
                          fontWeight: "600",
                        },
                      }}
                      label="₹ OFF"
                    />
                  </RadioGroup>
                  <div className={styles.errorMessage}>
                    {errors.campaignDiscount?.message}
                  </div>
                  {watch("campaignDiscount") && (
                    <ControllerInput
                      control={control}
                      name="discount"
                      id="discount"
                      placeholder={
                        watch("campaignDiscount") === "0"
                          ? "Enter % OFF"
                          : "Enter ₹ OFF"
                      }
                    />
                  )}
                </div>
              )} */}
            </div>
            <div className={styles.campaignForm}>
              <div className={styles.campaignContainer}>
                <ControllerInput
                  control={control}
                  name="name"
                  id="name"
                  label="Campaign Name"
                  placeholder="Enter Name"
                  wrapperClass={styles.WrapperClassleftInput}
                />
                <ControllerInput
                  control={control}
                  name="campaignURL"
                  id="campaignURL"
                  label="Campaign URL"
                  placeholder="Enter URL"
                  wrapperClass={styles.WrapperClassrightInput}
                />
              </div>
              <div className={styles.campaignContainer}>
                <div className={styles.WrapperClassleftInput}>
                  <label shrink className={styles.customLabel}>
                    Set Campaign Start Date
                  </label>
                  <input
                    {...register("startDate")}
                    type="date"
                    className={`${styles.selectDate} ${styles.startDate} ${errors.startDate?.message && styles.inputError
                      }`}
                    value={date.startDate}
                    min={new Date().toISOString().split("T")[0]}
                    max={new Date(2099, 11, 31).toISOString().split("T")[0]}
                    onChange={(e) => {
                      setDate({ ...date, startDate: e.target.value });
                    }}
                  />
                  <div className={styles.errorMessage}>
                    {errors.startDate?.message}
                  </div>
                </div>
                <div className={styles.WrapperClassrightInput}>
                  <label shrink className={styles.customLabel}>
                    Set Campaign End Date
                  </label>
                  <input
                    type="date"
                    className={`${styles.selectDate} 
                    ${errors.endDate?.message && styles.inputError
                      }`}
                    {...register("endDate")}
                    value={date.endDate}
                    min={date.startDate}
                    max={new Date(2099, 11, 31).toISOString().split("T")[0]}
                    onChange={(e) => {
                      setDate({ ...date, endDate: e.target.value });
                    }}
                  />
                  <div className={styles.errorMessage}>
                    {errors.endDate?.message}
                  </div>
                </div>
              </div>
              <div
                className={styles.productSelect}
              // onClick={() => handleAddProduct()}
              >
                <ControllerSelectV2
                  wrapperClass={styles.WrapperClassleftInput}
                  isMulti={true}
                  control={control}
                  options={
                    addProducts &&
                    addProducts.map((item) => {
                      return {
                        product_id: item?.product_id,
                        label: item?.product_name,
                        value: item?.variant_id,
                        image: item?.product_images[0]?.product_image,
                        mrp: item?.mrp,
                      };
                    })
                  }
                  name="market"
                  id="market"
                  label="Select Product"
                />
                {selectedTab === 1 && (
                  <ControllerInput
                    control={control}
                    name="couponCount"
                    id="couponCount"
                    label="Coupon Count"
                    placeholder="Enter Coupon Count"
                    wrapperClass={styles.WrapperClassrightInput}
                  />
                )}
              </div>
              {/* {watchMarket && watchMarket.length > 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "90%",
                      marginTop: "30px",
                      color: "#0E0750",
                      fontSize: "16px",
                      fontWeight: "600"
                    }}

                  >
                    <label>Product Name</label>
                    <label>MRP</label>
                    <label >Min Quantity</label>
                    {selectedTab === 1 && (
                      <label>Offer Price</label>
                    )}

                  </div>
                  {watchMarket.map((item, index) => (
                    <div key={item} style={{display:'flex'}}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "90%",
                          alignItems:'center'
                          
                        }}
                      >
                        <div
                          style={{
                            color: "#0E0750",
                            maxWidth: "300px"
                          }}
                        
                        >
                          {index + 1}. {item.label}
                        </div>
                       
                        <div
                        > {item.mrp}
                        </div>
                        <div>
                          <ControllerInput
                            control={control}
                            name={`products[${index}].minQty`}
                            placeholder="Enter Qty"
                            inputWrapperClass={styles.addProductInput}
                          />
                        </div>
                
                        <div>
                          {selectedTab === 1 && (
                            <>
                              <ControllerInput
                                control={control}
                                name={`products[${index}].discPrice`}
                                placeholder="Enter offer price"
                                inputWrapperClass={styles.addProductInput}
                              />
                            </>
                          )}
                        </div>
                        <div
                          onClick={() => handleRemoveInputField(item?.value)}
                        // style={{ marginLeft: "53px", marginTop: "40px" }}
                        >
                          <DeleteIcon />
                        </div>
                      </div>

                    </div>
                  ))}
                </>
              )} */}
              {watchMarket && watchMarket.length > 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "90%",
                      marginTop: "30px",
                      color: "#0E0750",
                      fontSize: "16px",
                      fontWeight: "600"
                    }}
                  >
                    {
                      selectedTab === 0 && (
                        <>
                          <div style={{ flex: "1" }}>Product Name</div>
                          <div style={{ flex: "1", textAlign: "center", marginLeft: '120px' }}>MRP</div>
                          <div style={{ flex: "1", textAlign: "center", marginRight: '130px' }}>Min Quantity</div>
                        </>
                      )

                    }

                    {selectedTab === 1 && (
                      <><div style={{ flex: "1" }}>Product Name</div>
                        <div style={{ flex: "1", textAlign: "center", marginLeft: '113px' }}>MRP</div>
                        <div style={{ flex: "1", textAlign: "center", marginRight: '152px' }}>Min Quantity</div>
                        <div style={{ flex: "1", marginLeft: '15px' }}>Offer Price</div></>
                    )}
                  </div>
                  {watchMarket.map((item, index) => (
                    <div key={item} style={{ display: "flex", marginTop: "10px", justifyContent: "center", alignItems: "center" }}>
                      <div
                        style={{
                          flex: "1",
                          color: "#0E0750",
                          maxWidth: "300px",
                          whiteSpace: "wrap"
                        }}
                      >
                        {index + 1}. {item.label}
                      </div>
                      <div style={{ flex: "1", textAlign: "center" }}>{item.mrp}</div>
                      <div style={{ flex: "1", textAlign: "center" }}>
                        <ControllerInput
                          control={control}
                          name={`products[${index}].minQty`}
                          placeholder="Enter Qty"
                          inputWrapperClass={styles.addProductInput}
                        />
                      </div>
                      {selectedTab === 1 && (
                        <div style={{ flex: "1", textAlign: "right" }}>
                          <ControllerInput
                            control={control}
                            name={`products[${index}].discPrice`}
                            placeholder="Enter offer price"
                            inputWrapperClass={styles.addProductInput}
                          />
                        </div>
                      )}
                      <div onClick={() => handleRemoveInputField(item?.value)} style={{ marginRight: '10px' }}>
                        <DeleteIcon />
                      </div>
                    </div>
                  ))}
                </>
              )}

            </div>
            <CustomButton
              title="CREATE CAMPAIGN"
              wrapperClass={styles.wrapperClassBtn}
            />
          </form>
        </div>
      </div>
    </AppLayout>
  );
};

export default CreateCampaign;
