/** @format */

import React, { useContext, useEffect, useRef, useState } from "react";
import AppLayout from "../../../components/layout";
import { ControllerInput } from "../../../components/common/ControllerInput";
import styles from "./CreateCompany.module.css";
import { YUP_REQUIRED_MESSAGE } from "../../../helpers/constant";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomButton from "../../../components/common/CustomButton";
import UploadLogoIcon from "../../../assets/icons/UploadLogoIcon";
import { CREATE_COMPANY, EDIT_COMPANY } from "../../../helpers/api-endpoints";
import { AppContext } from "../../../helpers/app-context";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ToastIcon from "../../../assets/icons/ToastIcon";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
const schema = yup.object().shape({
  companyName: yup
    .string()
    .required(YUP_REQUIRED_MESSAGE)
    .matches(
      /^[a-zA-Z0-9](?=(?:[^\s]*\s){0,3}[^\s]*$)[a-zA-Z0-9\s]{2,199}$/,
      "Please enter a valid Company Name"
    ),
  mobileNumber: yup
    .string()
    .trim()
    .required(YUP_REQUIRED_MESSAGE)
    .matches(/^[6-9]\d{9}$/, "Please enter a valid Mobile Number"),
});
const CreateCompany = () => {
  const { handleSubmit, setValue, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const ctx = useContext(AppContext);
  const [photo, setPhoto] = useState();
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleCloseToast = () => {
    toast.dismiss();
  };
  const onSubmit = async (data) => {
    if (photo) {
      let formData = new FormData();
      formData.append("company_name", data.companyName);
      formData.append("mobile", data.mobileNumber);
      formData.append("logo", photo.file ? photo.file : photo);
      if (state) {
        formData.append("company_id", state?.id);
        const result = await ctx.HttpPost(EDIT_COMPANY, formData);
        if (result.status) {
          navigate("/company-list");
        }
      } else {
        const result = await ctx.HttpPost(CREATE_COMPANY, formData);
        if (result.status) {
          navigate("/company-list");

          ctx.showToastAlert({
            type: "success",
            position: "bottom-left",
            message: (
              <div className="toastContainer">
                <div className="toastIcon">
                  <ToastIcon />
                </div>
                <div className="toastContent">Company Created Successfully</div>
                <div className="toastClose" onClick={handleCloseToast}>
                  CLOSE
                </div>
              </div>
            ),
          });
        }
      }
    } else {
      ctx.showToastAlert({
        type: "error",
        message: "Please upload Logo",
      });
    }
  };
  const handleFileUploadChange = (event) => {
    console.log("onclick");
    event.preventDefault();
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg")
    ) {
      const photoURL = URL.createObjectURL(file);
      setPhoto({ file, url: photoURL });
    } else {
      ctx.showToastAlert({
        type: "error",
        message:
          "Please upload an image in either .jpg/.png/.jpeg formats. Other file types are not allowed.",
      });
    }
  };
  useEffect(() => {
    if (state) {
      setValue("companyName", state?.name);
      setValue("mobileNumber", state?.phone);
      setPhoto(state?.logo);
    }
  }, []);
  return (
    <AppLayout
      title={!state?.phone ? "Create Company" : "Edit Company"}
      backButton={true}
    >
      <div className={styles.mainContainer}>
        <CustomBreadcrumbs
          item1={!state?.phone ? "Create company" : "Edit company"}
          lastTypographyColor="#0000004D"
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <div
          style={{background:"#F3F5F86B"}}
            className={styles.uploadImage}
            // onClick={() => {
            //   document.getElementById("company-image").click();
            // }}
          >
            <input
              className={styles.profileImageInput}
              type="file"
              id="company-image"
              onChange={handleFileUploadChange}
              accept="image/png, image/jpg, image/jpeg"
            />
            {photo ? (
              <img
                src={photo.url ? photo.url : photo}
                width="140px"
                height="74px"
                className={styles.profileImage}
                alt="not found"
              />
            ) : (
              <>
                <UploadLogoIcon />
                <p>Upload Logo</p>
              </>
            )}
          </div>

          <ControllerInput
            control={control}
            name="companyName"
            id="companyName"
            label="Company Name"
            placeholder="Enter Name"
            wrapperClass={styles.addSalesPersonInput}
          />
          <ControllerInput
            control={control}
            type="number"
            name="mobileNumber"
            id="mobileNumber"
            label="Mobile Number"
            placeholder="Enter Mobile Number"
            wrapperClass={styles.addSalesPersonInput}
          />

          <div className={styles.buttonWrapper}>
            <CustomButton title={state ? "SAVE" : "CREATE COMPANY"} />
          </div>
        </form>
      </div>
    </AppLayout>
  );
};

export default CreateCompany;
