/** @format */

import React, { useContext, useEffect, useState } from "react";
import AppLayout from "../../../components/layout";
import styles from "./CustomerData.module.css";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import CustomTab from "../../../components/common/CustomTab";
import Table from "../../../components/common/Table";
import ScratchCode from "../ScratchCode";
import { AppContext } from "../../../helpers/app-context";
import {
  DOWNLOAD_WARRANTY_EXCEL,
  PRODUCT_WARRANTY_LIST,
} from "../../../helpers/api-endpoints";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
const CustomerData = () => {
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [params, setParams] = useState({});
  const ctx = useContext(AppContext);

  const getData = async () => {
    setData([]);
    const dataToSend = {
      company_id: ctx?.selectedCompany?.id,
    };
    const result = await ctx.HttpPost(PRODUCT_WARRANTY_LIST, dataToSend);
    if (result.status) {
      setData(result.data);
    }
  };
  const tableHeader = [
    {
      key: "customer_name",
      label: "Customer Name",
    },
    {
      key: "whatsapp_no",
      label: "WhatsApp Number",
    },
    {
      label: "Invoice",
      render: (column) => {
        return (
          <a
            href={column.invoice}
            target="_blank"
            rel="noreferrer"
            className={styles.invoicePdf}
          >
            View Invoice
          </a>
        );
      },
    },
    {
      key: "product_name",
      label: "Product",
      headerClasses: styles.productHeaderClasses,
      cardColumn: styles.productCardColumn,
    },
    {
      key: "scratch_code",
      label: "Scratch Code",
    },
  ];

  const searchInput = {
    key: "customer_name",
    placeholder: "Search Customer Name",
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  useEffect(() => {
    if (ctx?.selectedCompany?.id) {
      getData();
    }
  }, [ctx?.selectedCompany]);

  const downloadExcel = async () => {
    const dataToSend = {
      company_id: ctx?.selectedCompany?.id,
    };
    const result = await ctx.HttpPost(DOWNLOAD_WARRANTY_EXCEL, dataToSend);
    if (result.status) {
      downloadFile(result.msg);
    }
  };
  const downloadFile = async (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "example.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <AppLayout title="Warranty Management" backButton={false}>
      <div className={styles.mainContainer}>
        <div className={styles.breadcrumbsContainer}>
          <CustomBreadcrumbs
            item1="Company"
            item2="Warranty management"
            lastTypographyColor="#0000004D"
          />
        </div>
        <CustomTab
          onChange={handleTabChange}
          value={selectedTab}
          customLabel={["Customer Data", "Scratch Code"]}
        />
        <div style={{ marginLeft: "18px", marginRight: "17px" }}>
          {selectedTab === 0 && (
            <>
              <div className={styles.uploadBtns}>
                <div
                  className={styles.downloadExcel}
                  onClick={() => downloadExcel()}
                >
                  <span className={styles.Iconstyling}>
                    <DownloadIcon color="#6C5CFF" />
                  </span>
                  DOWNLOAD EXCEL
                </div>
              </div>

              <Table
                tableParams={{ params, setParams }}
                tableHeader={tableHeader}
                tableData={data}
                tableSearchInput={searchInput}
                // wrapClassName={styles.cardBodyList}
                tableWrapper={styles.tableWrapper}
              />
            </>
          )}
          {selectedTab === 1 && <ScratchCode />}
        </div>
      </div>
    </AppLayout>
  );
};

export default CustomerData;
