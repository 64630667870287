/** @format */

import React from "react";

const SearchCloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
    >
      <defs>
        <filter
          id="Ellipse_19"
          x="0"
          y="0"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-opacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_135982"
        data-name="Group 135982"
        transform="translate(-177 -383)"
      >
        <g transform="matrix(1, 0, 0, 1, 177, 383)" filter="url(#Ellipse_19)">
          <circle
            id="Ellipse_19-2"
            data-name="Ellipse 19"
            cx="10"
            cy="10"
            r="10"
            transform="translate(9 6)"
            fill="#fff"
          />
        </g>
        <path
          id="Icon_ionic-ios-close"
          data-name="Icon ionic-ios-close"
          d="M14.737,14.079l1.993-1.993a.467.467,0,0,0-.661-.661l-1.993,1.993-1.993-1.993a.467.467,0,1,0-.661.661l1.993,1.993-1.993,1.993a.467.467,0,0,0,.661.661l1.993-1.993,1.993,1.993a.467.467,0,0,0,.661-.661Z"
          transform="translate(181.924 384.922)"
          fill="#6c5cff"
          stroke="#6c5cff"
          stroke-width="0.5"
        />
      </g>
    </svg>
  );
};

export default SearchCloseIcon;
