/** @format */

import React from "react";

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g
        id="Group_135747"
        data-name="Group 135747"
        transform="translate(-870 -190)"
      >
        <rect
          id="Rectangle_28006"
          data-name="Rectangle 28006"
          width="40"
          height="40"
          transform="translate(870 190)"
          fill="none"
        />
        <g
          id="Group_135746"
          data-name="Group 135746"
          transform="translate(-76.75 7.25)"
        >
          <path
            id="Path_229032"
            data-name="Path 229032"
            d="M8661.692,7381.839V7408"
            transform="translate(71.006 -11150.989) rotate(45)"
            fill="none"
            stroke="#707070"
            stroke-linecap="round"
            stroke-width="1"
          />
          <path
            id="Path_229033"
            data-name="Path 229033"
            d="M0,26.162V0"
            transform="translate(975.999 211.999) rotate(135)"
            fill="none"
            stroke="#707070"
            stroke-linecap="round"
            stroke-width="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default CloseIcon;
