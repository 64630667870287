/** @format */

import React from "react";

const ProfileDetailsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.615"
      height="19.645"
      viewBox="0 0 20.615 19.645"
    >
      <g
        id="Group_133996"
        data-name="Group 133996"
        transform="translate(0.6 0.6)"
      >
        <path
          id="Path_227292"
          data-name="Path 227292"
          d="M10,10.193a2.049,2.049,0,1,1-2.9,0,2.049,2.049,0,0,1,2.9,0"
          transform="translate(-2.192 -2.331)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.2"
        />
        <path
          id="Path_227293"
          data-name="Path 227293"
          d="M15,11h4.1"
          transform="translate(-2.556 -2.263)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.2"
        />
        <path
          id="Path_227294"
          data-name="Path 227294"
          d="M18.074,14.5H15"
          transform="translate(-2.504 -2.366)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.2"
        />
        <path
          id="Path_227295"
          data-name="Path 227295"
          d="M12.173,18.089a3.165,3.165,0,0,0-.79-1.152h0a3.167,3.167,0,0,0-2.152-.844H7.942a3.162,3.162,0,0,0-2.152.844h0A3.147,3.147,0,0,0,5,18.089"
          transform="translate(-1.926 -2.499)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.2"
        />
        <path
          id="Path_227296"
          data-name="Path 227296"
          d="M13.074,7.123H11.025A1.025,1.025,0,0,1,10,6.1V3.025A1.025,1.025,0,0,1,11.025,2h2.049A1.025,1.025,0,0,1,14.1,3.025V6.1A1.025,1.025,0,0,1,13.074,7.123Z"
          transform="translate(-2.342 -2)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.2"
          fill-rule="evenodd"
        />
        <path
          id="Path_227297"
          data-name="Path 227297"
          d="M9.766,5H3.981A1.971,1.971,0,0,0,2,6.961V18.449A1.931,1.931,0,0,0,3.941,20.37H19.473a1.931,1.931,0,0,0,1.941-1.921V6.921A1.931,1.931,0,0,0,19.473,5H13.649"
          transform="translate(-2 -1.926)"
          fill="none"
          stroke="#323232"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.2"
        />
      </g>
    </svg>
  );
};

export default ProfileDetailsIcon;
