/** @format */

import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../../components/common/CustomButton";
import CreateIcon from "../../../assets/icons/CreateIcon";
import Table from "../../../components/common/Table";
import { AppContext } from "../../../helpers/app-context";
import styles from "./ScratchCode.module.css";
import CustomizedDialogs from "../../../components/common/CustomModal";
import { ControllerInput } from "../../../components/common/ControllerInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { YUP_REQUIRED_MESSAGE } from "../../../helpers/constant";
import {
  CREATE_SCRATCH_CODE_LIST,
  DOWNLOAD_SCRATCH_CODE_LIST,
  GET_SCRATCH_CODE,
  PRODUCT_LIST,
} from "../../../helpers/api-endpoints";
import ToastIcon from "../../../assets/icons/ToastIcon";
import { toast } from "react-toastify";
import SingleSelectDropdown from "../../../components/common/CustomDropdown";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import { Box } from "@mui/material";

const ScratchCode = () => {
  const [selectedValue, setSelectedValue] = useState();
  const schema = yup.object().shape({
    quantity: yup.string().required(YUP_REQUIRED_MESSAGE)
      .matches(/^[0-9]+$/, "Please enter valid quantity "),
  });
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: "",
  });
  const [params, setParams] = useState({});
  const ctx = useContext(AppContext);
  const [data, setData] = useState([]);
  const [scratchCode, setScratchCode] = useState(false);
  const [addProducts, setAddProducts] = useState();

  const getData = async () => {
    setData([]);
    const dataToSend = {
      company_id: ctx?.selectedCompany?.id,
    };
    const result = await ctx.HttpPost(GET_SCRATCH_CODE, dataToSend);
    if (result.status) {
      setData(result.data);
    }
  };
  useEffect(() => {
    if (ctx?.selectedCompany?.id) {
      getData();
    }
  }, [ctx?.selectedCompany]);
  const handleDownload = async (set_id) => {
    const dataToSend = {
      set_id: set_id,
    };
    const result = await ctx.HttpPost(DOWNLOAD_SCRATCH_CODE_LIST, dataToSend);
    if (result.status) {
      downloadFile(result.msg);
    }
  };
  const downloadFile = async (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "example.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const tableHeader = [
    {
      key: "product_name",
      label: "Product Name",
      headerClasses: styles.productHeaderClasses,
      cardColumn: styles.productCardColumn,
    },
    {
      key: "product_sku",
      label: "Product SKU",
    },
    {
      key: "created_at",
      label: "Date & Time",
      headerClasses: styles.dateHeaderClasses,
      cardColumn: styles.dateCardColumn,
    },

    {
      label: "Scratch Code Count",
      headerClasses: styles.codeHeaderClasses,
      cardColumn: styles.codeCardColumn,
      render: (column) => {
        return <Box color="#6C5CFF">{column.count}</Box>;
      },
    },
    {
      label: "",
      render: (column) => {
        return (
          <div
            className={styles.downloadScratchCode}
            onClick={() => handleDownload(column?.set_id)}
          >
            <div style={{ marginLeft: "20px" }}>
              <DownloadIcon color="#6C5CFF" />
            </div>
            <div>DOWNLOAD</div>
          </div>
        );
      },
    },
  ];
  const searchInput = {
    key: "product_name",
    placeholder: "Search Scratch code",
  };
  const handleSalesPersonSelect = (selectedOption) => {
    setSelectedValue(selectedOption);
  };
  const handleCloseToast = () => {
    toast.dismiss();
  };
  const handleAddProduct = async () => {
    setScratchCode(true);
    let formData = new FormData();
    formData.append("company_id", ctx?.selectedCompany?.id);
    const result = await ctx.HttpPost(PRODUCT_LIST, formData);
    if (result.status) {
      setAddProducts(result.data);
    }
  };
  const onSubmit = async (data) => {
    if (selectedValue) {
      const dataToSend = {
        scratch_count: data.quantity,
        company_id: ctx?.selectedCompany?.id,
        product_id: selectedValue?.product_id,
        variant_id: selectedValue?.value,
      };
      const result = await ctx.HttpPost(CREATE_SCRATCH_CODE_LIST, dataToSend);
      if (result.status) {
        getData();
        reset();
        setSelectedValue(null);
        setScratchCode(false);
        ctx.showToastAlert({
          type: "success",
          position: "bottom-left",
          message: (
            <div className="toastContainer">
              <div className="toastIcon">
                <ToastIcon />
              </div>
              <div className="toastContent">
                Alphanumeric Code Generated Successfully
              </div>
              <div className="toastClose" onClick={handleCloseToast}>
                CLOSE
              </div>
            </div>
          ),
        });
      }
    } else {
      ctx.showToastAlert({
        type: "error",
        message: "Please Select SKU",
      });
    }
  };
  const handleModalClose = () => {
    // Reset the form data and selected value
    reset();
    setSelectedValue(null);
    setScratchCode(false);
  };

  return (
    <>
      <div className={styles.uploadBtns}>
        <CustomButton
          onClick={() => handleAddProduct()}
          title={
            <div className={styles.btnTitleStyling}>
              <span className={styles.Iconstyling}>
                <CreateIcon />
              </span>
              Generate Scratch Code
            </div>
          }
          wrapperClass={styles.wrapperClassBtn}
        />
      </div>

      <Table
        tableParams={{ params, setParams }}
        tableHeader={tableHeader}
        tableData={data}
        tableSearchInput={searchInput}
        wrapClassName={styles.cardBodyList}
        tableWrapper={styles.tableWrapper}
      />
      {scratchCode && (
        <CustomizedDialogs
          handleClose={handleModalClose}
          modalTitle={"Generate Scratch Code"}
          modalContent={
            <div className={styles.modalContainer}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label shrink className={styles.customLabel}>
                    Select SKU
                  </label>
                  <SingleSelectDropdown
                    wrapperSelectInput={styles.wrapperSelectInput}
                    onOptionSelect={handleSalesPersonSelect}
                    options={
                      addProducts &&
                      addProducts.map((item) => ({
                        label: item?.product_name,
                        value: item?.variant_id,
                        product_id: item?.product_id,
                        image: item?.product_images[0]?.product_image,
                      }))
                    }
                    placeholder="Select SKU"
                    searchPlaceholder="Search SKU"
                    wraperClass={styles.wraperClass}
                  />
                </div>
                {selectedValue && (
                  <ControllerInput
                    control={control}
                    name="quantity"
                    id="quantity"
                    label="Enter Quantity"
                    placeholder="Enter Quantity"
                    wrapperClass={styles.inputWrapperClass}
                  />
                )}

                <div className={styles.generateCouponBtn}>
                  <CustomButton
                    title={"GENERATE"}
                    wrapperClass={styles.wrapperClassBtn}
                  />
                </div>
                <div className={styles.generateCoupon}>
                  (It generates 12 digits Alphanumeric codes with serial number)
                </div>
              </form>
            </div>
          }
        />
      )}
    </>
  );
};

export default ScratchCode;
