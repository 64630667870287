/** @format */

import React from "react";

import { Header } from "./Header/Header";
import { Sidebar } from "./sidebar/Sidebar";
import styles from "./Layout.module.css";

function AppLayout({ children, title, backButton }) {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <Header title={title} backButton={backButton} />
      </div>
      <div className={styles.sidebarContainer}>
        <Sidebar />
      </div>
      <div className={styles.childrenWrapper}>
        <div className={styles.childrenContainer}>{children}</div>
      </div>
    </div>
  );
}

export default AppLayout;
