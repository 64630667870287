/** @format */

import React, { useContext, useEffect, useState } from "react";
import styles from "./Faqs.module.css";
import CustomButton from "../../../components/common/CustomButton";
import Table from "../../../components/common/Table";
import { AppContext } from "../../../helpers/app-context";
import {
  CREATE_FAQ,
  DELETE_FAQS,
  GET_FAQ_ISSUE,
  GET_FAQ_LISTS,
  UPDATE_FAQ,
} from "../../../helpers/api-endpoints";
import EditIcon from "../../../assets/icons/EditIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import { CustomDeleteModal } from "../../../components/common/CustomDeleteModal";
import CreateIcon from "../../../assets/icons/CreateIcon";
import CustomizedDialogs from "../../../components/common/CustomModal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { YUP_REQUIRED_MESSAGE } from "../../../helpers/constant";
import FaqDropdown from "../../../components/common/FaqDropdown";
import { ControllerInput } from "../../../components/common/ControllerInput";
import SingleSelectDropdown from "../../../components/common/CustomDropdown";

const schema = yup.object().shape({
  topic: yup
    .string()
    .required(YUP_REQUIRED_MESSAGE)
    .matches(
      /^[a-zA-Z0-9\s().,]*[a-zA-Z0-9(),.][a-zA-Z0-9\s().,&]*$/,
      "Please enter a valid Topic"
    ),
  description: yup
    .string()
    .required(YUP_REQUIRED_MESSAGE)
    .matches(
      /^[a-zA-Z0-9\s().,]*[a-zA-Z0-9(),.][a-zA-Z0-9\s().,&]*$/,
      "Please enter a valid Description"
    ),
});
const Faqs = () => {
  const [params, setParams] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const ctx = useContext(AppContext);
  const [data, setData] = useState([]);
  const [addFAQ, setAddFAQ] = useState(false);
  const [editFAQ, setEditFAQ] = useState();
  const [options, setOptions] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [deletecompany, setDeletecompany] = useState(null);
  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);

  const { handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const getData = async () => {
    setData([]);
    const result = await ctx.HttpGet(GET_FAQ_LISTS);
    if (result.status) {
      setData(result.data);
    }
  };
  const getFAQIssue = async () => {
    const result = await ctx.HttpGet(GET_FAQ_ISSUE);
    if (result.status) {
      const options = result.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setOptions(options);
    }
  };
  const handleDelete = (data) => {
    if (selectedCheckboxIds.length > 0) {
      setDeletecompany(selectedCheckboxIds);
    } else {
      setDeletecompany(data);
    }
  };
  const onDeleteCompany = async () => {
    if (selectedCheckboxIds.length > 0) {
      const idsString = selectedCheckboxIds.join(",");
      const resultObject = { ids: idsString };
      const result = await ctx.HttpPost(DELETE_FAQS, resultObject);
      if (result.status) {
        setDeletecompany();
        getData();
        setSelectedCheckboxIds([]);
      }
    } else {
      const dataToSend = {
        ids: deletecompany?.id,
      };
      const result = await ctx.HttpPost(DELETE_FAQS, dataToSend);
      if (result.status) {
        setDeletecompany();
        setSelectedValue();
        getData();
        setSelectedCheckboxIds([]);
      }
    }
  };
  console.log(editFAQ);
  const onSubmit = async (data) => {
    const dataToSend = {
      title: data?.topic,
      description: data?.description,
      issue_type_id: selectedValue?.value,
    };
    if (editFAQ) {
      const editData = {
        title: data?.topic,
        description: data?.description,
        issue_type_id: selectedValue?.value
          ? selectedValue?.value
          : editFAQ?.issue_type,
        id: editFAQ?.id,
      };
      const result = await ctx.HttpPost(UPDATE_FAQ, editData);
      if (result.status) {
        setAddFAQ(false);
        setEditFAQ();
        getData();
        reset();
      }
    } else {
      const result = await ctx.HttpPost(CREATE_FAQ, dataToSend);
      if (result.status) {
        setAddFAQ(false);
        getData();
        reset();
      }
    }
  };
  const handleSalesPersonSelect = (selectedOption) => {
    setSelectedValue(selectedOption);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (editFAQ && editFAQ?.title) {
      setValue("topic", editFAQ.title);
      setValue("description", editFAQ.description);
    }
  }, [editFAQ]);

  const tableHeader = [
    {
      key: "issue_type",
      label: (
        <div style={{paddingLeft:'0px'}}>
          <input
            type="checkbox"
            style={{color:'blue'}}
            onChange={(event) => {
              const isChecked = event.target.checked;
              const idsToSelect = data.map((item) => item.id);
              setSelectedCheckboxIds(isChecked ? idsToSelect : []);
            }}
           
          />
          {/* <span>Issue Type</span> */}
          <label className={styles.labelType}>Issue Type</label>
        </div>
      ),
      filtering: true,
      headerClasses: styles.issueTypeheaderClasses,
      cardColumn: styles.issueTypecardColumn,
      render: (column) => {
        const isChecked = selectedCheckboxIds.includes(column.id);
        const handleCheckboxChange = () => {
          if (isChecked) {
            setSelectedCheckboxIds((prevIds) =>
              prevIds.filter((id) => id !== column.id)
            );
          } else {
            setSelectedCheckboxIds((prevIds) => [...prevIds, column.id]);
          }
        };

        return (
          <div>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label className={styles.labelType}>{column.issue_type}</label>
          </div>
        );
      },
    },
    {
      key: "title",
      label: "FAQ Topic",
      headerClasses: styles.faqheaderClasses,
      cardColumn: styles.faqcardColumn,
    },
    {
      key: "description",
      label: "FAQ Description",
      headerClasses: styles.descheaderClasses,
      cardColumn: styles.desccardColumn,
    },
    {
      label: "",
      render: (column) => {
        return (
          <div style={{ display: "flex", marginLeft: "30px" }}>
            <div
              style={{ marginRight: "35px" }}
              onClick={() => {
                getFAQIssue();
                setAddFAQ(true);
                setEditFAQ(column);
              }}
            >
              <EditIcon />
            </div>
            <div onClick={() => handleDelete(column)}>
              <DeleteIcon />
            </div>
          </div>
        );
      },
    },
  ];
  const searchInput = {
    key: "title",
    placeholder: "Search FAQ",
  };
  const filtering = {
    key: "issue_type",
  };
  console.log(submitted, !selectedValue?.value, !editFAQ);
  return (
    <div className={styles.faqContainer}>
      <div className={styles.uploadBtns}>
        {selectedCheckboxIds.length ===1 && (
          <CustomButton
            title="Delete"
            wrapperClass={styles.wrapperClassAddBtn}
            onClick={() => handleDelete("data")}
          />
        )}
        {selectedCheckboxIds.length > 1 && (
          <CustomButton
            title="Delete All"
            wrapperClass={styles.wrapperClassAddBtn}
            onClick={() => handleDelete("data")}
          />
        )}
        <CustomButton
          title={
            <div className={styles.btnTitleStyling}>
              <span className={styles.Iconstyling}>
                <CreateIcon />
              </span>
              Create FAQ
            </div>
          }
          wrapperClass={styles.wrapperClassBtn}
          onClick={() => {
            setAddFAQ(true);
            getFAQIssue();
          }}
        />
      </div>
      <Table
        tableParams={{ params, setParams }}
        tableHeader={tableHeader}
        tableData={data}
        tableSearchInput={searchInput}
        tableFiltering={filtering}
        tableWrapper={styles.tableWrapper}
      />
      {addFAQ && (
        <CustomizedDialogs
          handleClose={() => {
            setEditFAQ();
            setAddFAQ(false);
            reset();
          }}
          modalTitle={editFAQ ? "Edit FAQ" : "Create FAQ"}
          modalContent={
            <div className={styles.modalHeight}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={styles.wrapperForm}
              >
                <div className={styles.dropdownMainContainer}>
                  <label shrink className={styles.customLabel}>
                    Select Issue Type
                  </label>

                  {!editFAQ ? (
                    <FaqDropdown
                      onOptionSelect={handleSalesPersonSelect}
                      options={options && options}
                      setOptions={setOptions}
                      placeholder="Select Issue Type"
                      searchPlaceholder="Search Issue"
                      wraperClass={styles.wraperClass}
                      wrapperSelectInput={` ${
                        submitted &&
                        !selectedValue?.value &&
                        styles.wrapperSelectError
                      }`}
                    />
                  ) : (
                    <SingleSelectDropdown
                      wrapperSelectInput={styles.wrapperSelectInput}
                      onOptionSelect={handleSalesPersonSelect}
                      options={options}
                      setOptions={setOptions}
                      placeholder="Select Issue Type"
                      searchPlaceholder="Search Issue"
                      wraperClass={styles.wraperClass}
                      selectedValue={editFAQ?.issue_type}
                    />
                  )}
                  <div className={styles.errorMessage}>
                    {submitted &&
                      !selectedValue?.value &&
                      !editFAQ &&
                      "This field is required"}
                  </div>
                </div>
                <ControllerInput
                  control={control}
                  name="topic"
                  id="topic"
                  label="Enter Topic"
                  placeholder="Enter Topic"
                  wrapperClass={styles.topicInput}
                />
                <ControllerInput
                  control={control}
                  name="description"
                  id="description"
                  label="Enter Description"
                  placeholder="Enter Description"
                  wrapperClass={styles.topicInput}
                />
                <CustomButton
                  title="CREATE"
                  wrapperClass={styles.wrapperClassBtn}
                  onClick={() => setSubmitted(true)}
                />
              </form>
            </div>
          }
        />
      )}
      {deletecompany && (
        <CustomDeleteModal
          flag={deletecompany}
          content="Do you want to delete<br />this FAQ ?"
          handleClose={() => setDeletecompany(null)}
          onClick={onDeleteCompany}
        />
      )}
    </div>
  );
};

export default Faqs;
