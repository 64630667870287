/** @format */

import React from "react";

const ToastIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.5"
      height="33.5"
      viewBox="0 0 33.5 33.5"
    >
      <g
        id="Group_135728"
        data-name="Group 135728"
        transform="translate(-2.14 -1.846)"
      >
        <path
          id="Path_229025"
          data-name="Path 229025"
          d="M19.993,10.5,13,17.493"
          transform="translate(7.501 5.612)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Path_229026"
          data-name="Path 229026"
          d="M10.5,16,7,12.5"
          transform="translate(3.012 7.108)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Path_229027"
          data-name="Path 229027"
          d="M17.741,9.5,9,18.241"
          transform="translate(4.508 4.863)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <circle
          id="Ellipse_43874"
          data-name="Ellipse 43874"
          cx="16"
          cy="16"
          r="16"
          transform="translate(2.89 2.596)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
    </svg>
  );
};

export default ToastIcon;
