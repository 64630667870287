/** @format */

import React from "react";

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.191"
      height="16.191"
      viewBox="0 0 16.191 16.191"
    >
      <g
        id="Group_128661"
        data-name="Group 128661"
        transform="translate(-3.247 0.75)"
        opacity="0.999"
      >
        <circle
          id="Ellipse_4290"
          data-name="Ellipse 4290"
          cx="6.344"
          cy="6.344"
          r="6.344"
          transform="translate(3.997)"
          fill="none"
          stroke="#d3d3e2"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Path_184501"
          data-name="Path 184501"
          d="M19.6,19.6l-3.55-3.55"
          transform="translate(-1.225 -5.222)"
          fill="none"
          stroke="#d3d3e2"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
