/** @format */

import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#4E2C90",
    },
  },
  components: {
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          color: "black",
          borderRadius: 2,
          borderWidth: 1,
          borderColor: "#e91e63",
          border: "1px solid",
          backgroundColor: "#6200EE",
        },
      },
    },
    // InputBase: {
    //   styleOverrides: {
    //     root: {
    //       color: "red",
    //     },
    //   },
    // },
  },
});
