/** @format */

import React from "react";

const NavigateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.077"
      height="12.034"
      viewBox="0 0 7.077 12.034"
    >
      <path
        id="Path_228983"
        data-name="Path 228983"
        d="M3881.075,5426.839l4.957,4.956,4.956-4.956"
        transform="translate(-5425.779 3892.048) rotate(-90)"
        fill="none"
        stroke="#5d5d61"
        stroke-linecap="round"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default NavigateIcon;
